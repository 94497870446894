/* eslint-disable @typescript-eslint/no-explicit-any */

import { getGlobalPropList } from '@utils/props'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'

const useContentfulGlobalFaq = (
  topics?: MCDC.Props.IGlobalTopic[]
): MCDC.Props.IGlobalFaq[] => {
  const { locale } = useIntl()
  const { data } = useStaticQuery(
    graphql`
      query {
        data: allContentfulGlobalFaq(
          filter: { topics: { elemMatch: { identifier: { ne: null } } } }
        ) {
          nodes {
            ...GlobalFaq
          }
        }
      }
    `
  )

  return topics
    ? (getGlobalPropList(
        data.nodes?.filter(
          (entry: MCDC.Contentful.IGlobalFaq) =>
            entry.node_locale === locale &&
            entry.topics.some((entryTopic) =>
              topics.find((topic) => topic.identifier === entryTopic.identifier)
            )
        )
      ) as MCDC.Props.IGlobalFaq[])
    : (getGlobalPropList(
        data.nodes.filter(
          (entry: MCDC.Contentful.IGlobalTopic) => entry.node_locale === locale
        )
      ) as MCDC.Props.IGlobalFaq[])
}
export default useContentfulGlobalFaq
