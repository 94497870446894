import React, { ReactElement, useEffect } from 'react'
import { HistoryLocation } from 'reach__router'
import { Breakpoint } from '@mui/material'

import {
  getAssetProp,
  getGlobalProp,
  getGlobalPropList,
  getRichTextProp,
} from '@utils/props'

import Facts, { FactsProps } from './Facts'
import Video, { VideoProps } from './Video'
import Stage, { StageProps } from './Stage'
import Accordion, { AccordionsProps } from './Accordions'
import Testimonials, { TestimonialsProps } from './Testimonials'
import TeaserImage, { TeaserImageProps } from './TeaserImage'
import Benefits, { BenefitsProps } from './Benefits'
import TeaserMedia, { TeaserMediaProps } from './TeaserMedia'
import DeepLinks, { DeepLinksProps } from './DeepLinks'
import Reviews, { ReviewsProps } from './Reviews'
import JobFacts, { JobFactsProps } from './JobFacts'
import ReviewDetails, { ReviewDetailsProps } from './ReviewDetails'
import TeaserText, { TeaserTextProps } from './TeaserText'
import Chatbot, { ChatbotProps } from './Chatbot'
import Faqs, { FaqsProps } from './Faqs'
import useStaticContentful from '@components/site/hooks/contentful/useStaticContentful'
import TeaserJobs, { TeaserJobsProps } from './TeaserJobs'
import VideoSlider, { VideoSliderProps } from './VideoSlider'
import Recommendations, { RecommendationsProps } from './Recommendations'
import useChatbot from '@components/site/hooks/chatbot/useChatbot'
import Ambassador, {
  AmbassadorProps,
} from './Ambassador/components/AmbassadorCard'
import AmbassadorContainer, {
  AmbassadorContainerProps,
} from '@components/modules/Ambassador'
import BenefitsList, {
  BenefitsListProps,
} from '@components/modules//BenefitsList'
import AmbassadorFaqs, {
  AmbassadorFaqsProps,
} from '@components/modules/AmbassadorFaqs'
import AmbassadorFaqItem, {
  AmbassadorFaqItemProps,
} from '@components/modules/AmbassadorFaqs/AmbassadorFaqItem'

import LinkBundle, { LinkCollectionProps } from './LinkCollection'
import EducationTeaser, { EducationTeaserProps } from './EducationTeaser'

interface IPageModuleParser {
  __typename: string
  component: React.ElementType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: (
    mod: any,
    options: {
      sx?: MCDC.Props.IDefault['sx']
      maxWidth?: Breakpoint
      theme?: MCDC.Props.ThemeType
    }
  ) => MCDC.Props.UnionModules
}

export const pageModuleParsers: Array<IPageModuleParser> = [
  {
    __typename: 'ContentfulModuleEducationTeaser',
    component: EducationTeaser,
    props: (
      mod: MCDC.Contentful.IModuleEducationTeaser
    ): EducationTeaserProps => {
      const { data } = mod
      const { headline, copy, items } = data

      return {
        headline,
        items,
        copy,
      }
    },
  },
  {
    __typename: 'ContentfulModuleLinkBundle',
    component: LinkBundle,
    props: (mod: MCDC.Contentful.IModuleLinkBundle): LinkCollectionProps => {
      const { childContentfulModuleLinkBundleDataJsonNode } = mod
      const { headline, items, style } =
        childContentfulModuleLinkBundleDataJsonNode

      return {
        headline,
        items,
        style,
      }
    },
  },
  {
    __typename: 'ContentfulModuleBenefitsList',
    component: BenefitsList,
    props: (
      mod: MCDC.Contentful.IModuleBenefitsList
    ): {
      childContentfulModuleBenefitsListStructuredDataBenefitsJsonNode: {
        headline: string
        items: Array<{
          employer: string
          employee: string
        }>
      }
    } => {
      const {
        childContentfulModuleBenefitsListStructuredDataBenefitsJsonNode,
      } = mod

      const { headline, items } =
        childContentfulModuleBenefitsListStructuredDataBenefitsJsonNode
      return {
        headline,
        items,
      }
    },
  },
  {
    __typename: 'ContentfulModuleAmbassadorFaqs',
    component: AmbassadorFaqs,
    props: (
      mod: MCDC.Contentful.IModuleAmbassadorFaqs,
      { maxWidth, sx }
    ): AmbassadorFaqsProps => {
      const { headline, copy, ambassadorFilter, ambassadorData, ...rest } = mod

      // Get the parser for AmbassadorFaqItem
      const ambassadorFaqItemParser = pageModuleParsers.find(
        (entry) => entry.__typename === 'ContentfulModuleAmbassadorFaq'
      )

      // Map over ambassadorData and apply the parser to each item
      const parsedAmbassadorData =
        ambassadorData &&
        (ambassadorData.map(
          (entry) =>
            ambassadorFaqItemParser && ambassadorFaqItemParser.props(entry, {})
        ) as AmbassadorFaqItemProps[])

      return {
        ...rest,
        headline,
        copy: getRichTextProp(copy),
        filterLevelOptions: ambassadorFilter?.filterLevel,
        ambassadorData: parsedAmbassadorData,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleAmbassadorFaq',
    component: AmbassadorFaqItem,
    props: (
      mod: MCDC.Contentful.IModuleAmbassadorFaq,
      { maxWidth, sx }
    ): AmbassadorFaqItemProps => {
      const { headline, subline, image, ambassadorFilter, faqVideo, ...rest } =
        mod
      //TODO: Refactor faqvideo don't need a image it's already possible to attach a image to a Globalvideo
      return {
        ...rest,
        headline,
        subline,
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        ambassadorFilter,
        faqVideo: faqVideo.map((entry) => ({
          question: entry.question,
          answer: getRichTextProp(entry.answer),
          video: {
            youtubeId: entry?.video?.youtubeId,
            image: getAssetProp(entry?.image?.large) as MCDC.Props.IAssetImage,
          },
        })),
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulAmbassadorContainer',
    component: AmbassadorContainer,
    props: (
      mod: MCDC.Contentful.IModuleAmbassadorContainer,
      { maxWidth, sx }
    ): AmbassadorContainerProps => {
      const ambassadorParser = pageModuleParsers.find(
        (entry) => entry.__typename === 'ContentfulModuleAmbassador'
      )
      const { ambassadors, ...rest } = mod
      return {
        ...rest,
        ambassadors: ambassadors.map(
          (entry) => ambassadorParser && ambassadorParser.props(entry, {})
        ) as AmbassadorProps[],
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleAmbassador',
    component: Ambassador,
    props: (
      mod: MCDC.Contentful.IModuleAmbassador,
      { maxWidth, sx }
    ): AmbassadorProps => {
      const { copy, image, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleVideo',
    component: Video,
    props: (
      mod: MCDC.Contentful.IModuleVideo,
      { maxWidth, sx }
    ): VideoProps => {
      const { copy, image, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getGlobalProp(image) as MCDC.Props.IGlobalImage,
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFaqs',
    component: Faqs,
    props: (mod: MCDC.Contentful.IModuleFaqs, { maxWidth, sx }): FaqsProps => {
      const { copy, image, topics, ...rest } = mod
      const { getGlobalFaqs } = useStaticContentful()
      const globalTopics = getGlobalPropList(
        topics
      ) as MCDC.Props.IGlobalTopic[]

      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        options: globalTopics?.map((entry) => ({
          label: entry.title,
          value: entry.identifier || '',
        })),
        items: getGlobalFaqs(globalTopics),
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserJobs',
    component: TeaserJobs,
    props: (
      mod: MCDC.Contentful.IModuleTeaserJobs,
      { sx }
    ): TeaserJobsProps => {
      const { copy, image, linkTo, linkToParams, linkToLabel, ...rest } = mod
      const { pageJobOffers } = useStaticContentful()
      const linkToSearchParams = !!linkToParams?.internal.content
        ? new URLSearchParams({
            ...(JSON.parse(linkToParams.internal.content) || {}),
            rel: 'true',
          }).toString()
        : ''
      const linkToUrl = !!linkTo
        ? `${linkTo?.fields?.linkTo.url}${
            !!linkToSearchParams ? '?' : ''
          }${linkToSearchParams}`
        : `${pageJobOffers?.fields?.linkTo.url}${
            !!linkToSearchParams ? '?' : ''
          }${linkToSearchParams}`
      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        linkTo: {
          label: linkToLabel,
          url: linkToUrl,
          isExternal: linkTo?.fields?.linkTo.isExternal || false,
        },
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserImage',
    component: TeaserImage,
    props: (
      mod: MCDC.Contentful.IModuleTeaserImage,
      { sx }
    ): TeaserImageProps => {
      const { copy, image, linkTo, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        image: getAssetProp(image) as MCDC.Props.IAssetImage,
        linkTo: linkTo?.fields?.linkTo,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserText',
    component: TeaserText,
    props: (
      mod: MCDC.Contentful.IModuleTeaserText,
      { sx }
    ): TeaserTextProps => {
      const { copy, linkTo, linkToLabel, ...rest } = mod
      return {
        ...rest,
        copy: getRichTextProp(copy),
        linkTo: linkTo?.fields
          ? {
              ...linkTo.fields.linkTo,
              label: linkToLabel || linkTo.fields.linkTo.label,
            }
          : undefined,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFacts',
    component: Facts,
    props: (
      mod: MCDC.Contentful.IModuleFacts,
      { theme, maxWidth, sx }
    ): FactsProps => {
      const { facts, ...rest } = mod
      return {
        ...rest,
        facts: facts?.facts.split('\n'),
        maxWidth,
        theme: theme || 'light',
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleStage',
    component: Stage,
    props: (
      mod: MCDC.Contentful.IModuleStage,
      { maxWidth, sx }
    ): StageProps => {
      const { background, ...rest } = mod
      return {
        ...rest,
        background: getGlobalProp(background) as
          | MCDC.Props.IGlobalImage
          | MCDC.Props.IGlobalVideo,
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTestimonials',
    component: Testimonials,
    props: (
      mod: MCDC.Contentful.IModuleTestimonials,
      { maxWidth, sx }
    ): TestimonialsProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalTestimonial[],
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleDeepLinks',
    component: DeepLinks,
    props: (
      mod: MCDC.Contentful.IModuleDeepLinks,
      { maxWidth, sx }
    ): DeepLinksProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalDeepLink[],
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFaq',
    component: Accordion,
    props: (
      mod: MCDC.Contentful.IModuleFaq,
      { maxWidth, sx }
    ): AccordionsProps => {
      const { entries, headline, ...rest } = mod

      return {
        ...rest,
        headline,
        headlineSize: 'h4',
        items: (getGlobalPropList(entries) as MCDC.Props.IGlobalFaq[]).map(
          (entry) => ({ title: entry.question, content: entry.answer })
        ),
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleSteps',
    component: Accordion,
    props: (
      mod: MCDC.Contentful.IModuleSteps,
      { maxWidth, sx }
    ): AccordionsProps => {
      const { entries, copy, ...rest } = mod

      return {
        ...rest,
        theme: 'secondary',
        variant: 'footerInfos',
        copy: getRichTextProp(copy),
        items: (getGlobalPropList(entries) as MCDC.Props.IGlobalStep[]).map(
          (entry, index) => ({
            title: `${index + 1}. ${entry.title}`,
            content: entry.description,
          })
        ),
        maxWidth,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleBenefits',
    component: Benefits,
    props: (
      mod: MCDC.Contentful.IModuleBenefits,
      { maxWidth, sx }
    ): BenefitsProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalBenefit[],
        maxWidth,
        sx,
      }
    },
  },
  // {
  //   __typename: 'ContentfulModuleChatbot',
  //   component: Chatbot,
  //   props: (
  //     mod: MCDC.Contentful.IModuleChatbot,
  //     { maxWidth, sx }
  //   ): ChatbotProps => {
  //     return { sx }
  //   },
  // },
  {
    __typename: 'ContentfulModuleRecommendations',
    component: Recommendations,
    props: (
      mod: MCDC.Contentful.IModuleRecommendations,
      { maxWidth, sx }
    ): RecommendationsProps => {
      const { pageJobOffers } = useStaticContentful()
      return {
        variant: 'offers',
        baseUrl: pageJobOffers?.fields.linkTo.url,
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleReviews',
    component: Reviews,
    props: (
      mod: MCDC.Contentful.IModuleReviews,
      { maxWidth, sx }
    ): ReviewsProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalReview[],
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleReviewDetails',
    component: ReviewDetails,
    props: (
      mod: MCDC.Contentful.IModuleReviewDetails,
      { maxWidth, sx }
    ): ReviewDetailsProps => {
      const { copy, labels, values, ...rest } = mod
      const options =
        labels?.map((entry, index) => ({
          label: entry,
          value: values?.[index] || '0,0',
        })) || []
      return { ...rest, options, copy: getRichTextProp(copy), sx }
    },
  },
  {
    __typename: 'ContentfulModuleJobFacts',
    component: JobFacts,
    props: (
      mod: MCDC.Contentful.IModuleJobFacts,
      { maxWidth, sx }
    ): JobFactsProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalJobFact[],
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserMedia',
    component: TeaserMedia,
    props: (
      mod: MCDC.Contentful.IModuleTeaserMedia,
      { maxWidth, sx }
    ): TeaserMediaProps => {
      const { entries, ...rest } = mod
      return {
        ...rest,
        items: getGlobalPropList(entries) as MCDC.Props.IGlobalMediaTeaser[],
        sx,
      }
    },
  },
  {
    __typename: 'ContentfulModuleVideoSlider',
    component: VideoSlider,
    props: (
      mod: MCDC.Contentful.IModuleVideoSlider,
      { maxWidth, sx }
    ): VideoSliderProps => {
      const videoParser = pageModuleParsers.find(
        (entry) => entry.__typename === 'ContentfulModuleVideo'
      )
      const { videos, ...rest } = mod
      return {
        ...rest,
        videos: videos.map(
          (entry) => videoParser && videoParser.props(entry, {})
        ) as VideoProps[],
        sx,
      }
    },
  },
]

type PageModuleProps = MCDC.Props.IDefault & {
  moduleProps: MCDC.Contentful.UnionsModules & { __typename: string }
  location?: HistoryLocation
  theme?: MCDC.Props.ThemeType
  maxWidth?: Breakpoint
}

export function PageModule({
  moduleProps,
  theme,
  maxWidth,
  sx,
}: PageModuleProps): ReactElement | null {
  const Parser = pageModuleParsers.find((moduleParser) => {
    /*     if (moduleParser.__typename === 'ContentfulModuleBenefitsList') {
      console.log('moduleProps = ', moduleProps.__typename)
      console.log('moduleParser = ', moduleParser.__typename)
    } */
    return moduleProps.__typename === moduleParser.__typename
  })

  if (!Parser) {
    console.log('Module not defined: ', moduleProps)
    return null
  }

  return React.createElement(
    Parser.component,
    Parser.props(moduleProps, { sx, maxWidth, theme })
  )
}

type PageModulesProps = MCDC.Props.IDefault & {
  location: HistoryLocation
  modules?: Array<MCDC.Contentful.UnionsModules>
}

export function PageModules({
  className,
  location,
  modules,
}: PageModulesProps): ReactElement {
  // const { setIsChatbotVisible, isChatbotVisible } = useChatbot()

  // useEffect(() => {
  //   const withChatbot =
  //     modules &&
  //     modules.some((entry) => entry.__typename === 'ContentfulModuleChatbot')

  //   if (!isChatbotVisible && withChatbot) {
  //     setIsChatbotVisible(true)
  //   } else if (isChatbotVisible && !withChatbot) {
  //     setIsChatbotVisible(false)
  //   }
  // }, [])

  return (
    <>
      {modules &&
        modules.map((mod, index) => (
          <PageModule
            key={`${mod.id}${index}`}
            className={className}
            location={location}
            moduleProps={mod}
          />
        ))}
    </>
  )
}
