import { Components, Theme } from '@mui/material'

export const AutocompleteOverrides = (
  theme: Theme
): Components['MuiAutocomplete'] => ({
  defaultProps: {
    componentsProps: {
      paper: {
        elevation: 4,
      },
    },
  },
  styleOverrides: {
    root: {},
    paper: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      margin: '0 -2px',
    },
    popper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    inputRoot: {
      '&.MuiFormControl-root': {
        paddingRight: 0,
      },
    },
    input: {
      fontWeight: 700,
      '&:placeholder-shown': {
        fontWeight: 100,
      },
    },
    noOptions: {
      padding: 0,
    },
  },
})
