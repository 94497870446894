import React, { ReactElement } from 'react'

import { Box, SelectChangeEvent, Theme } from '@mui/material'

import SelectInput from '../../fields/SelectInput'
import AutocompleteInput from '../../fields/AutocompleteInput'

export type LocationGroupProps = {
  id: string
  labels?: {
    location?: string
    distance?: string
    useLocation?: string
    currentLocation?: string
    loading?: string
  }
  options: MCDC.Props.IOption[]
  optionsDistance: MCDC.Props.IOption[]
  value?: MCDC.Props.IOption | null
  valueDistance?: MCDC.Props.IOption
  query?: string
  withoutBorder?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  isGeoLocated?: boolean
  onUseLocation: () => void
  onChangeLocation: (
    e: React.SyntheticEvent | undefined,
    value: MCDC.Props.IOption | string | null,
    reason: string
  ) => void
  onChangeLocationInput: (
    e: React.SyntheticEvent,
    value: string,
    reason: string
  ) => void
  onChangeDistance: (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => void
  sx?: MCDC.Props.IDefault['sx']
}

const LocationGroup = ({
  id,
  labels = {},
  options = [],
  optionsDistance = [],
  value,
  valueDistance,
  query,
  withoutBorder,
  isDisabled,
  isLoading,
  isGeoLocated,
  onChangeLocation,
  onChangeLocationInput,
  onChangeDistance,
  onUseLocation,
  sx,
}: LocationGroupProps): ReactElement => {
  return (
    <Box sx={[sx, { position: 'relative', display: 'flex' }]}>
      <AutocompleteInput
        id={`${id}-location`}
        label={labels.location}
        labelLoading={labels.loading}
        options={options}
        value={value || null}
        icon="pin_drop"
        isLoading={isLoading}
        isDisabled={isDisabled}
        isHighlighted={isGeoLocated}
        isAutoSelect
        isClearOnBlur
        isSelectOnFocus
        isClearable
        withoutBorder
        actions={
          !isGeoLocated
            ? [
                {
                  label: labels.useLocation || '',
                  icon: 'my_location',
                  onClick: onUseLocation,
                },
              ]
            : undefined
        }
        onInputChange={onChangeLocationInput}
        onChange={onChangeLocation}
        sx={{
          '.MuiOutlinedInput-root': {
            pr: '150px !important',
          },
          '.MuiFormControl-root': {
            pr: '0 !important',
          },
          '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: '124px',
          },
        }}
      />
      <SelectInput
        id={`${id}-distance`}
        value={!!value ? valueDistance : null}
        options={optionsDistance}
        placeholder={labels.distance}
        onChange={onChangeDistance}
        isDisabled={!value || isDisabled}
        sx={(theme: Theme) => ({
          position: 'absolute',
          right: 0,
          width: '120px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: `2px solid ${theme.palette.grey[100]} !important`,
        })}
        withoutBorder={withoutBorder}
      />
    </Box>
  )
}

export default LocationGroup
