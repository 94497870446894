import React, { ReactElement } from 'react'

import Section from '@components/core/container/Section'
import Headline from '@components/core/text/Headline'
import TextBase from '@components/core/text/TextBase'
import { Breakpoint } from '@mui/material'

const kFactColors: string[] = [
  'primary.main',
  'secondary.main',
  'tertiary.main',
]

export type FactsProps = {
  theme?: MCDC.Props.ThemeType
  headline: string
  facts: string[]
  maxWidth?: Breakpoint
  sx?: MCDC.Props.IDefault['sx']
}

export default function Facts({
  theme,
  headline,
  facts = [],
  maxWidth,
  sx,
}: FactsProps): ReactElement {
  return (
    <Section theme={theme} sx={sx} maxWidth={maxWidth}>
      <Headline variant="h5" sx={{ mb: 5 }}>
        {headline}
      </Headline>
      <Headline
        variant="h2"
        sx={{ wordWrap: 'break-word', '> *': { width: '100%' } }}
      >
        {facts.map((str, index) => (
          <TextBase
            component="span"
            variant="inherit"
            sx={{
              color: kFactColors[index % 3],
              mr: '0.16em',
            }}
            isHtml
            key={index}
          >
            {str}
          </TextBase>
        ))}
      </Headline>
    </Section>
  )
}
