import { Theme } from '@mui/material'
import { MixinsOptions } from '@mui/material/styles/createMixins'
import Breakpoints from '../definitions/breakpoints'
import FontSize from '../definitions/fontSize'
import Spacing from '../definitions/spacing'

const stripUnit = (value: string): number => Number(value.replace('px', ''))

export default function Mixins(theme: Theme): MixinsOptions {
  return {
    lineClamp: (maxLines) => {
      const supportKey = `@supports (-webkit-line-clamp: ${maxLines})`
      return {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [supportKey]: {
          whiteSpace: 'initial',
          display: '-webkit-box',
          WebkitLineClamp: `${maxLines}`,
          WebkitBoxOrient: 'vertical',
        },
      }
    },
    fluidFontSize: (bpMin, bpMax, sizeMin, sizeMax) => {
      const bpMinValue = Breakpoints.values?.[bpMin] || 0
      const bpMaxValue = Breakpoints.values?.[bpMax] || 0

      const sizeMinValue = FontSize.get(sizeMin) as string
      const sizeMaxValue = FontSize.get(sizeMax) as string

      return {
        fontSize: sizeMinValue,
        [theme.breakpoints.up(bpMin)]: {
          fontSize: `calc(${sizeMinValue} + ${
            stripUnit(sizeMaxValue) - stripUnit(sizeMinValue)
          } * ((100vw - ${bpMinValue}px) / ${bpMaxValue - bpMinValue}))`,
        },
        [theme.breakpoints.up(bpMax)]: {
          fontSize: sizeMaxValue,
        },
      }
    },
    conatainerModuleSpacing: (
      size: 'small' | 'medium' | 'large' = 'medium'
    ) => ({
      '& > * + *': {
        mt: Spacing.padding[size].sm,
        [theme.breakpoints.up('md')]: {
          mt: Spacing.padding[size].md,
        },
        [theme.breakpoints.up('lg')]: {
          mt: Spacing.padding[size].lg,
        },
      },
      '& > *:first-of-type:not(.ModuleStage):not(.Mui-themed)': {
        mt: Spacing.padding[size].sm,
        [theme.breakpoints.up('md')]: {
          mt: Spacing.padding[size].md,
        },
        [theme.breakpoints.up('lg')]: {
          mt: Spacing.padding[size].lg,
        },
      },
      '& > .Mui-themed + .Mui-themed': {
        mt: 0,
      },
    }),
    containerPadding: (paddingTop, paddingBottom, paddingSize = 'medium') => {
      const top: MCDC.Props.BreakpointFlags = {
        sm: !!paddingTop,
        md: !!paddingTop,
        lg: !!paddingTop,
        ...(typeof paddingTop === 'object' ? paddingTop : {}),
      }
      const bottom: MCDC.Props.BreakpointFlags = {
        sm: !!paddingBottom,
        md: !!paddingBottom,
        lg: !!paddingBottom,
        ...(typeof paddingBottom === 'object' ? paddingBottom : {}),
      }

      return {
        paddingTop: top.sm ? Spacing.padding[paddingSize].sm : undefined,
        paddingBottom: bottom.sm ? Spacing.padding[paddingSize].sm : undefined,
        [theme.breakpoints.up('md')]: {
          paddingTop: top.md ? Spacing.padding[paddingSize].md : undefined,
          paddingBottom: bottom.md
            ? Spacing.padding[paddingSize].md
            : undefined,
        },
        [theme.breakpoints.up('lg')]: {
          paddingTop: top.lg ? Spacing.padding[paddingSize].lg : undefined,
          paddingBottom: bottom.lg
            ? Spacing.padding[paddingSize].lg
            : undefined,
        },
      }
    },

    containerGutters: {
      paddingLeft: theme.spacing(theme.gutter('sm')),
      paddingRight: theme.spacing(theme.gutter('sm')),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(theme.gutter('sm')),
        paddingRight: theme.spacing(theme.gutter('sm')),
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(theme.gutter('md')),
        paddingRight: theme.spacing(theme.gutter('md')),
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(theme.gutter('lg')),
        paddingRight: theme.spacing(theme.gutter('lg')),
      },
    },
    containerGuttersOverflow: {
      marginLeft: theme.spacing(-theme.gutter('sm')),
      marginRight: theme.spacing(-theme.gutter('sm')),
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(-theme.gutter('sm')),
        marginRight: theme.spacing(-theme.gutter('sm')),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(-theme.gutter('md')),
        marginRight: theme.spacing(-theme.gutter('md')),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(-theme.gutter('lg')),
        marginRight: theme.spacing(-theme.gutter('lg')),
      },
    },
  }
}
