import React, { ReactElement } from 'react'
import { Box, IconButton, Theme } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'

import {
  kEventCategory,
  kEventActionFastApplication,
  kEventActionJobcard,
} from '@utils/constants'

import Link from '@components/core/input/Link'
import Copy from '@components/core/text/Copy'
import Chip from '@components/core/input/Chip'
import Headline from '@components/core/text/Headline'
import Button from '@components/core/input/Button'
import { trackEvent } from '@utils/tracking'
import Image from '@components/core/media/Image'
import Icon from '@components/core/media/Icon'

export type JobCardProps = {
  sx?: MCDC.Props.IDefault['sx']
  variant?: 'overview' | 'detail' | 'group'
  types?: string[]
  title: string
  availability?: string
  description?: string
  linkTo?: MCDC.Props.ILinkTo
  linkToDetail?: MCDC.Props.ILinkTo
  image?: MCDC.Props.IAssetImage
  hasApplicationNow?: boolean | undefined
  isFavorite?: boolean
  onClickFavorite?: () => void
}

export default function JobCard({
  availability,
  description,
  variant = 'detail',
  linkTo,
  linkToDetail,
  image,
  sx,
  types,
  title,
  hasApplicationNow,
  isFavorite,
  onClickFavorite,
}: JobCardProps): ReactElement {
  const trackClick = (
    e: any,
    hasApplicationNow: boolean | undefined,
    link: any
  ) => {
    if (link && link.isExternal) {
      trackEvent(
        kEventCategory,
        hasApplicationNow ? kEventActionFastApplication : kEventActionJobcard,
        link.url
      )
    }
    e.stopPropagation()
  }
  return (
    <Box
      sx={[
        {
          position: 'relative',
          p: 6,
          borderRadius: 2,
          backgroundColor: 'common.white',
          overflow: 'hidden',
        },
        sx,
      ]}
    >
      {image && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
          }}
        >
          <Image
            {...image}
            objectFit="contain"
            objectPosition="bottom right"
            scaleToFit
            sx={{
              height: '100%',
              width: '100%',
            }}
          />
        </Box>
      )}
      {variant === 'detail' && (
        <IconButton
          onClick={onClickFavorite}
          sx={(theme: Theme) => ({
            position: 'absolute',
            right: 12,
            top: 20,
            zIndex: 1,
            color: 'primary.main',
            [theme.breakpoints.up('lg')]: {
              top: 12,
            },
          })}
        >
          <Icon variant={!isFavorite ? 'favorite' : 'favorite_filled'} />
        </IconButton>
      )}
      <Box
        component={GatsbyLink}
        to={linkToDetail ? linkToDetail.url : linkTo?.url || '#'}
        sx={[
          {
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: 'block',
          },
          sx,
        ]}
      />
      <Box
        sx={{
          position: 'relative',
          maxWidth: variant === 'overview' ? '70%' : undefined,
          pointerEvents: 'none',
        }}
      >
        {variant === 'overview' && image && (
          <Box
            sx={(theme: Theme) => ({
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: theme.spacing(-6),
              top: theme.spacing(-6),
              background:
                'linear-gradient(90deg, rgba(255,255,255,.8) 70%, rgba(255,255,255,0) 100%)',
              pointerEvents: 'none',
            })}
          />
        )}
        <Box
          sx={{
            position: 'relative',
            mb: 2,
            p: 0,
          }}
        >
          {types && (
            <Box sx={{ mb: 4, ml: 0, pointerEvents: 'none' }}>
              {types.map((type, index) => (
                <Chip
                  sx={{ mr: 2 }}
                  size="small"
                  variant={'outlined'}
                  label={type}
                  key={index}
                />
              ))}
            </Box>
          )}
          <Headline variant="h5">{title}</Headline>
        </Box>
        <Box
          sx={(theme: Theme) => ({
            position: 'relative',
            display: 'flex',
            flexDirection: variant === 'overview' ? 'column' : undefined,
            justifyContent:
              variant !== 'overview' ? 'space-between' : undefined,
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Box>
            <Copy>{availability}</Copy>
            <Copy
              sx={(theme: Theme) => ({
                color: theme.palette.grey[400],
              })}
            >
              {description}
            </Copy>
            {linkToDetail && (
              <Link
                variant="navigation"
                underline="always"
                to={linkToDetail.url}
                isExternal={linkToDetail.isExternal}
                sx={{ display: 'inline-block', mt: 4 }}
              >
                {linkToDetail.label}
              </Link>
            )}
          </Box>
          {linkTo && (
            <Button
              to={linkTo.url}
              isExternal={linkTo.isExternal}
              onClick={(e) => trackClick(e, hasApplicationNow, linkTo)}
              icon={
                hasApplicationNow && variant === 'detail'
                  ? 'alarm_on'
                  : undefined
              }
              color="primary"
              sx={(theme: Theme) => ({
                pointerEvents: 'auto',
                alignSelf: variant !== 'overview' ? 'flex-end' : undefined,
                mt: 4,
                [theme.breakpoints.down('md')]:
                  variant !== 'overview'
                    ? {
                        width: '100%',
                      }
                    : undefined,
              })}
            >
              {linkTo.label}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
