import React, { ReactElement } from 'react'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material'

import Headline from '@components/core/text/Headline'
import Button from '@components/core/input/Button'
import Copy from '@components/core/text/Copy'
import Image from '@components/core/media/Image'

export type TeaserMediaProps = {
  variant?: 'tbLR' | 'btRL' | 'tb' | 'bt'
  headline: string
  subline?: string
  copy?: MCDC.Props.RichText
  image: MCDC.Props.IAssetImage
  linkTo?: MCDC.Props.ILinkTo
  sx?: MCDC.Props.IDefault['sx']
}

export default function TeaserMedia({
  headline,
  copy,
  subline,
  variant = 'tbLR',
  image,
  linkTo,
}: TeaserMediaProps): ReactElement {
  const isBTRL = variant === 'btRL'
  const isBT = variant === 'bt'
  const asColumn = variant === 'tb' || variant === 'bt'
  return (
    <Box
      sx={(theme: Theme) => ({
        ...(isBTRL || isBT
          ? {
              display: 'flex',
              flexDirection: !isBT ? 'column-reverse' : 'column',
              [theme.breakpoints.up('md')]: !asColumn
                ? {
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                  }
                : isBT
                ? { flexDirection: 'column-reverse' }
                : undefined,
            }
          : {
              mb: 6,
              [theme.breakpoints.up('md')]: !asColumn
                ? {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mb: 0,
                  }
                : {
                    mb: 0,
                  },
            }),
      })}
    >
      <Image
        {...image}
        sx={(theme: Theme) => ({
          mb: isBTRL ? 0 : 6,
          [theme.breakpoints.down('md')]: asColumn
            ? {
                width: isBT ? '60%' : '80%',
                mb: isBT ? 6 : undefined,
              }
            : undefined,
          [theme.breakpoints.up('md')]: !asColumn
            ? {
                mb: 0,
                width: '50%',
              }
            : undefined,
        })}
      />
      <Box
        sx={(theme: Theme) => ({
          [theme.breakpoints.up('md')]: !asColumn
            ? {
                pl: !isBTRL ? 14 : undefined,
                pr: isBTRL ? 14 : undefined,
                width: '50%',
              }
            : undefined,
          [theme.breakpoints.down('md')]:
            asColumn && !isBT
              ? {
                  textAlign: 'right',
                }
              : undefined,
        })}
      >
        <Headline variant={isBTRL || isBT ? 'h3' : 'h2'} component="p" isHtml>
          {headline}
        </Headline>
        {subline && (
          <Headline variant="h5" component="p" sx={{ mt: 6 }} isHtml>
            {subline}
          </Headline>
        )}
        {copy && (
          <Copy component="div" sx={{ mt: 6 }}>
            {copy}
          </Copy>
        )}
        {linkTo && (
          <Button
            variant="text"
            color="primary"
            to={linkTo.url}
            isExternal={linkTo.isExternal}
            sx={(theme: Theme) => ({
              mt: 6,
              mb: isBTRL ? 8 : undefined,
              [theme.breakpoints.up('md')]: isBT
                ? {
                    mb: 8,
                  }
                : undefined,
            })}
          >
            {linkTo.label}
          </Button>
        )}
      </Box>
    </Box>
  )
}
