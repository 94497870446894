import React, { ReactElement } from 'react'
import Box from '@mui/material/Box'
import { SelectChangeEvent, Theme } from '@mui/material'

import Section from '@components/core/container/Section'
import SelectInput from '@components/core/form/fields/SelectInput'
import Headline from '@components/core/text/Headline'
import Image from '@components/core/media/Image'
import Copy from '@components/core/text/Copy'
import { useIntl } from 'react-intl'

export type HeroDropDownProps = {
  headline: string
  copy: MCDC.Props.RichText
  options: MCDC.Props.IOption[]
  value?: MCDC.Props.IOption | null
  image: MCDC.Props.IAssetImage
  sx?: MCDC.Props.IDefault['sx']
  onChange: (e: SelectChangeEvent<string>, child: React.ReactNode) => void
}

export default function HeroDropDown({
  headline,
  copy,
  image,
  options,
  value,
  onChange,
  sx,
}: HeroDropDownProps): ReactElement {
  const intl = useIntl()
  return (
    <Section maxWidth="sm" sx={sx}>
      <Box
        sx={(theme: Theme) => ({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '500px',
          backgroundColor: 'common.white',
          background:
            'linear-gradient(0deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 100%)',
          px: theme.gutter('sm'),
          py: theme.padding('small', 'sm'),
          borderRadius: '8px',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            minHeight: '351px',
            overflow: 'hidden',
            px: theme.gutter('md'),
          },
          [theme.breakpoints.up('lg')]: {
            mx: theme.spacing(-theme.gutter('lg')),
            px: theme.gutter('lg'),
          },
        })}
      >
        <Box
          sx={(theme: Theme) => ({
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
            [theme.breakpoints.up('md')]: {
              width: '50%',
            },
          })}
        >
          <Headline variant="h3" component="div" sx={{ mb: 4 }}>
            {headline}
          </Headline>
          <Copy component="div" sx={{ mb: 10 }}>
            {copy}
          </Copy>
          <Box
            sx={(theme: Theme) => ({
              mt: 'auto',
              [theme.breakpoints.down('md')]: {
                position: 'absolute',
                left: theme.spacing(theme.gutter('sm')),
                right: theme.spacing(theme.gutter('sm')),
                bottom: theme.spacing(theme.padding('small', 'sm')),
              },
            })}
          >
            <SelectInput
              id="heroDropDown"
              onChange={onChange}
              options={options}
              value={value}
              placeholder={intl.messages['label.chooseTopic'] as string}
              withoutBorder
              sx={(theme: Theme) => ({
                '.MuiInputBase-noBorder': {
                  color: theme.palette.grey[900],
                  fontWeight: 700,
                },
              })}
            />
          </Box>
        </Box>
        <Box
          sx={(theme: Theme) => ({
            position: 'relative',
            height: '292px',
            [theme.breakpoints.down('md')]: {
              mx: theme.spacing(-theme.gutter('sm')),
              mb: theme.spacing(-theme.padding('small', 'sm')),
            },
            [theme.breakpoints.up('md')]: {
              width: '40%',
              height: '100%',
              ml: 'auto',
              bottom: theme.spacing(-theme.padding('small', 'sm')),
            },
          })}
        >
          <Image
            {...image}
            objectFit="contain"
            sx={(theme: Theme) => ({
              width: 'auto',
              height: '100%',
            })}
          />
        </Box>
      </Box>
    </Section>
  )
}
