import React, {
  useState,
  useCallback,
  createContext,
  ReactElement,
  useEffect,
} from 'react'

import Lightbox, { DialogConfigType } from '@components/core/container/Lightbox'

export interface ILightboxContext {
  isOpen: boolean
  showDialog: (config: DialogConfigType) => void
}

export const LightboxContext = createContext<ILightboxContext>({
  isOpen: false,
  showDialog: (config: DialogConfigType) => {
    console.log('No Provider | Lightbox Dialog config: ', config)
  },
})

export type LightboxProviderProps = MCDC.Props.IDefault

export default function LightboxProvider({
  children,
}: LightboxProviderProps): ReactElement {
  const [isOpen, setOpen] = useState(false)
  const [currentDialog, setCurrentDialog] = useState<
    DialogConfigType | undefined
  >(undefined)

  const showDialogHandler = useCallback((config: DialogConfigType) => {
    setCurrentDialog(config)
    setOpen(true)
  }, [])

  const hideDialogHandler = useCallback(() => {
    setOpen(false)
  }, [])

  const closeDialogHandler = useCallback(() => {
    setCurrentDialog(undefined)
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.setAttribute(
        'style',
        `padding-right: ${
          window.innerWidth - document.documentElement.clientWidth
        }px`
      )
      document.body.classList.add('Mui-noScroll')
    } else {
      document.body.removeAttribute('style')
      document.body.classList.remove('Mui-noScroll')
    }
    return () => {
      document.body.classList.remove('Mui-noScroll')
      document.body.removeAttribute('style')
    }
  }, [isOpen])

  return (
    <LightboxContext.Provider value={{ isOpen, showDialog: showDialogHandler }}>
      {children}
      <Lightbox
        isOpen={isOpen}
        currentDialog={currentDialog}
        onClose={closeDialogHandler}
        onHide={hideDialogHandler}
      />
    </LightboxContext.Provider>
  )
}
