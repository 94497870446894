import { alpha, Components, Theme } from '@mui/material'

export const ListOverrides = (theme: Theme): Components['MuiList'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {},
  },
})

export const ListItemOverrides = (theme: Theme): Components['MuiListItem'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {
      minHeight: '62px !important',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
      // '.MuiTouchRipple-root': {
      //   display: 'none',
      // },
    },
    selected: {},
  },
})
