export enum kPageMetaType {
  'offers' = 'offers',
  'offersRestaurant' = 'offersRestaurant',
  'offersManagement' = 'offersManagement',
  'faq' = 'faq',
}

export enum kPageMetaTypeFilterArea {
  'offersRestaurant' = 'RESTAURANT_POSITON',
  'offersManagement' = 'ADMINISTRATION_POSITION',
}

//-------------------------------------------------------------
// Cookie Settings
//-------------------------------------------------------------

//General Settings
export const kCookieExpiringDate = 30
export const kIsSecureCookie = true

//Cookie Methods
export const kCookieMethodSessionStorage = 'SessionStorage'
export const kCookieMethodLocalStorage = 'LocalStorage'
export const kCookieMethodCookie = 'Cookie'

//-------------------------------------------------------------
// Cookie Constants
//-------------------------------------------------------------

export const kYoutubeCookieId = 'youtubeConsent'
export const kCookieBannerCookieId = 'cookieBanner'

//-------------------------------------------------------------
// Chatbot Constants
//-------------------------------------------------------------

// functionality TODO move to ci
export const kChatBotRegion = 'eu-1'
export const kChatBotAppId = '5bc44d83721654001eecb4b9'

// functionality menuitems
export const kImageUpload = false
export const kFileUpload = false
export const kShareLocation = false

//-------------------------------------------------------------
// Event Tracking
//-------------------------------------------------------------

export const kEventCategory = 'application_process'
export const kEventCategoryInteraction = 'user_interaction'
export const kEventActionFastApplication = 'link_click_fast_application'
export const kEventActionJobDetails = 'link_click_job_details'
export const kEventActionJobcard = 'link_click_jobcard'
export const kEventActionPlayVideo = 'video_click'
export const kEventActionPlayVideoFAQ = 'faq_video_click'
export const kEventActionChatbotModule = 'chatbot_module_click'
export const kEventActionChatbotButton = 'chatbot_button_click'

//-------------------------------------------------------------
// Consent Constants
//-------------------------------------------------------------

export const kConsentUpdateTypeCategory = 'Category'
export const kConsentActivateCategoryYoutube = '6:1'
export const kConsentDeactivateCategoryYoutube = '6:0'
