import React, { ReactElement } from 'react'

import { Box, Drawer, Grid } from '@mui/material'
import Chip from '@components/core/input/Chip'
import Copy from '@components/core/text/Copy'
import Button from '@components/core/input/Button'
import Link from '@components/core/input/Link'
import { FormattedMessage, useIntl } from 'react-intl'
import SelectInput from '@components/core/form/fields/SelectInput'

export type SearchFiltersDraweDrawerProps = {
  areaFilters?: MCDC.Props.IOption[]
  typeFilters: MCDC.Props.IOption[]
  taskFilters?: MCDC.Props.IOption[]
  levelFilters?: MCDC.Props.IOption[]
  area?: MCDC.Props.IOption
  types?: MCDC.Props.IOption[]
  tasks?: MCDC.Props.IOption[]
  levels?: MCDC.Props.IOption[]
  totalResult?: number
  isOpen?: boolean
  onSelectArea: (id?: string) => void
  onSelectType: (ids: string[]) => void
  onSelectTasks: (ids: string[]) => void
  onSelectLevels: (ids: string[]) => void
  onReset: () => void
  onClose: () => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function SearchFiltersDraweDrawer({
  area,
  types = [],
  tasks = [],
  levels = [],
  areaFilters,
  typeFilters,
  taskFilters,
  levelFilters,
  totalResult,
  onClose,
  isOpen,
  onSelectArea,
  onSelectType,
  onSelectTasks,
  onSelectLevels,
  onReset,
  sx = {},
}: SearchFiltersDraweDrawerProps): ReactElement {
  const intl = useIntl()
  const typeIds = types.map((entry) => String(entry.value))
  const levelIds = levels.map((entry) => String(entry.value))
  return (
    <Drawer
      elevation={0}
      anchor="left"
      variant="temporary"
      open={isOpen}
      onClose={onClose}
      transitionDuration={500}
      PaperProps={{
        sx: [
          {
            width: '100vw',
            height: 'auto',
            bottom: 0,
            display: 'flex',
            px: 8,
          },
          sx,
        ],
      }}
      sx={sx}
    >
      {areaFilters && (
        <>
          <Copy sx={{ mt: 8, mb: 4 }}>
            <strong>
              <FormattedMessage id="label.jobAreas" />
            </strong>
          </Copy>
          <Grid container spacing={3}>
            {areaFilters.map((entry, index) => (
              <Grid item xs key={index} flexGrow="0 !important">
                <Chip
                  label={entry.label}
                  variant="outlined"
                  size="medium"
                  isSelected={area?.value === entry.value}
                  isBold={false}
                  onClick={() => onSelectArea(String(entry.value))}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {taskFilters && (
        <>
          <Copy sx={{ mt: 8, mb: 4 }}>
            <strong>
              <FormattedMessage id="label.jobTasks" />
            </strong>
          </Copy>
          <SelectInput
            id="jobSearchFilterTasks"
            placeholder={
              !tasks.length
                ? (intl.messages['label.pleaseChoose'] as string)
                : intl.formatMessage(
                    { id: 'label.amountChosen' },
                    {
                      value: tasks.length,
                    }
                  )
            }
            value={tasks}
            options={taskFilters}
            onChange={(e) => onSelectTasks(e.target.value as any)}
            isMultiple
          />
        </>
      )}
      <Copy sx={{ mt: 8, mb: 4 }}>
        <strong>
          <FormattedMessage id="label.jobTypes" />
        </strong>
      </Copy>
      <Grid container spacing={3}>
        {typeFilters.map((entry) => (
          <Grid item xs key={entry.value} flexGrow="0 !important">
            <Chip
              label={entry.label}
              variant="outlined"
              size="medium"
              isSelected={typeIds.includes(String(entry.value))}
              isBold={false}
              isDisabled={entry.isDisabled}
              onClick={() => {
                const value = String(entry.value)
                if (typeIds.includes(value)) {
                  onSelectType(typeIds.filter((id) => id !== value))
                  return
                }

                onSelectType([...typeIds, value])
              }}
            />
          </Grid>
        ))}
      </Grid>
      {levelFilters && (
        <>
          <Copy sx={{ mt: 8, mb: 4 }}>
            <strong>
              <FormattedMessage id="label.joblevel" />
            </strong>
          </Copy>
          <Grid container spacing={3}>
            {levelFilters.map((entry) => (
              <Grid item xs key={entry.value} flexGrow="0 !important">
                <Chip
                  label={entry.label}
                  variant="outlined"
                  size="medium"
                  isSelected={levelIds.includes(String(entry.value))}
                  isBold={false}
                  isDisabled={entry.isDisabled}
                  onClick={() => {
                    const value = String(entry.value)
                    if (levelIds.includes(value)) {
                      onSelectLevels(levelIds.filter((id) => id !== value))
                      return
                    }

                    onSelectLevels([...levelIds, value])
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Box sx={{ mt: 'auto', pt: 10, pb: 8, textAlign: 'center' }}>
        <Button color="primary" sx={{ width: '100%', mb: 4 }} onClick={onClose}>
          <FormattedMessage
            id="label.showTotalResults"
            values={{ value: intl.formatNumber(totalResult || 0) }}
          />
        </Button>
        <Link sx={{ display: 'inline-block' }} onClick={onReset}>
          <FormattedMessage id="label.resetFilters" />
        </Link>
      </Box>
    </Drawer>
  )
}
