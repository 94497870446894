import React, { ElementType, ReactElement } from 'react'

import TextBase from '../TextBase'

export type CopyProps = {
  id?: string
  isHtml?: boolean
  variant?: 'body1' | 'small'
  component?: ElementType<any>
  children?: MCDC.Props.IDefault['children']
  sx?: MCDC.Props.IDefault['sx']
}

export default function Copy({
  children,
  id,
  variant = 'body1',
  component = 'p',
  isHtml,
  sx,
}: CopyProps): ReactElement {
  return (
    <TextBase
      id={id}
      component={component}
      variant={variant}
      sx={sx}
      isHtml={isHtml}
    >
      {children}
    </TextBase>
  )
}
