import React, { ReactElement, useEffect, useState } from 'react'

import Link from '@components/core/input/Link'
import { styled, Theme } from '@mui/material'
import { StyledMotionSpan } from '@utils/motion'
import Chip from '@components/core/input/Chip'

const StyledWrapper = styled('li')({
  position: 'relative',
  display: 'inline-block',
})

export type NavigationItemProps = {
  to: string
  count?: string | number
  onClick?: () => void
  onHover?: (isHover: boolean) => void
  isSelected?: boolean
  isExternal?: boolean
  children?: MCDC.Props.IDefault['children']
  sx?: MCDC.Props.IDefault['sx']
}

export default function NavigationItem({
  children,
  to,
  count,
  onClick,
  onHover,
  isSelected = false,
  isExternal = false,

  sx = {},
}: NavigationItemProps): ReactElement {
  const [isHover, setIsHover] = useState(false)
  const [aniState, setAniState] = useState<'enter' | 'exit'>('exit')
  const [aniStateComplete, setAniStateComplete] = useState<'enter' | 'exit'>(
    'exit'
  )
  const [animationOrigin, setAnimationOrigin] = useState<'left' | 'right'>(
    'left'
  )

  useEffect(() => {
    if (onHover) {
      onHover(isHover)
    }
  }, [isHover])

  function onComplete(id: 'enter' | 'exit') {
    // ugly because triggered multiple times ???
    if (id === 'enter' && animationOrigin === 'left') {
      setAnimationOrigin('right')
      if (!isHover) {
        setAniState('exit')
      }
      setAniStateComplete(id)
    } else if (id === 'exit' && animationOrigin === 'right') {
      setAnimationOrigin('left')
      if (isHover) {
        setAniState('enter')
      }
      setAniStateComplete(id)
    }
  }

  return (
    <StyledWrapper sx={sx}>
      <Link
        variant="navigation"
        underline="none"
        to={to}
        isExternal={isExternal}
        onClick={onClick}
        onMouseOver={() => {
          if (aniStateComplete === 'exit') {
            setAniState('enter')
          }
          setIsHover(true)
        }}
        onMouseOut={() => {
          if (aniStateComplete === 'enter') {
            setAniState('exit')
          }
          setIsHover(false)
        }}
        sx={(theme: Theme) => ({
          display: 'flex',
          minHeight: '34px',
          alignItems: 'center',
          [theme.breakpoints.down('lg')]: {
            pb: 6,
          },
          [theme.breakpoints.up('lg')]: {
            minHeight: '64px',
          },
        })}
      >
        {children}
        {count !== undefined && (
          <Chip
            label={String(count)}
            size="small"
            color="primary"
            sx={(theme: Theme) => ({
              ml: 3,
              pointerEvents: 'none',
              [theme.breakpoints.down('lg')]: {
                position: 'absolute',
                left: '100%',
              },
            })}
          />
        )}
      </Link>

      <StyledMotionSpan
        initial="exit"
        animate={isSelected ? 'enter' : aniState}
        onAnimationComplete={onComplete}
        variants={{
          enter: {
            scaleX: 1,
            transformOrigin: animationOrigin,
          },
          exit: {
            scaleX: 0,
            transformOrigin: animationOrigin,
          },
        }}
        transition={{ type: 'ease', ease: 'easeInOut' }}
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          display: 'block',
          width: '100%',
          height: '2px',
          borderRadius: '1px',
          backgroundColor: 'primary.main',
        }}
      />
    </StyledWrapper>
  )
}
