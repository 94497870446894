import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'

import { ISearchContext } from '@components/site/providers/SearchProvider'
import { distanceOptions, getLocationLabel } from '@utils/props'
import {
  searchLocation,
  searchLocationGeolocatingState,
  searchLocationOptions,
  searchLocationQuery,
  searchLocationQueryIsBusy,
  setSearchLocationIsGeolocated,
  setSearchLocationQuery,
} from '@store/search'

type UseSearchLocationReturn = {
  locationQuery: string
  locationOptions: MCDC.Props.IOption[]
  distanceOptions: MCDC.Props.IOption[]
  locationQueryOptions?: MCDC.API.ILocation
  locationOption?: MCDC.Props.IOption | null
  distanceOption?: MCDC.Props.IOption
  errors?: any[]
  isBusy: boolean
  isGeoLocated: boolean
  setLocationQuery: (value: string) => void
  setLocation: (option: MCDC.Props.IOption | string | null) => void
  setDistance: (value: number) => void
  useCurrentLocation: () => void
}

export default function useSearchLocation(
  searchState?: ISearchContext,
  submitPath?: string
): UseSearchLocationReturn {
  const locationQuery = useStore(searchLocationQuery)
  const locationQueryOptions = useStore(searchLocationOptions)
  const geolocatingState = useStore(searchLocationGeolocatingState)
  const queryIsBusy = useStore(searchLocationQueryIsBusy)
  const location = useStore(searchLocation)
  const [locationOptions, setLocationOptions] = useState<MCDC.Props.IOption[]>(
    []
  )

  const [distanceOption, setDistanceOption] = useState<
    MCDC.Props.IOption | undefined
  >(
    distanceOptions.find(
      (entry) => entry.value === (searchState?.getDistance() || 0) / 1000
    )
  )

  const useCurrentLocation = () => {
    if (geolocatingState === 'locating') return
    setSearchLocationIsGeolocated(true)
  }

  function setLocationQuery(value: string) {
    setSearchLocationQuery(value)
  }

  function setLocation(value: MCDC.Props.IOption | string | null) {
    if (typeof value === 'string') {
      searchLocationQuery.set(value)
      searchState?.setDistance(0)
      searchState?.setLocation(undefined)
      setDistanceOption(undefined)
      return
    }

    if (!location.isGeolocated) {
      const data = searchLocationOptions
        .get()
        .find((entry) => entry.id === value?.value)
      searchState?.setLocation(data)
    }
    searchState?.setDistance(!!value ? (distanceOptions[1].value as number) : 0)
    setDistanceOption(!!value ? distanceOptions[1] : undefined)
    setLocationQuery(value?.label || '')
  }

  function setDistance(value: number) {
    if (value === distanceOption?.value) {
      searchState?.setDistance(0)
      setDistanceOption(undefined)
      return
    }
    searchState?.setDistance(value)
    setDistanceOption(distanceOptions.find((entry) => entry.value === value))
  }

  useEffect(() => {
    setLocationOptions(
      locationQueryOptions.map((entry) => ({
        label: getLocationLabel(entry),
        value: entry.id,
      }))
    )
  }, [locationQueryOptions])

  useEffect(() => {
    setDistanceOption(
      distanceOptions.find((entry) => entry.value === location.distance)
    )
  }, [location.distance])

  return {
    locationQuery,
    locationOptions,
    distanceOptions,
    locationOption: location.option,
    distanceOption,
    isBusy: geolocatingState === 'locating' || queryIsBusy,
    isGeoLocated: location.isGeolocated || false,
    setLocationQuery,
    setLocation,
    setDistance,
    useCurrentLocation,
  }
}
