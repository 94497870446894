import React, { ReactElement } from 'react'
import Section from '@components/core/container/Section'

import TeaserMediaComp from './components/TeaserMedia'
import { Grid } from '@mui/material'

export type TeaserMediaProps = {
  theme: MCDC.Props.ThemeType
  items: MCDC.Props.IGlobalMediaTeaser[]
  sx?: MCDC.Props.IDefault['sx']
}

export default function TeaserMedia({
  theme,
  items = [],
  sx,
}: TeaserMediaProps): ReactElement {
  const isMultiple = items.length > 1
  return (
    <Section
      theme={theme}
      sx={sx}
      maxWidth={items.length <= 1 ? 'md' : undefined}
    >
      <Grid container spacing={{ sm: 12, lg: 24, xl: 36 }}>
        {items.map((entry, index) => (
          <Grid item xs={12} md={isMultiple ? 6 : 12} key={entry.id || index}>
            <TeaserMediaComp
              {...entry}
              variant={
                index === 0 ? (items.length > 1 ? 'tb' : entry.layout) : 'bt'
              }
            />
          </Grid>
        ))}
      </Grid>
    </Section>
  )
}
