import React, { forwardRef, KeyboardEvent, ReactElement } from 'react'

import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Theme,
  CircularProgress,
} from '@mui/material'
import clsx from 'clsx'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import Icon, { IconProps } from '@components/core/media/Icon'

export type AutocompleteInputProps = {
  id: string
  label?: string
  labelLoading?: string
  options: MCDC.Props.IOption[]
  value?: MCDC.Props.IOption | null
  initialValue?: MCDC.Props.IOption
  inputValue?: string
  icon?: IconProps['variant']
  withoutBorder?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  isClearable?: boolean
  isHighlighted?: boolean
  isAutoSelect?: boolean
  isFreeSolo?: boolean
  isClearOnBlur?: boolean
  isOpenOnFocus?: boolean
  isOpen?: boolean
  isSelectOnFocus?: boolean
  actions?: { label: string; icon: IconProps['variant']; onClick: () => void }[]
  onChange: (
    e: React.SyntheticEvent | undefined,
    value: MCDC.Props.IOption | string | null,
    reason: string
  ) => void
  onInputChange: (
    e: React.SyntheticEvent,
    value: string,
    reason: string
  ) => void
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void
  sx?: MCDC.Props.IDefault['sx']
}

const AutocompleteInput = ({
  id,
  label,
  labelLoading,
  options = [],
  actions = [],
  value,
  initialValue,
  inputValue,
  icon,
  withoutBorder,
  isClearable,
  isDisabled,
  isLoading,
  isHighlighted,
  isAutoSelect,
  isFreeSolo,
  isClearOnBlur,
  isOpenOnFocus,
  isSelectOnFocus,
  isOpen,
  onChange,
  onInputChange,
  onKeyDown,
  sx,
}: AutocompleteInputProps): ReactElement => {
  function GroupComponent({ children, isLoading, ...rest }: any, ref: any) {
    return (
      <List
        {...rest}
        sx={{
          padding: '0 !important',
          pointerEvents: isLoading ? 'none' : undefined,
        }}
        ref={ref}
      >
        {actions.length > 0 && (
          <Box
            sx={(theme: Theme) => ({
              '&not(:last-of-type)': {
                borderBottom: '2px solid',
                borderColor: theme.palette.grey[100],
              },
            })}
          >
            {actions.map((entry, index) => (
              <ListItem
                sx={{ color: 'primary.dark' }}
                onClick={entry.onClick}
                key={index}
              >
                <ListItemIcon
                  sx={{ minWidth: 'auto', mr: 4, color: 'inherit' }}
                >
                  <Icon variant={entry.icon} />
                </ListItemIcon>
                <ListItemText>{entry.label}</ListItemText>
              </ListItem>
            ))}
          </Box>
        )}
        {children}
      </List>
    )
  }

  return (
    <Autocomplete
      id={`${id}-location`}
      options={options}
      value={value}
      defaultValue={initialValue}
      inputValue={inputValue}
      loading={isLoading}
      disableClearable={!isClearable}
      autoSelect={isAutoSelect}
      freeSolo={isFreeSolo}
      clearOnBlur={isClearOnBlur}
      openOnFocus={isOpenOnFocus}
      selectOnFocus={isSelectOnFocus}
      fullWidth
      clearOnEscape
      handleHomeEndKeys
      blurOnSelect
      disabled={isDisabled}
      open={isOpen}
      onKeyDown={onKeyDown}
      getOptionLabel={(option: string | MCDC.Props.IOption) =>
        (option as MCDC.Props.IOption).label || (option as string) || ''
      }
      isOptionEqualToValue={(option1, option2) =>
        option1.label === option2.label
      }
      renderInput={(params) => (
        <TextField
          {...params}
          className={clsx({
            'MuiInputBase-noBorder': withoutBorder,
            [params.InputProps.className]: true,
          })}
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: icon && (
              <InputAdornment
                position="start"
                sx={{ width: '24px', fontWeight: 700 }}
              >
                <Icon variant={icon} size="medium" />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            className: clsx({
              'MuiInputBase-noBorder': withoutBorder,
              [params.InputProps.className]: true,
            }),
            sx: {
              color: isHighlighted ? 'primary.dark' : undefined,
            },
          }}
        />
      )}
      renderOption={(props, option: any, { inputValue }) => {
        const matches = match(option.label, inputValue)
        const parts = parse(option.label, matches)

        return (
          <ListItem {...props} disablePadding>
            <ListItemText>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </ListItemText>
          </ListItem>
        )
      }}
      filterOptions={(options, params) => {
        return options
      }}
      ListboxComponent={forwardRef(GroupComponent)}
      popupIcon={
        !isClearable ? (
          <Icon
            className="MuiSelect-arrowIcon"
            variant="expand_more"
            size="medium"
            sx={{
              pointerEvents: 'none',
            }}
          />
        ) : null
      }
      onInputChange={(e, val, reason) => {
        if (reason === 'reset') return
        onInputChange(e, val, reason)

        if (val === '' && value) {
          onChange(e, null, 'clear')
        }

        if (isHighlighted) {
          onInputChange(e, '', 'reset')
          onChange(e, '', 'reset')
        }
      }}
      onChange={(e, val, reason) => {
        onChange(e, val || '', reason)
      }}
      noOptionsText={GroupComponent({}, null)}
      loadingText={labelLoading}
      sx={sx}
    />
  )
}

export default AutocompleteInput
