import { useState } from 'react'

type FilterOption = { id: string; title: string }
type FilterLevelOption = {
  id: string
  hasMultipleOptions: boolean
  filterOptions: FilterOption[]
}
type SelectedLevelOption = {
  id: string
  hasMultipleOptions: boolean
  selectedOption: FilterOption[]
}
export const useFilter = (filterLevelOptions: FilterLevelOption[]) => {
  const initialLevelOption = filterLevelOptions.map((option, index) => ({
    id: option.id,
    // TODO Temporary disable multiple options for the second filter level: hasMultipleOptions: option.hasMultipleOptions,
    hasMultipleOptions: index === 1 ? false : option.hasMultipleOptions,
    selectedOption: [option.filterOptions[0]],
  }))
  const [selectedLevels, setSelectedLevels] =
    useState<SelectedLevelOption[]>(initialLevelOption)
  const [filterChanged, setFilterChanged] = useState(false)

  const handleSelectedLevel = (id: string, optionId: string) => {
    const selectedLevel = filterLevelOptions.find((option) => option.id === id)
    const selectedOption = selectedLevel?.filterOptions.find(
      (option) => option.id === optionId
    )

    if (!selectedOption) return

    setSelectedLevels((prevSelectedOption) => {
      const levelIndex = prevSelectedOption.findIndex((l) => l.id === id)
      if (levelIndex === -1) {
        // Level does not exist
        return [
          ...prevSelectedOption,
          {
            id,
            selectedOption: [selectedOption],
            hasMultipleOptions: selectedLevel?.hasMultipleOptions || false,
          },
        ]
      }

      // If only one option is allowed, replace the existing option
      let newSelectedOption = [...prevSelectedOption]
      if (!newSelectedOption[levelIndex].hasMultipleOptions) {
        newSelectedOption[levelIndex].selectedOption = [selectedOption]

        // If there is a change in a level where hasMultipleOptions is false, reset all levels where hasMultipleOptions is true to their initialOption
        newSelectedOption = newSelectedOption.map((option) => {
          if (option.hasMultipleOptions) {
            const initialOption = initialLevelOption.find(
              (initialOption) => initialOption.id === option.id
            )?.selectedOption[0]
            return {
              ...option,
              selectedOption: initialOption
                ? [initialOption]
                : option.selectedOption,
            }
          }
          return option
        })

        return newSelectedOption
      }

      // If multiple options are allowed
      let existingOptions = newSelectedOption[levelIndex].selectedOption
      const initialOption = initialLevelOption.find(
        (option) => option.id === id && option.hasMultipleOptions
      )?.selectedOption[0]
      if (!initialOption) return newSelectedOption

      // Find the initial option for the current level and check if the initial option is in the existing options
      const initialOptionExists = existingOptions.some(
        (option) => option.id === initialOption.id
      )
      const selectedOptionExists = existingOptions.some(
        (option) => option.id === selectedOption.id
      )

      if (selectedOption.id === initialOption.id) {
        // If the selected option is the initial option, clear all other options
        existingOptions = [initialOption]
      } else {
        if (initialOptionExists) existingOptions = []
        if (selectedOptionExists) {
          existingOptions = existingOptions.filter(
            (option) => option.id !== selectedOption.id
          )
        } else {
          existingOptions = [...existingOptions, selectedOption]
        }
      }

      if (
        existingOptions.length ===
          (selectedLevel && selectedLevel.filterOptions.length - 1) &&
        newSelectedOption[levelIndex].hasMultipleOptions
      ) {
        existingOptions = [initialOption]
      } else if (
        existingOptions.length === 0 &&
        newSelectedOption[levelIndex].hasMultipleOptions
      ) {
        existingOptions = [initialOption]
      }

      newSelectedOption[levelIndex].selectedOption = existingOptions
      return newSelectedOption
    })

    setFilterChanged(true)
  }

  const isSelected = (option: FilterOption): boolean => {
    return selectedLevels.some((selectedLevel) =>
      selectedLevel.selectedOption.some(
        (selectedOption) => selectedOption.id === option.id
      )
    )
  }

  const resetFilterChanged = () => {
    setFilterChanged(false)
  }

  return {
    selectedLevels,
    handleSelectedLevel,
    isSelected,
    filterChanged,
    resetFilterChanged,
  }
}
