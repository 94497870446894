import React, { ReactElement, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'

import Popup from '@components/core/container/Popup'
import Headline from '@components/core/text/Headline'
import Checkbox from '@components/core/form/fields/Checkbox'
import Copy from '@components/core/text/Copy'
import Button from '@components/core/input/Button'

export type DialogYoutubeConsentProps = {
  onClose?: () => void
  onClick: () => void
  onConsent: (isConsent: boolean) => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function DialogYoutubeConsent({
  onClick,
  onClose,
  onConsent,
  sx,
}: DialogYoutubeConsentProps): ReactElement {
  const intl = useIntl()

  return (
    <Popup sx={sx} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Headline variant="h5" sx={{ mb: 4 }}>
          {intl.messages['dialog.youtubeconsent.headline']}
        </Headline>
        <Copy variant="small" sx={{ mb: 4 }}>
          {intl.messages['dialog.youtubeconsent.copy']}
        </Copy>
        <Checkbox
          name="youtubeConsent"
          onChange={(e, checked) => onConsent(checked)}
          label={intl.messages['dialog.youtubeconsent.checkboxLabel'] as string}
          sx={{ mb: 8 }}
        />
        <Button
          color="primary"
          onClick={() => {
            if (typeof onClose === 'function') {
              onClose()
            }
            onClick()
          }}
        >
          {intl.messages['label.videoLoad'] as string}
        </Button>
      </Box>
    </Popup>
  )
}
