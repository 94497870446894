import React, { ReactElement } from 'react'

import { Box, Container, Theme } from '@mui/material'

import Section from '@components/core/container/Section'
import Image from '@components/core/media/Image'
import Headline from '@components/core/text/Headline'
import Copy from '@components/core/text/Copy'
import Button from '@components/core/input/Button'

export type TeaserImageProps = {
  image: MCDC.Props.IAssetImage
  headline: string
  copy: MCDC.Props.RichText
  linkTo?: MCDC.Props.ILinkTo
  sx?: MCDC.Props.IDefault['sx']
}

export default function TeaserImage({
  image,
  headline,
  copy,
  linkTo,
  sx,
}: TeaserImageProps): ReactElement {
  return (
    <Section
      theme="secondary"
      paddingTop={false}
      paddingBottom={false}
      maxWidth={false}
      sx={sx}
    >
      <Box
        sx={(theme: Theme) => ({
          width: '100%',
          mb: 12,
          [theme.breakpoints.up('md')]: {
            position: 'absolute',
            width: '47%',
            height: '100%',
            mb: 0,
          },
        })}
      >
        <Image
          {...image}
          objectFit="cover"
          sx={(theme: Theme) => ({
            [theme.breakpoints.up('md')]: {
              width: '100%',
              height: '100%',
            },
          })}
        />
      </Box>
      <Container
        sx={(theme: Theme) => ({
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          ...theme.mixins.containerPadding(
            { sm: false, md: true, lg: true },
            true
          ),
        })}
      >
        <Box
          sx={(theme: Theme) => ({
            color: 'text.inverted',
            [theme.breakpoints.up('md')]: {
              width: '53%',
              pl: 20,
              my: 'auto',
            },
          })}
        >
          <Headline variant="h2" sx={{ mb: 4 }}>
            {headline}
          </Headline>
          <Copy component="div" sx={{ mb: 4 }}>
            {copy}
          </Copy>
          {linkTo && (
            <Box component="div">
              <Button
                variant="text"
                color="secondary"
                to={linkTo.url}
                isExternal={linkTo.isExternal}
              >
                {linkTo.label}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Section>
  )
}
