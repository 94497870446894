import React, { ReactElement } from 'react'
import Section from '@components/core/container/Section'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material'

import Headline from '@components/core/text/Headline'
import Button from '@components/core/input/Button'
import Copy from '@components/core/text/Copy'

export type TeaserTextProps = {
  headline: string
  copy: MCDC.Props.RichText
  linkTo?: MCDC.Props.ILinkTo
  sx?: MCDC.Props.IDefault['sx']
}

export default function TeaserText({
  headline,
  copy,
  linkTo,
}: TeaserTextProps): ReactElement {
  return (
    <Section theme="light" paddingSize="small">
      <Box
        sx={(theme: Theme) => ({
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
          },
        })}
      >
        <Headline variant="h3" sx={{ mb: 2 }}>
          {headline}
        </Headline>
        <Copy component="div" variant="body1">
          {copy}
        </Copy>
      </Box>
      {linkTo && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
          <Button
            to={linkTo.url}
            isExternal={linkTo.isExternal}
            color="primary"
            variant="rounded"
            sx={(theme: Theme) => ({
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            })}
          >
            {linkTo.label}
          </Button>
        </Box>
      )}
    </Section>
  )
}
