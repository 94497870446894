import axios, { AxiosResponse } from 'axios'
import { isBrowser } from './functional'

export const kApiHost = isBrowser ? '/api' : process.env.API_URI

export type PositionId =
  | 'INT_VW_BE'
  | 'INT_VW_PRWS'
  | 'INT_REST_AZB'
  | 'INT_REST_DSTD'

export async function search(
  config?: MCDC.API.ISearchConfig
): Promise<AxiosResponse<MCDC.API.ISearchResponse> | undefined> {
  let response: AxiosResponse<MCDC.API.ISearchResponse> | undefined

  try {
    response = await axios.post(`${kApiHost}/job-finder/search`, config || {})
  } catch (e) {
    console.log(e)
  }

  return response
}

export async function searchAggregated(
  config?: MCDC.API.ISearchConfig
): Promise<AxiosResponse<MCDC.API.ISearcAggregatedhResponse> | undefined> {
  let response: AxiosResponse<MCDC.API.ISearcAggregatedhResponse> | undefined

  try {
    response = await axios.post(`${kApiHost}/job-finder/aggregatedsearch`, {
      ...(config || {}),
      query: config?.query || '',
    })
  } catch (e) {
    console.log(e)
  }

  return response
}

export async function searchLocation(
  config?: MCDC.API.ISearchLocationConfig
): Promise<AxiosResponse<MCDC.API.ILocation[]> | undefined> {
  let response: AxiosResponse<MCDC.API.ILocation[]> | undefined

  try {
    response = await axios.get(`${kApiHost}/job-finder/job-location/search`, {
      params: {
        limit: 100,
        ...(config || {}),
      },
    })
  } catch (e) {
    console.log(e)
  }

  return response
}

export async function recommendations(
  config?: MCDC.API.ISearchConfig
): Promise<AxiosResponse<MCDC.API.ISearchResponse> | undefined> {
  let response: AxiosResponse<MCDC.API.ISearchResponse> | undefined

  try {
    response = await axios.post(`${kApiHost}/job-finder/recommendation`, {
      params: {
        ...(config || {}),
        pager: {
          ...(config?.pager || {}),
          size: 3,
        },
      },
    })
  } catch (e) {
    console.log(e)
  }

  return response
}

export async function searchSuggest(
  config?: MCDC.API.ISearchConfig
): Promise<AxiosResponse<MCDC.API.ISearchSuggestResponse> | undefined> {
  let response: AxiosResponse<MCDC.API.ISearchSuggestResponse> | undefined

  try {
    response = await axios.post(`${kApiHost}/job-finder/suggest`, {
      ...(config || {}),
      pager: {},
    })
  } catch (e) {
    console.log(e)
  }

  return response
}

export async function getJobDetail(
  refId: string
): Promise<AxiosResponse<MCDC.API.IJobDetail> | undefined> {
  let response: AxiosResponse<MCDC.API.IJobDetail> | undefined

  try {
    response = await axios.get(`${kApiHost}/job-finder/job-jobdetails/${refId}`)
  } catch (e) {
    console.log(e)
  }

  return response
}

export async function getAllJobTemplates(): Promise<
  AxiosResponse<MCDC.API.IJobTemplate[]> | undefined
> {
  let response: AxiosResponse<MCDC.API.IJobTemplate[]> | undefined

  try {
    response = await axios.get(`${kApiHost}/job-finder/job-template/all`)
  } catch (e) {
    console.log(e)
  }

  return response
}

export async function getMeta(): Promise<
  AxiosResponse<MCDC.API.IMetaResponse> | undefined
> {
  let response: AxiosResponse<MCDC.API.IMetaResponse> | undefined

  try {
    response = await axios.get(`${kApiHost}/job-finder/meta`)
  } catch (e) {
    console.log(e)
  }

  return response
}
