/* eslint-disable @typescript-eslint/no-explicit-any */

import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'

const useContentfulPageJobProfile = (): MCDC.Contentful.IPageProfile[] => {
  const { locale } = useIntl()
  const { data } = useStaticQuery(
    graphql`
      query {
        data: allContentfulPageProfile {
          nodes {
            node_locale
            identifier
            title
            identifier
            imageTeaser {
              ...GlobalImageFragmentStage
            }
            fields {
              linkTo {
                url
              }
            }
          }
        }
      }
    `
  )

  return (
    data.nodes?.filter(
      (entry: MCDC.Contentful.IPageProfile) => entry.node_locale === locale
    ) || []
  )
}
export default useContentfulPageJobProfile
