import Box from '@mui/material/Box/Box'
import { Theme } from '@mui/material'

import Section from '@components/core/container/Section'
import Headline from '@components/core/text/Headline'
import { EducationTeaserItemProps } from './EducationTeaserItem'

import React from 'react'
import Copy from '@components/core/text/Copy'
import EducationTeaserItem from './EducationTeaserItem'

interface EducationTeaserProps {
  headline: string
  copy: string
  items: Array<EducationTeaserItemProps>
}

export default function EducationTeaser({
  headline,
  copy,
  items,
}: EducationTeaserProps) {
  return (
    <Section maxWidth="md">
      <Headline variant="h2" sx={{ mt: 6 }}>
        {headline}
      </Headline>
      <Copy
        variant="body1"
        sx={(theme: Theme) => ({
          mb: 12,
          mt: 4,
          [theme.breakpoints.up('md')]: {
            maxWidth: '75%',
          },
        })}
      >
        {copy}
      </Copy>
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          width: '100%',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
        })}
      >
        {items.map((item, index) => (
          <EducationTeaserItem key={index} {...item} />
        ))}
      </Box>
    </Section>
  )
}
