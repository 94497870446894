import { alpha, Components, Theme } from '@mui/material'

export const MenuOverrides = (theme: Theme): Components['MuiMenu'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {},
  },
})

export const MenuItemOverrides = (theme: Theme): Components['MuiMenuItem'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {
      minHeight: '54px !important',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
      '.MuiTouchRipple-root': {
        display: 'none',
      },
    },
    selected: {},
  },
})
