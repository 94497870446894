import React, { ReactElement } from 'react'

import Section from '@components/core/container/Section'
import Headline from '@components/core/text/Headline'
import { Box, Breakpoint, Theme } from '@mui/material'
import Copy from '@components/core/text/Copy'

export type BenefitsProps = {
  headline: string
  items: MCDC.Props.IGlobalBenefit[]
  sx?: MCDC.Props.IDefault['sx']
  maxWidth?: Breakpoint
}

export default function Benefits({
  headline,
  items,
}: BenefitsProps): ReactElement {
  return (
    <Section>
      <Headline
        variant="h3"
        sx={(theme: Theme) => ({
          mb: 12,
          [theme.breakpoints.up('md')]: {
            m: 'auto',
            mb: 12,
          },
        })}
      >
        {headline}
      </Headline>
      <Box
        sx={(theme: Theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'flex',
          },
        })}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={(theme: Theme) => ({
              display: 'flex',
              alignItems: 'center',
              mb: 8,
              '&:last-of-type': {
                mb: 0,
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'column',
                width: '100%',
                mx: 4,
                mb: 0,
                '&:first-of-type': {
                  ml: 0,
                },
                '&:last-of-type': {
                  mr: 0,
                },
              },
            })}
          >
            <Box
              sx={(muiTheme: Theme) => ({
                minWidth: '48px',
                mr: 4,
                lineHeight: 1,
                [muiTheme.breakpoints.up('md')]: {
                  height: '88px',
                  mr: 0,
                  mb: 4,
                  textAlign: 'center',
                  '> svg': {
                    height: '100%',
                  },
                },
              })}
              dangerouslySetInnerHTML={{ __html: item.icon?.content }}
            />

            <Copy
              component="div"
              sx={(theme: Theme) => ({
                [theme.breakpoints.up('md')]: { textAlign: 'center' },
              })}
            >
              {item.description}
            </Copy>
          </Box>
        ))}
      </Box>
    </Section>
  )
}
