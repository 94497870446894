import Cookies from 'js-cookie'
import { isBrowser } from './functional'
import {
  kCookieMethodCookie,
  kCookieMethodLocalStorage,
  kCookieMethodSessionStorage,
  kCookieExpiringDate,
  kIsSecureCookie,
} from './constants'

export interface ICookie {
  value: string | null
  expiringDate?: number
}

//-------------------------------------------------------------------
// Cookie Handling base Methods
//-------------------------------------------------------------------

export const getCookie = (methodType = kCookieMethodCookie): any[] => {
  if (!isBrowser) {
    return []
  }

  switch (methodType) {
    case kCookieMethodCookie: {
      return Cookies.get() as any
    }
    case kCookieMethodLocalStorage: {
      if (localStorage) {
        const keys = Object.keys(localStorage)
        return keys.map(
          (key) => (localStorage[key] = localStorage.getItem(key))
        )
      }
      return []
    }
    case kCookieMethodSessionStorage: {
      if (sessionStorage) {
        const keys = Object.keys(sessionStorage)
        return keys.map(
          (key) => (sessionStorage[key] = sessionStorage.getItem(key))
        )
      }
      return []
    }
    default:
      return []
  }
}

export const getCookieById = (
  cookieId: string,
  methodType = kCookieMethodCookie
) => {
  if (!isBrowser) {
    return null
  }

  switch (methodType) {
    case kCookieMethodCookie: {
      return Cookies.get(cookieId)
    }
    case kCookieMethodLocalStorage: {
      if (localStorage) {
        return localStorage.getItem(cookieId)
      }
      return null
    }
    case kCookieMethodSessionStorage: {
      if (sessionStorage) {
        return sessionStorage.getItem(cookieId)
      }
      return null
    }
  }
}

export const setCookieById = (
  cookieId: string,
  payload: string | boolean | number,
  methodType = kCookieMethodCookie,
  expiringDate: number,
) => {
  if (!isBrowser) {
    return false
  }

  switch (methodType) {
    case kCookieMethodCookie: {
      return Cookies.set(cookieId, JSON.stringify(payload), {
        sameSite: 'strict',
        expires: expiringDate || kCookieExpiringDate,
        secure: kIsSecureCookie,
      })
    }
    case kCookieMethodLocalStorage: {
      if (localStorage) {
        localStorage.setItem(cookieId, JSON.stringify(payload))
        return true
      }
      return false
    }
    case kCookieMethodSessionStorage: {
      if (sessionStorage) {
        sessionStorage.setItem(cookieId, JSON.stringify(payload))
        return true
      }
      return false
    }
  }
}

//-------------------------------------------------------------------
// Cookie Handling QoL Methods
//-------------------------------------------------------------------

export const getLocalStorage = () => {
  return getCookie(kCookieMethodLocalStorage)
}

export const getSessionStorage = () => {
  return getCookie(kCookieMethodSessionStorage)
}

export const getLocalStorageById = (cookieId: string) => {
  return getCookieById(cookieId, kCookieMethodLocalStorage)
}

export const getSessionStorageById = (cookieId: string) => {
  return getCookieById(cookieId, kCookieMethodSessionStorage)
}

export const setLocalStorageById = (
  cookieId: string,
  payload: string | boolean | number,
  expiringDate: number,
) => {
  return setCookieById(cookieId, payload, kCookieMethodSessionStorage, expiringDate)
}

export const setSessionStorageById = (
  cookieId: string,
  payload: string | boolean | number,
  expiringDate: number,
) => {
  return setCookieById(cookieId, payload, kCookieMethodSessionStorage, expiringDate)
}
