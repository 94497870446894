import React, { ReactElement } from 'react'
import { Link as MuiLink } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'

export type LinkProps = {
  id?: string
  to?: string
  underline?: 'none' | 'always' | 'hover'
  variant?: 'body1' | 'navigation'
  isDownload?: boolean
  isExternal?: boolean
  isActive?: boolean
  onClick?: (ev?: any) => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  children?: MCDC.Props.IDefault['children']
  sx?: MCDC.Props.IDefault['sx']
}

export default function Link({
  id,
  children,
  to,
  isExternal = false,
  isDownload = false,
  isActive = false,
  underline = 'hover',
  variant = 'body1',
  onClick,
  onMouseOver,
  onMouseOut,
  sx,
}: LinkProps): ReactElement {
  const className = clsx({
    ['Mui-active']: isActive,
  })

  const LinkComponent =
    !isExternal && to ? GatsbyLink : !to && onClick ? 'button' : 'a'
  const additionalProps =
    isExternal || isDownload
      ? { href: to, target: '_blank', rel: 'noreferrer', download: isDownload }
      : { to }

  return (
    <MuiLink
      {...additionalProps}
      id={id}
      className={className}
      variant={variant}
      underline={underline}
      component={LinkComponent}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      sx={sx}
    >
      {children}
    </MuiLink>
  )
}
