import { useContext } from 'react'
import {
  ISnackbarContext,
  SnackbarContext,
} from '../providers/SnackbarProvider'

export default function useSnackbar(): ISnackbarContext {
  const SnackbarValues = useContext<ISnackbarContext>(SnackbarContext)

  return SnackbarValues
}
