/* eslint-disable */
import { isBrowser } from './functional'

export const removeTrackingConsent = (): void => {
  if (!isBrowser) {
    return
  }

  // @ts-ignore
  if (_paq) {
    // @ts-ignore
    _paq.push(['forgetCookieConsentGiven'])
  }
}

export const giveTrackingConsent = (): void => {
  if (!isBrowser) {
    return
  }

  // @ts-ignore
  if (_paq) {
    // @ts-ignore
    _paq.push(['setConsentGiven'])
  }
}

export const trackEvent = (
  eventCategory: string,
  eventAction: string,
  eventName: string,
  eventValue: number = 0
): void => {
  if (!isBrowser) {
    return
  }

  // @ts-ignore
  if (_paq) {
    // @ts-ignore
    _paq.push(['trackEvent', eventCategory, eventAction, eventName, eventValue])
  }
}
