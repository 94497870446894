import React, { ReactElement } from 'react'
import { Chip as MuiChip } from '@mui/material'
import Icon from '@components/core/media/Icon'
import clsx from 'clsx'

export type ChipProps = {
  label: string
  variant?: 'filled' | 'outlined'
  color?: 'default' | 'primary' | 'secondary' | 'info'
  size?: 'small' | 'medium' | 'large'
  isSelected?: boolean
  isDisabled?: boolean
  isBold?: boolean
  isClearable?: boolean
  onClick?: () => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function Chip({
  label,
  onClick,
  variant = 'filled',
  color = 'default',
  isSelected = false,
  isDisabled = false,
  isClearable = false,
  isBold = true,
  size = 'medium',
  sx = {},
}: ChipProps): ReactElement {
  return (
    <MuiChip
      className={clsx({
        'Mui-selected': isSelected,
        'Mui-bold': isBold,
        'MuiChip-large': size === 'large',
      })}
      sx={sx}
      label={label}
      variant={variant}
      size={size !== 'large' ? size : undefined}
      color={color}
      deleteIcon={
        isClearable ? <Icon variant="close" sx={{ ml: 2 }} /> : undefined
      }
      onClick={onClick}
      onDelete={isClearable ? onClick : undefined}
      disabled={isDisabled}
    />
  )
}
