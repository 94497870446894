import React, { ReactElement } from 'react'
import { Box, styled } from '@mui/material'

import LogoIcon from '@static/images/logo.svg'

const StyledWrapper = styled('div')({
  display: 'inline-block',
  '> svg': {
    verticalAlign: 'middle',
  },
})

// eslint-disable-next-line @typescript-eslint/ban-types
export type LogoProps = {
  sx?: MCDC.Props.IDefault['sx']
}

/**
 * Logo
 */
export default function Logo({ sx }: LogoProps): ReactElement | null {
  return (
    <StyledWrapper sx={sx}>
      <LogoIcon style={{ veticalAlign: 'middle' }} />
    </StyledWrapper>
  )
}
