import React, { ReactElement } from 'react'
import { Box, Breakpoint, Theme } from '@mui/material'

import Section from '@components/core/container/Section'
import Slider from '@components/core/container/Slider'
import JobFactCard from './components/JobFactCard'

export type JobFactsProps = {
  items: MCDC.Props.IGlobalJobFact[]
  maxWidth?: Breakpoint
  sx?: MCDC.Props.IDefault['sx']
}

export default function JobFacts({
  items = [],
  maxWidth,
  sx,
}: JobFactsProps): ReactElement {
  return (
    <Section theme="primary" sx={[sx]} maxWidth={maxWidth} paddingSize="small">
      <Box sx={{ position: 'relative' }}>
        <Slider
          variant="custom"
          theme="primary"
          arrows="overlay"
          isLoop
          sx={(theme: Theme) => ({
            '.swiper-slide': {
              backgroundColor: 'primary.main',
              background:
                'linear-gradient(90deg, rgba(255,188,13,0) 0%, rgba(255,188,13,1) 50%, rgba(255,188,13,0) 100%)',
            },
            [theme.breakpoints.up('md')]: { px: 16 },
          })}
        >
          {items.map((item, index) => (
            <JobFactCard {...item} sx={{ mx: 'auto' }} key={index} />
          ))}
        </Slider>
      </Box>
    </Section>
  )
}
