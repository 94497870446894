import speedeeRegularWoff2 from '../../../static/fonts/Speedee_W_Rg.woff2'
import speedeeRegularWoff from '../../../static/fonts/Speedee_W_Rg.woff'
import speedeeBoldWoff2 from '../../../static/fonts/Speedee_W_Bd.woff2'
import speedeeBoldWoff from '../../../static/fonts/Speedee_W_Bd.woff'

export const speedeeRegular = {
  fontFamily: 'Speedee',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${speedeeRegularWoff2}) format('woff2'),
        url(${speedeeRegularWoff}) format('woff')`,
}

export const speedeeBold = {
  fontFamily: 'Speedee',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `url(${speedeeBoldWoff2}) format('woff2'),
        url(${speedeeBoldWoff}) format('woff')`,
}

export const fontFamily = 'Speedee, Helvetica, Arial, sans-serif'
