import { Components, Theme } from '@mui/material'

export const AlertOverrides = (theme: Theme): Components['MuiAlert'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {
      minWidth: '300px',
      borderRadius: '8px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
})
