import React from 'react'
import { Box, Theme } from '@mui/material'
import { YouTubeEvent } from 'react-youtube'

import Copy from '@components/core/text/Copy'
import Headline from '@components/core/text/Headline'
import DripSvg from '@static/images/icons/drip.svg'
import Image from '@components/core/media/Image'
import FaqVideo from '@components/modules/FaqVideo'

export type AmbassadorFaqItemProps = {
  headline: string
  subline: string
  image: MCDC.Props.IAssetImage
  ambassadorFilter: { filterLevel: FilterLevelOption[] }
  faqVideo: MCDC.Props.IGlobalFaqVideo[]
  fullWidth?: boolean
  setActiveVideo?: (newActiveVideo: YouTubeEvent) => void
  sx?: MCDC.Props.IDefault['sx']
}

type FilterOption = { id: string; title: string }
type FilterLevelOption = {
  id: string
  hasMultipleOptions: boolean
  filterOptions: FilterOption[]
}

export default function AmbassadorFaqItem({
  image,
  headline,
  subline,
  faqVideo,
  fullWidth,
  setActiveVideo,
}: AmbassadorFaqItemProps) {
  return (
    <Box
      sx={(theme: Theme) => ({
        backgroundColor: theme.palette.common.white,
        width: fullWidth ? '100%' : '264px',
        borderRadius: '8px',
        padding: 2,
      })}
    >
      <Box
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.background.primary,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
          margin: theme.spacing(-2, -2, 10, -2),
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: '-32px',
            left: '0',
            zIndex: 1,
          }}
        >
          <DripSvg />
        </Box>
        <Box
          sx={{
            padding: 4,
          }}
        >
          <Image
            {...image}
            sx={{
              borderRadius: '50%',
              height: '70px',
              width: '70px',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            paddingRight: 4,
          }}
        >
          <Headline variant="h5">{headline}</Headline>
          <Copy component="div" sx={{ display: 'block' }}>
            {subline}
          </Copy>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 4,
        }}
      >
        {faqVideo && (
          <FaqVideo
            faqVideo={faqVideo}
            fullWidth={fullWidth}
            setActiveVideo={setActiveVideo}
          />
        )}
      </Box>
    </Box>
  )
}
