import React, {ReactElement, useState} from 'react'
import { Box, IconButton, Theme } from '@mui/material'
import useChatbot from '@components/site/hooks/chatbot/useChatbot'
import Button from '@components/core/input/Button'
import SpeechbubbleSvg from '@static/images/icons/speechbubble.svg'
import { kCookieBannerCookieId, kEventActionChatbotButton, kCookieMethodCookie } from '@utils/constants'
import CookieBanner from "@components/modules/CookieBanner";
import { useIntl } from "react-intl";
import { getCookieById } from "@utils/cookies";

export type PageFooterProps = {
  linkTo?: MCDC.Props.IButtonTo
  showCookieBanner: boolean
  sx?: MCDC.Props.IDefault['sx']
}

export default function PageFooter({
  linkTo,
  showCookieBanner,
  sx,
}: PageFooterProps): ReactElement | null {
  const { openChatbot, isChatbotLoaded, isChatbotVisible } = useChatbot()
  const intl = useIntl()

  const initialCookieBannerVisibility = getCookieById(kCookieBannerCookieId, kCookieMethodCookie)
  const [cookieBannerVisibility, setCookieBannerVisibility] = useState(!initialCookieBannerVisibility)

  return (
    <Box sx={sx}>
      {showCookieBanner && isChatbotVisible && !isChatbotLoaded && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            transform: 'translateY(-100%)',
            width: '100%',
          }}
        >
          <IconButton
            onClick={() => openChatbot(kEventActionChatbotButton)}
            sx={[
              {
                position: 'absolute',
                top: '-70px',
                right: '16px',
                width: '54px',
                height: '54px',
                color: 'common.white',
                boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.15)',
                '&.MuiIconButton-root': {
                  border: 'none',
                  backgroundColor: 'primary.main',
                },
                '&.MuiIconButton-root:hover': {
                  backgroundColor: 'primary.dark',
                },
              },
              sx,
            ]}
          >
            <Box component={SpeechbubbleSvg} sx={{ width: '30px' }} />
          </IconButton>
          {cookieBannerVisibility &&
            <CookieBanner
              copy={intl.messages['label.cookieBanner'] as string}
              onClose={() => {setCookieBannerVisibility(false)}}
              sx={{}}
            />
          }
        </Box>
      )}
      {!showCookieBanner && isChatbotVisible && !isChatbotLoaded && (
        <Box
          sx={{
            position: 'absolute',
            top: '-16px',
            right: '16px',
            display: 'flex',
            ml: 'auto',
            transform: 'translateY(-100%)',
          }}
        >
          <IconButton
            onClick={() => openChatbot(kEventActionChatbotButton)}
            sx={[
              {
                width: '54px',
                height: '54px',
                color: 'common.white',
                boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.15)',
                '&.MuiIconButton-root': {
                  border: 'none',
                  backgroundColor: 'primary.main',
                },
                '&.MuiIconButton-root:hover': {
                  backgroundColor: 'primary.dark',
                },
              },
              sx,
            ]}
          >
            <Box component={SpeechbubbleSvg} sx={{ width: '30px' }} />
          </IconButton>
        </Box>
      )}
      {linkTo && (
        <>
          <Box
            sx={(theme: Theme) => ({
              backgroundColor: 'common.white',
              boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.15)',
              [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'center',
                py: 3,
              },
            })}
          >
            <Button
              to={linkTo.url}
              isExternal={linkTo.isExternal}
              isLoading={linkTo.isLoading}
              isDisabled={linkTo.isDisabled}
              onClick={linkTo.onClick}
              color="primary"
              sx={(theme: Theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                  borderRadius: '0 !important',
                },
                [theme.breakpoints.up('md')]: {
                  minWidth: '434px',
                },
              })}
            >
              {linkTo.label}
            </Button>
          </Box>
          <Box
            sx={(theme: Theme) => ({
              width: '100%',
              height: isChatbotLoaded ? '46px' : 0,
              backgroundColor: 'background.default',
              transition: 'height 700ms ease',
              [theme.breakpoints.up('md')]: {
                height: isChatbotLoaded ? '56px' : 0,
              },
            })}
          />
        </>
      )}
    </Box>
  )
}
