import React, { ReactElement } from 'react'

import { Box, Theme } from '@mui/material'
import Image from '@components/core/media/Image'
import Headline from '@components/core/text/Headline'
import { StyledGatsbyLink, StyledLink } from '@utils/motion'
import { StyledComponent } from '@emotion/styled'

export type DeepLinkCardProps = {
  image: MCDC.Props.IAssetImage
  linkTo: MCDC.Props.ILinkTo
  sx?: MCDC.Props.IDefault['sx']
}

export default function DeepLinkCard({
  linkTo,
  image,
  sx,
}: DeepLinkCardProps): ReactElement | null {
  if (!image) return null
  const LinkComponent: StyledComponent<any, any, any> = linkTo.isExternal
    ? StyledLink
    : StyledGatsbyLink
  const additionalProps = linkTo.isExternal
    ? { href: linkTo.url }
    : { to: linkTo.url }
  return (
    <LinkComponent
      {...additionalProps}
      target={linkTo.isExternal ? '_blank' : undefined}
      rel={linkTo.isExternal ? 'noreferrer' : undefined}
      sx={[
        sx,
        {
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          borderRadius: '8px',
          textDecoration: 'none',
          backgroundColor: 'background.default',
          transform: 'translateZ(0)',
        },
      ]}
    >
      <Image
        data={image.data}
        scaleToFit
        objectFit="cover"
        objectPosition="top center"
      />
      <Box
        sx={(theme: Theme) => ({
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          p: 6,
          // zIndex Hack: Image has Transform
          // TODO: Find a proper Solution
          // https://www.freecodecamp.org/news/4-reasons-your-z-index-isnt-working-and-how-to-fix-it-coder-coder-6bc05f103e6c/
          transform: 'translateZ(0)',
          zIndex: 1,
          [theme.breakpoints.up('md')]: {},
        })}
      >
        <Headline variant="h5" sx={{ width: '50%' }} isHtml>
          {linkTo.label}
        </Headline>
      </Box>
    </LinkComponent>
  )
}
