import { Theme } from '@mui/material/'
import {
  ColorPartial,
  PaletteOptions,
} from '@mui/material/styles/createPalette'

/**
 * Used https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 * for [Shade] = hex based on styleguide colors.
 */
const Colors: Record<string, ColorPartial> = {
  grey: {
    // style guide color
    100: '#F7F7F7',
    // style guide color
    200: '#E6E6E6',
    // style guide color
    300: '#C1C1C1',
    // style guide color
    400: '#969696',
    500: '#aeaeae',
    600: '#848484',
    700: '#6f6f6f',
    800: '#4F4F4F',
    // style guide color
    900: '#2d2d2d',
  },
}

const primary = {
  contrastText: '#fff',
  light: '#FFC93D',
  main: '#FFBC0D',
  dark: '#FFBC0D',
}

const secondary = {
  contrastText: '#fff',
  light: '#264F36',
  main: '#264F36',
  dark: '#264F36',
}

const tertiary = {
  contrastText: '#fff',
  light: '#DB0007',
  main: '#DB0007',
  dark: '#DB0007',
}

export const PaletteOverrides = (theme: Theme): PaletteOptions => {
  return {
    ...theme.palette,
    mode: 'light',
    primary,
    secondary,
    tertiary,
    background: {
      default: Colors.grey[100],
      paper: Colors.grey[100],
      primary: primary.main,
      secondary: secondary.main,
      light: theme.palette.common.white,
      medium: Colors.grey[100],
      dark: Colors.grey[900],
    },
    text: {
      disabled: Colors.grey[900],
      primary: Colors.grey[900],
      secondary: Colors.grey[900],
      inverted: theme.palette.common.white,
    },
    success: {
      main: secondary.main,
    },
    error: {
      main: tertiary.main,
    },
    grey: Colors.grey,
  }
}
