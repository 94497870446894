import { ISearchContext } from '@components/site/providers/SearchProvider'
import { useState } from 'react'
import { useStore } from '@nanostores/react'

import {
  searchQuery,
  searchQueryIsBusy,
  searchQueryOptions,
} from '@store/search'

type UseSearchQueryReturn = {
  query: string
  queryOption?: MCDC.Props.IOption | null
  queryOptions?: MCDC.Props.IOption[]
  isBusy: boolean
  setQuery: (value: string) => void
  setQueryOption: (option?: MCDC.Props.IOption | string | null) => void
}

export default function useSearchQuery(
  searchState?: ISearchContext,
  submitPath?: string
): UseSearchQueryReturn {
  const isBusy = useStore(searchQueryIsBusy)
  const query = useStore(searchQuery)
  const queryOptions = useStore(searchQueryOptions)
  const [queryOption, setQueryOptionState] =
    useState<MCDC.Props.IOption | null>()

  function setQuery(value: string) {
    searchState?.setQuery(value)
  }

  function setQueryOption(value?: string | MCDC.Props.IOption | null) {
    if (typeof value === 'string') {
      searchState?.setQuery(value, undefined, submitPath, true)
      setQueryOptionState({ label: value, value: value })
    } else {
      searchState?.setQuery(
        value?.label || '',
        value ? { title: value.label } : undefined,
        submitPath
      )
      setQueryOptionState(value)
    }
  }

  return {
    isBusy,
    query,
    queryOption,
    queryOptions,
    setQuery,
    setQueryOption,
  }
}
