import { Components, Theme } from '@mui/material'

export const ContainerOverrides = (
  theme: Theme
): Components['MuiContainer'] => ({
  variants: [
    {
      props: { maxWidth: 'sm' },
      style: {
        maxWidth: `${theme.spacing(theme.maxWidth('sm'))} !important`,
      },
    },
    {
      props: { maxWidth: 'md' },
      style: {
        maxWidth: `${theme.spacing(theme.maxWidth('md'))} !important`,
      },
    },
    {
      props: { maxWidth: 'lg' },
      style: {
        maxWidth: `${theme.spacing(theme.maxWidth('lg'))} !important`,
      },
    },
    {
      props: { maxWidth: 'xl' },
      style: {
        maxWidth: `${theme.spacing(theme.maxWidth('xl'))} !important`,
      },
    },
  ],
  styleOverrides: {
    root: {
      ...theme.mixins.containerGutters,
    },
  },
})
