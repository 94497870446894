import React, { ReactElement, useCallback, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Swiper as SwiperInstance, SwiperOptions } from 'swiper'
import { Box, Theme } from '@mui/material'

import Breakpoints from '@config/theme/definitions/breakpoints'
import muiTheme from '@config/theme'
import { SliderUi } from './components/SliderArrows'

import 'swiper/css'
import 'swiper/css/pagination'

const scaleZoomProgress = (swiper: SwiperInstance, progress: number) => {
  const totalSlides = swiper.slides.length - 1
  const slideWidth = swiper.width
  const stepSize = 1 / totalSlides
  swiper.slides.forEach((slide: any, index) => {
    const slideProgress = (index / totalSlides - progress) / stepSize
    if (slideProgress < 0) {
      slide.style.opacity = 1 + slideProgress
      slide.style.transform = `
        translateX(${-slideProgress * slideWidth}px)
      `
    } else if (slideProgress >= 0) {
      slide.style.transform = 'translateX(0%) scale(1)'
      slide.style.width = `100%`
      slide.style.opacity = 1
    }
  })
}

const scaleZoomSetTransition = (swiper: SwiperInstance, transition: number) => {
  swiper.slides.forEach((slide: any) => {
    slide.style.transition = `width ${transition}ms ease, transform ${transition}ms ease, opacity ${transition}ms ease`
  })
}

type SliderBreakpointsType = {
  sm?: SwiperOptions
  md?: SwiperOptions
  lg?: SwiperOptions
}

export type SliderProps = MCDC.Props.IDefault & {
  theme?: MCDC.Props.ThemeType
  variant?: 'default' | 'custom'
  arrows?: 'overlay' | 'none'
  slidesPerView?: number | 'auto'
  initialSlide?: number
  onChangeSlide?: (activeIndex: number) => void
  withOverflow?: MCDC.Props.BreakpointFlags | boolean
  isLoop?: boolean
  isAutoHeight?: boolean
  breakpoints?: SliderBreakpointsType
  children: MCDC.Props.IDefault['children']
  sx?: MCDC.Props.IDefault['sx']
}
export default function Slider({
  theme,
  variant = 'default',
  arrows,
  slidesPerView = 1,
  isLoop = false,
  isAutoHeight = false,
  initialSlide = 0,
  breakpoints,
  onChangeSlide,
  children,
  sx,
  withOverflow,
}: SliderProps): ReactElement {
  const [, updateState] = useState<any>()
  const forceUpdate = useCallback(() => updateState({}), [])
  const slides = React.Children.toArray(children)
  const isDefaultTheme = !theme || theme !== 'primary'
  const isCustomVariant = variant === 'custom'
  const withOverflowBreakpoints: MCDC.Props.BreakpointFlags = {
    sm: !!withOverflow,
    md: !!withOverflow,
    lg: !!withOverflow,
    ...(typeof withOverflow === 'object' ? withOverflow : {}),
  }

  const additionalProps = isCustomVariant
    ? {
        // dir: 'rtl',
        onProgress: scaleZoomProgress,
        onSetTransition: scaleZoomSetTransition,
      }
    : {}

  function getBreakpointOptions() {
    return Object.entries(breakpoints || {}).reduce(
      (obj: SliderBreakpointsType, [key, value]) => ({
        ...obj,
        [`${(Breakpoints?.values as any)?.[key]}`]: value as number,
      }),
      {}
    )
  }

  return (
    <Box
      sx={[
        sx,
        (theme: Theme) => ({
          position: 'relative',
          pb: 12,
          '> .swiper': {
            position: 'initial',
            overflow: withOverflowBreakpoints.sm ? 'visible' : undefined,
            [theme.breakpoints.up('md')]: {
              overflow: withOverflowBreakpoints.md ? 'visible' : 'hidden',
            },
            [theme.breakpoints.up('lg')]: {
              overflow: withOverflowBreakpoints.lg ? 'visible' : 'hidden',
            },
          },
          '.swiper': {
            '&-slide': {
              direction: 'ltr',
            },
            '&-pagination': {
              bottom: '0 !important',
              direction: 'ltr',
              '&-bullet': {
                width: '12px',
                height: '12px',
                backgroundColor: isDefaultTheme
                  ? muiTheme.palette.grey[300]
                  : 'common.white',
                opacity: !isDefaultTheme ? 1 : undefined,
                '&-active': {
                  backgroundColor: isDefaultTheme
                    ? 'primary.main'
                    : 'tertiary.main',
                },
              },
            },
          },
        }),
      ]}
    >
      <Swiper
        speed={750}
        spaceBetween={16}
        touchReleaseOnEdges={true}
        loop={isLoop}
        autoHeight={isAutoHeight}
        observer={!!breakpoints}
        initialSlide={initialSlide}
        slidesPerView={slidesPerView}
        breakpoints={getBreakpointOptions()}
        onResize={() => {
          forceUpdate()
        }}
        onSlideChangeTransitionEnd={() => {
          forceUpdate()
        }}
        onSlideChange={(swiper: SwiperInstance) => {
          if (onChangeSlide) onChangeSlide(swiper.realIndex)
        }}
        modules={slides.length > 0 ? [Pagination] : undefined}
        pagination={{ clickable: true }}
        {...additionalProps}
      >
        {slides.map((child, index) => {
          return (
            <SwiperSlide key={(child as any).key || index}>{child}</SwiperSlide>
          )
        })}
        {arrows !== 'none' && (
          <SliderUi
            loop={isLoop}
            theme={theme}
            sx={(theme: Theme) => ({
              mt: slides.length > 1 ? -6 : 0,
              [theme.breakpoints.down('md')]: { display: 'none' },
            })}
          />
        )}
      </Swiper>
    </Box>
  )
}
