import React, { ReactElement, SyntheticEvent } from 'react'
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material'

export type CheckboxProps = {
  id?: string
  name: string
  label: string
  value?: string
  isDisabled?: boolean
  isChecked?: boolean
  isDefaultChecked?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function Checkbox({
  id,
  name,
  label,
  value,
  isChecked,
  isDefaultChecked,
  isDisabled,
  onChange,
  sx,
}: CheckboxProps): ReactElement {
  return (
    <FormControlLabel
      sx={sx}
      control={<MuiCheckbox defaultChecked={isDefaultChecked} size="medium" />}
      id={id}
      name={name}
      label={label}
      value={value}
      checked={isChecked}
      onChange={
        onChange as (
          e: SyntheticEvent<Element, Event>,
          checked: boolean
        ) => void
      }
      disabled={isDisabled}
    />
  )
}
