import { HistoryLocation } from '@gatsbyjs/reach-router'
import { atom, action } from 'nanostores'

import { defaultLang, tLocales } from '../translations'

export const globalLocation = atom<HistoryLocation | undefined>()
export const globalLocale = atom<tLocales>(defaultLang)

/**
 * Search Location
 */

export const setGlobalLocation = action(
  globalLocation,
  'setGlobalhLocation',
  (store, value?: HistoryLocation) => {
    store.set(value)
    return store.get()
  }
)

export const setGlobalLocale = action(
  globalLocale,
  'setGlobalLocale',
  (store, value: tLocales) => {
    store.set(value)
    return store.get()
  }
)
