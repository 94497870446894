import React, { ReactElement, useEffect, useState, useRef } from 'react'
import { Box, Theme } from '@mui/material'
import { AnimatePresence } from 'framer-motion'

import {
  lightboxDialogMotionProps,
  lightboxDimmerMotionProps,
  StyledMotionDiv,
} from '@utils/motion'
import { DialogYoutubeConsentProps } from '@components/core/dialog/DialogYoutubeConsent'

export type DialogConfigType = {
  id: string
  props: DialogYoutubeConsentProps
  component: any
}

export type LightboxProps = MCDC.Props.IDefault & {
  currentDialog?: DialogConfigType
  isOpen: boolean
  onClose?: () => void
  onHide?: () => void
}

export default function Lightbox({
  currentDialog,
  isOpen = false,
  onClose,
  onHide,
}: LightboxProps): ReactElement {
  const [isOverflow, setIsOverflow] = useState(false)
  const [stateDialog, setStateDialog] = useState(currentDialog)
  const containerRef = useRef<HTMLElement>()

  useEffect(() => {
    if (currentDialog && currentDialog?.id !== stateDialog?.id) {
      setStateDialog(currentDialog)
    }
  }, [currentDialog])

  useEffect(() => {
    const resizeHandler = () => {
      if (!containerRef.current) return
      const currentIsOverflow =
        containerRef.current.scrollHeight > containerRef.current.clientHeight

      if (!isOverflow && currentIsOverflow) {
        setIsOverflow(true)
      }

      if (isOverflow && !currentIsOverflow) {
        setIsOverflow(false)
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => window.removeEventListener('resize', resizeHandler)
  }, [containerRef.current, isOverflow])

  const exitHandler = () => {
    setStateDialog(undefined)
    if (onHide) {
      onHide()
    }
  }

  const DialogComponent = currentDialog?.component

  return (
    <Box
      sx={(muiTheme: Theme) => ({
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: muiTheme.zIndex.modal,
      })}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: '100%',
          pointerEvents: isOpen ? 'auto' : 'none',
        }}
      >
        <StyledMotionDiv
          onClick={onClose}
          custom={0.8}
          animate={isOpen ? 'show' : 'hide'}
          {...lightboxDimmerMotionProps}
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'common.black',
            cursor: 'pointer',
          }}
        />
        <Box
          ref={containerRef}
          sx={(muiTheme: Theme) => ({
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',

            width: '100%',
            height: '100%',
            overflow: 'auto',

            [muiTheme.breakpoints.up('md')]: {
              pt: 0,
              alignItems: 'center',
            },
          })}
        >
          <AnimatePresence exitBeforeEnter onExitComplete={exitHandler}>
            {DialogComponent && (
              <StyledMotionDiv
                {...lightboxDialogMotionProps}
                sx={(muiTheme: Theme) => ({
                  width: '100%',
                  zIndex: 1,
                  p: muiTheme.gutter('sm'),

                  [muiTheme.breakpoints.up('md')]: {
                    p: muiTheme.gutter('md'),
                  },
                  [muiTheme.breakpoints.up('lg')]: {
                    p: muiTheme.gutter('lg'),
                  },
                })}
                key={currentDialog.id}
              >
                <DialogComponent
                  {...(currentDialog.props as any)}
                  onClose={onClose}
                />
              </StyledMotionDiv>
            )}
          </AnimatePresence>
        </Box>
      </Box>
    </Box>
  )
}
