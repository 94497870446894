import {
  atom,
  action,
  map,
  mapTemplate,
  actionFor,
  keepMount,
  onSet,
} from 'nanostores'
import { globalLocale, globalLocation } from './global'
import * as api from '@utils/api'
import { messages } from '../translations'
import { distanceOptions, getLocationLabel } from '@utils/props'

export type tSearchLocation = {
  location?: MCDC.API.ILocation
  option?: MCDC.Props.IOption
  distance?: number
  isGeolocated?: boolean
}

export type tSearchFilters = {
  filters?: MCDC.API.ISearchFilter[]
}

export type tSubmitConfig = {
  query?: string
  pager?: MCDC.API.ISearchConfig['pager']
  pageUrl?: string
  profile?: MCDC.API.IProfile
  filters?: MCDC.API.ISearchFilter[]
  location?: MCDC.API.ILocation | null
  distance?: number
}

export type tSearchHistory = {
  result?: MCDC.API.ISearchResponse | MCDC.API.ISearcAggregatedhResponse
  recommendations?:
    | MCDC.API.ISearchResponse
    | MCDC.API.ISearcAggregatedhResponse
  profile?: MCDC.API.IProfile
  config?: MCDC.API.ISearchConfig
  hash?: string
  isInitialized?: boolean
  isPersistent?: boolean
}

export const searchPathTo = atom<string>('/')
export const searchProfile = map<MCDC.API.IProfile>({})

/**
 * Search History
 */
export const searchHistory = mapTemplate<tSearchHistory>((store, id) => {
  const storeValues = store.get()
  if (!storeValues.isInitialized) {
    store.setKey('isInitialized', true)
    onSet(store, ({ newValue, abort }) => {
      if (!newValue.isPersistent && (!!newValue.result || !!newValue.profile)) {
        store.setKey('isPersistent', true)
        keepMount(store)
      }
    })
  }
})

export const searchHistoryUpdate = actionFor(
  searchHistory,
  'update',
  async (store, params: tSearchHistory = {}) => {
    Object.entries(params).forEach(([key, value]) => {
      store.setKey(key as any, value)
    })
  }
)

/**
 * Search Query
 */

export const searchQuery = atom<string>('')
export const searchQueryOptions = atom<MCDC.Props.IOption[]>([])
export const searchQueryIsBusy = atom<boolean>(false)

export const setSearchQuery = action(
  searchQuery,
  'setSearchQuery',
  async (store, query) => {
    store.set(query)
    if (query.length) {
      searchQueryIsBusy.set(true)
      const history = searchHistory(globalLocation.get()?.pathname || '')
      const response = await api.searchSuggest({
        ...(history.get().config || {}),
        query,
      })
      searchQueryOptions.set(
        (response?.data.data || []).map((entry) => ({
          label: entry.title,
          value: entry.title,
        }))
      )
      searchQueryIsBusy.set(false)
    } else if (searchQueryOptions.get().length) {
      searchQueryOptions.set([])
    }

    return store.get()
  }
)

/**
 * Search Location
 */

export const searchLocation = map<tSearchLocation>({
  isGeolocated: false,
  distance: 0,
})

export const searchLocationQuery = atom<string>('')
export const searchLocationQueryIsBusy = atom<boolean>(false)
export const searchLocationGeolocatingState = atom<
  'locating' | 'unsupported' | 'success' | 'error' | undefined
>()
export const searchLocationGeolocatingError = atom<number | undefined>()
export const searchLocationOptions = atom<MCDC.API.ILocation[]>([])

export const setSearchLocationOption = action(
  searchLocation,
  'setSearchLocationOption',
  (store, option?: MCDC.API.ILocation) => {
    store.setKey('location', option)
    store.setKey(
      'option',
      option ? { label: getLocationLabel(option), value: option.id } : undefined
    )
    return store.get()
  }
)

export const setSearchLocationIsGeolocated = action(
  searchLocation,
  'setSearchLocationIsGeolocated',
  (store, value?: boolean) => {
    if (!value) {
      store.setKey('isGeolocated', false)
      return
    }

    if (!navigator?.geolocation) {
      searchLocationGeolocatingState.set('unsupported')
      store.setKey('isGeolocated', false)
      store.setKey('location', undefined)
    } else {
      const locale = globalLocale.get()
      searchLocationGeolocatingState.set('locating')
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          const geoLocation = {
            lat: latitude,
            lng: longitude,
          }

          const data: MCDC.API.ILocation = {
            ...(geoLocation || { lat: 0, lng: 0 }),
            id: -1,
            plz: '',
            city: messages[locale]['label.locationCurrent'] as string,
          }
          const option = {
            label: messages[locale]['label.locationCurrent'] as string,
            value: -1,
          }

          store.setKey('isGeolocated', true)
          store.setKey('location', data)
          store.setKey('option', option)
          searchLocationOptions.set([])
          const storeValues = store.get()
          if (!storeValues.distance)
            store.setKey('distance', distanceOptions[1].value as number)

          searchLocationGeolocatingState.set('success')
        },
        (e) => {
          searchLocationGeolocatingError.set(e.code)
          searchLocationGeolocatingState.set('error')
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        }
      )
    }
    return store.get()
  }
)

export const setSearchLocationDistance = action(
  searchLocation,
  'setSearchLocationDistance',
  (store, value?: number) => {
    store.setKey('distance', value)
    return store.get()
  }
)

export const setSearchLocationQuery = action(
  searchLocationQuery,
  'setSearchLocationQuery',
  async (store, value: string, findMatch?: boolean) => {
    store.set(value)

    if (!value.length) return []
    searchLocationQueryIsBusy.set(true)
    const valueSplit = value
      .split(/(\s-\s)|(,\s)+/)
      .filter((entry) => !!entry && entry !== ', ' && entry !== ' - ')
    const response = await api.searchLocation({
      query:
        valueSplit.length === 3 ? `${valueSplit[0]}, ${valueSplit[2]}` : value,
    })

    searchLocationQueryIsBusy.set(false)
    if (!response?.data) return store.get()
    if (findMatch) {
      const option = (response?.data || []).find(
        (entry) => getLocationLabel(entry) === value
      )

      if (option) {
        searchLocation.setKey('location', option)
        searchLocation.setKey(
          'option',
          option
            ? { label: getLocationLabel(option), value: option.id }
            : undefined
        )
      }
    }
    searchLocationOptions.set(response?.data || [])
    return store.get()
  }
)

export const setSearchLocationQueryFromParams = action(
  searchLocationQuery,
  'setSearchLocationQuery',
  async (store, value: string, findMatch?: boolean) => {
    store.set(value)
    if (!value.length) return []
    searchLocationQueryIsBusy.set(true)
    const response = await api.searchLocation({
      query: value,
    })

    searchLocationQueryIsBusy.set(false)
    if (!response?.data) return store.get()
    if (findMatch) {
      const valueSplit = value.split(/[ \-,]+/)
      const option = (response?.data || []).find(
        (entry) => getLocationLabel(entry) === value
      )
      if (option) {
        searchLocation.setKey('location', option)
        searchLocation.setKey(
          'option',
          option
            ? { label: getLocationLabel(option), value: option.id }
            : undefined
        )
      }
    }
    searchLocationOptions.set(response?.data || [])
    return store.get()
  }
)

/**
 * Search Filters
 */

export const searchFilters = atom<MCDC.API.ISearchFilter[]>([])
export const searchFiltersOptions = atom<MCDC.API.ISearchFilter[]>([])
