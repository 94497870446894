import { isBrowser } from '@utils/functional'
import {
  kConsentUpdateTypeCategory,
  kConsentActivateCategoryYoutube,
} from '@utils/constants'

export const openConsentPreferencesDialog = (): void => {
  if (!isBrowser) {
    return
  }

  if (typeof window.OneTrust !== 'undefined') {
    window.OneTrust.ToggleInfoDisplay()
  }
}

export const activateYoutubeConsentInOntrust = () => {
  if (window.OneTrust) {
    window.OneTrust.UpdateConsent(
      kConsentUpdateTypeCategory,
      kConsentActivateCategoryYoutube
    )
  }
}
