import FontSize from '@config/theme/definitions/fontSize'
import { Components, Theme } from '@mui/material'

export const LinkOverrides = (theme: Theme): Components['MuiLink'] => ({
  styleOverrides: {
    root: {
      color: 'currentcolor',
      letterSpacing: 0.3,
      transition: 'color 300ms ease',
      textDecorationColor: 'currentcolor',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      ':hover': {
        color: theme.palette.grey[800],
        textDecorationColor: theme.palette.grey[800],
      },
    },
    button: {
      fontFamily: theme.typography.fontFamily,
    },
  },
})
