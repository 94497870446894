import React from 'react'
import { Box } from '@mui/material'
import Chip from '@components/core/input/Chip'
import Copy from '@components/core/text/Copy'

type FilterOption = { id: string; title: string }
type FilterLevelOption = {
  id: string
  hasMultipleOptions: boolean
  filterOptions: FilterOption[]
}

export type FilterComponentProps = {
  filterLevelOptions: FilterLevelOption[]
  selectedLevels: FilterOption[]
  handleSelectedLevel: (selectedLevel: FilterLevelOption) => void
  totalAmbassadors: string
  isSelected: (id: string, option: FilterOption) => boolean
}

export default function FilterComponent({
  filterLevelOptions,
  handleSelectedLevel,
  totalAmbassadors,
  isSelected,
}: FilterComponentProps) {
  const handleOptionChange = (id: string, optionId: string) => {
    const selectedLevel = filterLevelOptions.find((option) => option.id === id)
    if (selectedLevel) {
      const selectedOption = selectedLevel.filterOptions.find(
        (option) => option.id === optionId
      )
      if (selectedOption) {
        handleSelectedLevel({
          id,
          hasMultipleOptions: selectedLevel.hasMultipleOptions,
          filterOptions: [selectedOption],
        })
      }
    }
  }

  return (
    <Box sx={{ mb: 4 }}>
      {filterLevelOptions.map((filterLevelOption, levelIndex) => (
        <Box
          key={levelIndex}
          display="flex"
          flexWrap="wrap"
          gap={2}
          mt={levelIndex !== 0 ? 6 : 0}
        >
          {filterLevelOption.filterOptions.map((option, index) => (
            <Chip
              size={levelIndex === 0 ? 'large' : 'medium'}
              isBold={false}
              color={
                isSelected(filterLevelOption.id, option) ? 'info' : 'default'
              }
              variant={
                isSelected(filterLevelOption.id, option) ? 'filled' : 'outlined'
              }
              key={index}
              label={option.title}
              onClick={() =>
                handleOptionChange(filterLevelOption.id, option.id)
              }
              isSelected={isSelected(filterLevelOption.id, option)}
            />
          ))}
        </Box>
      ))}
      <Copy sx={{ mt: 10 }}>{totalAmbassadors}</Copy>
    </Box>
  )
}
