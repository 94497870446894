import { FontSizeType, IconSizeType } from '@mui/material/styles/createTheme'

export const IconSize: IconSizeType = new Map([
  ['small', '16px'],
  ['medium', '24px'],
  ['large', '32px'],
  ['xlarge', '72px'],
])

const FontSize: FontSizeType = new Map([
  ['base', '16px'],

  ['small', '12px'],

  ['h1-sm', '56px'],
  ['h1-lg', '80px'],

  ['h2-sm', '48px'],
  ['h2-lg', '64px'],

  ['h3-sm', '32px'],
  ['h3-lg', '48px'],

  ['h4-sm', '24px'],
  ['h4-lg', '28px'],

  ['h5-sm', '20px'],
  ['h5-lg', '24px'],
])

export default FontSize
