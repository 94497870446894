import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import { YouTubeEvent } from 'react-youtube'

import { useFilter } from '../../site//hooks/filter/useFilter'
import Headline from '@components/core/text/Headline'
import Copy from '@components/core/text/Copy'
import FilterComponent from '@components/core/input/Filter'
import AmbassadorFaqItem from '@components/modules/AmbassadorFaqs/AmbassadorFaqItem'
import { Box } from '@mui/system'
import Section from '@components/core/container/Section'
import { SliderArrowsAmbassadorFaqs } from '@components/modules/AmbassadorFaqs/SliderArrowsAmbassadorFaqs'

export type AmbassadorFaqsProps = {
  headline?: string
  copy?: MCDC.Props.RichText
  filterLevelOptions?: FilterLevelOption[]
  ambassadorData?: {
    headline: string
    subline: string
    image: MCDC.Props.IAssetImage
    ambassadorFilter: {
      filterLevel: FilterLevelOption[]
    }
    faqVideo: MCDC.Props.IGlobalFaqVideo[]
  }[]
  sx?: MCDC.Props.IDefault['sx']
}

type FilterOption = { id: string; title: string }
type FilterLevelOption = {
  id: string
  hasMultipleOptions: boolean
  filterOptions: FilterOption[]
}

export default function AmbassadorFaqs({
  headline,
  copy,
  filterLevelOptions = [],
  ambassadorData,
}: AmbassadorFaqsProps): ReactElement {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [activeVideo, setActiveVideo] = useState<YouTubeEvent | null>(null)

  const setPlayerReference = (newActiveVideo: YouTubeEvent) => {
    if (newActiveVideo) {
      if (activeVideo) {
        if (activeVideo.g === newActiveVideo.g) {
          setActiveVideo(null)
        } else {
          activeVideo.pauseVideo()
          setActiveVideo(newActiveVideo)
        }
      } else {
        setActiveVideo(newActiveVideo)
      }
    }
  }

  const {
    selectedLevels,
    handleSelectedLevel,
    isSelected,
    filterChanged,
    resetFilterChanged,
  } = useFilter(filterLevelOptions || [])

  // Filter ambassadorData based on selections
  type SelectedLevelOption = {
    id: string
    hasMultipleOptions: boolean
    selectedOption: FilterOption[]
  }

  const optionExists = (id: string, options: FilterOption[]): boolean => {
    return options.some((option: FilterOption) => option.id === id)
  }

  const levelHasOption = (
    selected: SelectedLevelOption,
    filterLevel: FilterLevelOption[]
  ): boolean => {
    return selected.selectedOption.every(({ id }) => {
      const flatOptions = filterLevel.flatMap(
        (level: any) => level.filterOptions
      )
      return optionExists(id, flatOptions)
    })
  }

  const ambassadorMatches = (
    selectedLevels: SelectedLevelOption[],
    filter: { filterLevel: FilterLevelOption[] }
  ): boolean => {
    return selectedLevels.every((selected) =>
      levelHasOption(selected, filter.filterLevel)
    )
  }

  const filterAmbassadors = (
    selectedLevels: SelectedLevelOption[],
    ambassadorData: any[]
  ): any[] => {
    return ambassadorData.filter(({ ambassadorFilter }) =>
      ambassadorMatches(selectedLevels, ambassadorFilter)
    )
  }
  const filteredAmbassadorData = filterAmbassadors(
    selectedLevels,
    ambassadorData || []
  )

  const fullWidth = filteredAmbassadorData.length === 1
  const showSliderArrows = filteredAmbassadorData.length >= 3

  const totalAmbassadors =
    filteredAmbassadorData.length === 1
      ? `${filteredAmbassadorData.length} Ambassador`
      : `${filteredAmbassadorData.length} Ambassadors`

  // Slider logic
  const sliderRef = useRef<HTMLElement | null>(null)
  const scrollWidth = 264

  const scroll = (direction: 'left' | 'right') => {
    if (sliderRef.current) {
      const offsetWidth = isMobile
        ? direction === 'left'
          ? -scrollWidth
          : scrollWidth
        : direction === 'left'
        ? -scrollWidth * 2
        : scrollWidth * 2
      sliderRef.current.scrollBy({ left: offsetWidth, behavior: 'smooth' })
    }
  }
  useEffect(() => {
    if (sliderRef.current && !activeVideo) {
      sliderRef.current.scrollLeft = 0
    }
    if (filterChanged && sliderRef.current && activeVideo) {
      sliderRef.current.scrollLeft = 0
    }
  }, [filteredAmbassadorData])

  const filterOptions = selectedLevels.flatMap((selectedLevel) =>
    selectedLevel.selectedOption.map((option) => ({
      id: selectedLevel.id,
      title: option.title,
    }))
  )

  return (
    <Section
      maxWidth={false}
      sx={(theme: Theme) => ({
        maxWidth: '728px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
          '> *': {
            mb: 10,
            paddingX: theme.spacing(4),
          },
        },
      })}
    >
      <Headline variant="h2">{headline}</Headline>
      <Copy component="div" sx={{ mt: 4, mb: 6 }}>
        {copy}
      </Copy>
      <FilterComponent
        filterLevelOptions={filterLevelOptions}
        totalAmbassadors={totalAmbassadors}
        handleSelectedLevel={(selectedLevel) =>
          handleSelectedLevel(
            selectedLevel.id,
            selectedLevel.filterOptions[0].id
          )
        }
        selectedLevels={filterOptions}
        isSelected={(level, option) => isSelected(option)}
      />
      <Box sx={{ position: 'relative' }}>
        <Box
          ref={sliderRef}
          sx={{
            display: 'flex',
            gap: 4,
            overflowX: 'auto',
            scrollSnapType: 'x mandatory',
            scrollBehavior: 'smooth',
            overflow: 'hidden',
          }}
        >
          {filteredAmbassadorData &&
            filteredAmbassadorData.map((ambassador, id) => (
              <Box
                key={ambassador.id}
                sx={{
                  width: fullWidth ? '100%' : 264,
                  flex: '0 0 auto',
                  scrollSnapAlign: 'start',
                }}
              >
                <AmbassadorFaqItem
                  image={ambassador.image}
                  headline={ambassador.headline}
                  subline={ambassador.subline}
                  faqVideo={ambassador.faqVideo}
                  ambassadorFilter={ambassador.ambassadorFilter}
                  fullWidth={fullWidth}
                  setActiveVideo={setPlayerReference}
                />
              </Box>
            ))}
        </Box>
        {showSliderArrows && (
          <SliderArrowsAmbassadorFaqs
            theme="primary"
            onClickLeft={() => scroll('left')}
            onClickRight={() => scroll('right')}
            totalSlides={filteredAmbassadorData.length}
            filterChanged={filterChanged}
            resetFilterChanged={resetFilterChanged}
            isMobile={isMobile}
            sx={{
              display: 'inline-block',
              top: '-48px',
              height: 'auto',
              '> button': {
                ml: 4,
              },
            }}
          />
        )}
      </Box>
    </Section>
  )
}
