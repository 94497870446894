import React, { ReactElement, useRef } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Box, Theme } from '@mui/material'
import { HistoryLocation } from '@gatsbyjs/reach-router'

import { pageTransition, StyledMotionMain } from '@utils/motion'
import useContentfulNavigation from '@components/site/hooks/contentful/useContentfulNavigation'
import Header from '@components/site/container/Header/'
import Footer from '@components/site/container/Footer/'
import useGlobalState from '@components/site/hooks/useGlobalState'
import PortalProvider from '@components/site/providers/PortalProvider'

export type LayoutProps = MCDC.Props.IDefault & {
  location: HistoryLocation
  pageContext?: MCDC.Contentful.IPageContext
}

export default function Layout({
  children,
  location,
  pageContext,
}: LayoutProps): ReactElement {
  const globalState = useGlobalState()
  const footerPortalRef = useRef<HTMLDivElement>(null)
  const { homePage, mainPages, metaPages, accountPage } =
    useContentfulNavigation()
  return (
    <>
      <Header
        navItems={[homePage, ...mainPages, accountPage]}
        location={location}
      />
      <PortalProvider portalRef={footerPortalRef} location={location}>
        <AnimatePresence
          exitBeforeEnter
          onExitComplete={() => globalState.setLocation(location)}
        >
          <StyledMotionMain
            key={location.pathname}
            {...pageTransition}
            sx={{
              position: 'relative',
              minHeight: '80vh',
              overflow: 'hidden',
              pt: 16,
            }}
          >
            <Box
              sx={(muiTheme: Theme) => ({
                ...muiTheme.mixins.conatainerModuleSpacing('large'),
                pb: 10,
              })}
            >
              {children}
            </Box>
          </StyledMotionMain>
        </AnimatePresence>
      </PortalProvider>
      <Box
        sx={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 100,
          pointerEvents: 'none',
        }}
      >
        <div ref={footerPortalRef} />
      </Box>
      <Footer navItems={metaPages} />
    </>
  )
}
