import React, { ReactElement } from 'react'
import { Box, Container, Theme } from '@mui/material'

import Logo from '@components/core/media/Logo'
import NavigationItem from './components/NavigationItem'
import MenuToggle from '@components/core/input/MenuToggle'
import { Link as GatsbyLink } from '@gatsbyjs/reach-router'
import Icon from '@components/core/media/Icon'
import Link from '@components/core/input/Link'

export type NavigationProps = {
  itemHome: MCDC.Props.ILinkTo
  itemAccount: MCDC.Props.ILinkTo
  items: MCDC.Props.ILinkTo[]
  activeIndex: number
  isOpen: boolean
  onToggle: () => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function Navigation({
  items,
  itemHome,
  itemAccount,
  activeIndex,
  isOpen,
  onToggle,
  sx,
}: NavigationProps): ReactElement {
  return (
    <Container
      maxWidth="xl"
      sx={[
        (theme: Theme) => ({
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          height: '64px',
          backgroundColor: theme.palette.grey[100],
          zIndex: theme.zIndex.drawer + 1,
          px: `${theme.spacing(theme.gutter('sm'))} !important`,
        }),
        sx,
      ]}
    >
      <GatsbyLink to={itemHome.url}>
        <Logo sx={{ mr: 4 }} />
      </GatsbyLink>
      <Box
        component="ul"
        sx={(theme: Theme) => ({
          mx: 'auto',
          whiteSpace: 'nowrap',
          p: 0,
          '> *': { mr: 6 },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        })}
      >
        {items.map((entry, index) => (
          <NavigationItem
            to={entry.url}
            isExternal={entry.isExternal}
            count={entry.count}
            isSelected={activeIndex === index}
            key={index}
          >
            {entry.label}
          </NavigationItem>
        ))}
      </Box>
      <Box
        sx={(theme: Theme) => ({
          ml: 'auto',
          mr: -2,
          [theme.breakpoints.up('lg')]: {
            height: '100%',
            ml: 0,
            mr: -4,
          },
        })}
      >
        <MenuToggle
          onClick={onToggle}
          isToggled={isOpen}
          sx={(theme: Theme) => ({
            [theme.breakpoints.up('lg')]: {
              display: 'none',
            },
          })}
        />
        <Link
          to={itemAccount.url}
          underline="none"
          sx={(theme: Theme) => ({
            display: 'inline-flex',
            width: '53px',
            height: '100%',
            justifyContent: 'center',
            '&:hover': {
              color: 'primary.main',
            },
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
          })}
        >
          <Icon variant="favorite" sx={{ color: 'inherit' }} />
        </Link>
      </Box>
    </Container>
  )
}
