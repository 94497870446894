import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'

import { ISearchContext } from '@components/site/providers/SearchProvider'
import { searchFilters } from '@store/search'

type UseSearchFiltersReturn = {
  areaFilters: MCDC.Props.IOption[]
  typeFilters: MCDC.Props.IOption[]
  taskFilters?: MCDC.Props.IOption[]
  levelFilters?: MCDC.Props.IOption[]
  area?: MCDC.Props.IOption
  types?: MCDC.Props.IOption[]
  tasks?: MCDC.Props.IOption[]
  levels?: MCDC.Props.IOption[]
  isBusy: boolean
  setArea: (id?: string) => void
  setTypes: (ids: string[]) => void
  setTasks: (ids: string[]) => void
  setLevels: (ids: string[]) => void
  reset: (whitelist?: string[]) => void
}

export default function useSearchFilters(
  searchState?: ISearchContext,
  submitPath?: string,
  areaId?: MCDC.API.SearchFilterArea
): UseSearchFiltersReturn {
  const filters = useStore(searchFilters)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [areaFilters] = useState<MCDC.Props.IOption[]>(
    searchState?.meta.searchFilters
      ?.filter(
        (entry) =>
          entry.level === 1 &&
          (entry.id === 'RESTAURANT_POSITON' ||
            entry.id === 'ADMINISTRATION_POSITION')
      )
      .map((entry) => ({ label: entry.title, value: entry.id })) || []
  )

  const [typeFilters, setTypeFilters] = useState<MCDC.Props.IOption[]>(
    [
      ...(searchState?.meta.searchFilters?.filter(
        (entry) => entry.level === 3
      ) || []),
    ].map((entry) => ({ label: entry.title, value: entry.id })) || []
  )

  const [levelFilters, setLevelFilters] = useState<MCDC.Props.IOption[]>()
  const [taskFilters, setTaskFilters] = useState<MCDC.Props.IOption[]>()
  const [area, setAreaState] = useState<MCDC.Props.IOption>()
  const [types, setTypesState] = useState<MCDC.Props.IOption[]>()
  const [tasks, setTasksState] = useState<MCDC.Props.IOption[]>()
  const [levels, setLevelsState] = useState<MCDC.Props.IOption[]>()

  function getFilterList(
    options?: MCDC.Props.IOption[],
    areaId?: string
  ): MCDC.API.ISearchFilter[] {
    const filters = searchState?.meta.searchFilters || []
    const areaIdKey = areaId?.split('_')[0]
    return options && options.length
      ? filters
          .filter(
            (entry) => !!options.find((option) => entry.id === option.value)
          )
          .filter(
            (entry) =>
              entry.level < 2 ||
              (entry.id as string).startsWith('IS') ||
              (!areaIdKey && entry.level === 2) ||
              (!areaIdKey && entry.level === 3) ||
              (areaIdKey && (entry.id as string).startsWith(areaIdKey))
          )
      : []
  }

  function reset(whitelist: string[] = []) {
    searchState?.setFilters(
      searchState?.meta.searchFilters.filter((entry) =>
        whitelist.includes(entry.id)
      )
    )
  }

  function setArea(id?: string) {
    const option =
      id === area?.value
        ? undefined
        : areaFilters.find((entry) => entry.value === id)

    const options = [...(types || []), ...(tasks || []), ...(levels || [])]
    const filters = getFilterList(
      option ? [option, ...options] : options,
      option?.value as string
    )
    searchState?.setFilters(filters)
  }

  function setTypes(ids: string[]) {
    const options = typeFilters
      .filter((entry) => ids.includes(String(entry.value)))
      .concat(tasks || [])
      .concat(levels || [])
      .concat(area ? [area] : [])
    const filters = getFilterList(options, area?.value as string)
    searchState?.setFilters(filters)
  }

  function setTasks(ids: string[]) {
    const options = (taskFilters || [])
      .filter((entry) => ids.includes(String(entry.value)))
      .concat(types || [])
      .concat(levels || [])
      .concat(area ? [area] : [])
    const filters = getFilterList(options, area?.value as string)
    searchState?.setFilters(filters)
  }

  function setLevels(ids: string[]) {
    const options = (levelFilters || [])
      .filter((entry) => ids.includes(String(entry.value)))
      .concat(types || [])
      .concat(tasks || [])
      .concat(area ? [area] : [])
    const filters = getFilterList(options, area?.value as string)
    searchState?.setFilters(filters)
  }

  // validate options set
  useEffect(() => {
    if (!isMounted && !filters.length) return

    const area = areaFilters.find((entry: MCDC.Props.IOption) =>
      filters.find(
        (filter: MCDC.API.ISearchFilter) => filter.id === entry.value
      )
    )

    const areaIdKey = (area?.value as string)?.split('_')[0]

    const typeFiltersNew = typeFilters.map((entry: MCDC.Props.IOption) => ({
      ...entry,
      isDisabled:
        !!areaIdKey &&
        !(entry.value as string).startsWith('IS') &&
        !(entry.value as string).startsWith(areaIdKey),
    }))

    const types = typeFilters.filter((entry: MCDC.Props.IOption) =>
      filters.find(
        (filter: MCDC.API.ISearchFilter) => filter.id === entry.value
      )
    )

    const levelFiltersNew =
      area &&
      searchState?.meta.searchFilters
        .filter(
          (entry: MCDC.API.ISearchFilter) =>
            entry.level === 2 && entry.id.startsWith(areaIdKey)
        )
        .map((entry) => ({ label: entry.title, value: entry.id }))

    const levels =
      levelFiltersNew &&
      levelFiltersNew.filter((entry: MCDC.Props.IOption) =>
        filters.find(
          (filter: MCDC.API.ISearchFilter) => filter.id === entry.value
        )
      )

    const taskFiltersNew =
      areaIdKey &&
      areaIdKey.startsWith('ADMINISTRATION') &&
      (searchState?.meta.searchFilters
        .filter((entry) => entry.level === 4)
        .map((entry) => ({
          label: entry.title,
          value: entry.id,
        })) as MCDC.Props.IOption[])

    const tasks =
      taskFiltersNew &&
      taskFiltersNew.filter((entry: MCDC.Props.IOption) =>
        filters.find(
          (filter: MCDC.API.ISearchFilter) => filter.id === entry.value
        )
      )

    setTypeFilters(typeFiltersNew)
    setLevelFilters(levelFiltersNew || undefined)
    setTaskFilters(taskFiltersNew || undefined)
    setLevelsState(levels || undefined)
    setTasksState(tasks || undefined)
    setTypesState(types)
    setAreaState(area)
  }, [filters, isMounted])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) return
    if (areaId && area?.value !== areaId) {
      const option = areaFilters.find((entry) => entry.value === areaId)
      const options = [...(types || []), ...(tasks || [])]
      const filters = getFilterList(
        option ? [option, ...options] : options,
        areaId
      )
      searchState?.setFilters(filters)
      return
    }
    setIsMounted(true)
  }, [areaId, area])

  return {
    isBusy,
    area,
    types,
    tasks,
    levels,
    areaFilters,
    typeFilters,
    taskFilters,
    levelFilters,
    setArea,
    setTypes,
    setTasks,
    setLevels,
    reset,
  }
}
