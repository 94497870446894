import React, { ReactElement } from 'react'

import FooterModule, {
  FooterProps as FooterModuleProps,
} from '@components/modules/Footer'
import { openConsentPreferencesDialog } from '@utils/consenthandling'

export type FooterProps = MCDC.Props.IDefault & {
  navItems: MCDC.Props.ILinkTo[]
}

export default function Footer({ navItems }: FooterProps): ReactElement {
  const socialItems: FooterModuleProps['socialItems'] = [
    {
      url: 'https://www.youtube.com/c/mcdonaldsde',
      variant: 'Youtube',
    },
    {
      url: 'https://www.tiktok.com/@mcdonaldsde',
      variant: 'TikTok',
    },
    { url: 'https://www.facebook.com/mcd', variant: 'Facebook' },
    {
      url: 'https://www.instagram.com/mcdonaldsde/',
      variant: 'Instagram',
    },
    { url: 'https://www.kununu.com/de/mcdonalds', variant: 'Kununu' },
    {
      url: 'https://www.linkedin.com/company/mcdonaldsdeutschland/',
      variant: 'LinkedIn',
    },
  ]

  const technicalItems: MCDC.Props.IButtonTo[] = [
    {
      label: 'Privatsphäre Einstellungen',
      onClick: openConsentPreferencesDialog,
    },
  ]
  return (
    <FooterModule
      items={[...navItems, ...technicalItems]}
      socialItems={socialItems}
    />
  )
}
