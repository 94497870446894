import { Components, Theme } from '@mui/material'

export const PaperOverrides = (theme: Theme): Components['MuiPaper'] => ({
  defaultProps: {
    square: true,
    elevation: 0,
  },
  styleOverrides: {
    root: {
      '&.MuiPaper-sizeSmall': {
        padding: theme.spacing(4),
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(6),
        },
      },
      '&.MuiPaper-sizeMedium': {
        padding: theme.spacing(4),
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(8),
        },
      },
      '&.MuiPaper-sizeLarge': {
        padding: theme.spacing(6),
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(8),
        },
      },
      '&.MuiPaper-sizeXLarge': {
        padding: theme.spacing(8),
        [theme.breakpoints.up('md')]: {
          padding: `${theme.spacing(10)} ${theme.spacing(8)}`,
        },
        [theme.breakpoints.up('lg')]: {
          padding: `${theme.spacing(16)} ${theme.spacing(25)}`,
        },
      },
      '&.MuiPaper-themeLight': {
        backgroundColor: theme.palette.background.light,
      },
      '&.MuiPaper-themeMedium': {
        color: theme.palette.text.inverted,
        backgroundColor: theme.palette.background.medium,
      },
      '&.MuiPaper-themeDark': {
        color: theme.palette.text.inverted,
        backgroundColor: theme.palette.background.dark,
      },
    },
  },
})
