import { Components, Theme } from '@mui/material'

export const SvgIconOverrides = (theme: Theme): Components['MuiSvgIcon'] => ({
  styleOverrides: {
    // root: {
    //   verticalAlign: 'middle',
    //   fontSize: IconSize.get('small'),
    // },
    // fontSizeSmall: {
    //   fontSize: IconSize.get('medium'),
    // },
    // fontSizeLarge: {
    //   fontSize: IconSize.get('large'),
    // },
  },
})
