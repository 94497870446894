import Section from '@components/core/container/Section'
import Headline from '@components/core/text/Headline'
import Box from '@mui/material/Box/Box'
import { Theme } from '@mui/material'

import React from 'react'

interface BenefitsListProps {
  headline: string
  items: Array<{
    employer: string
    employee: string
  }>
}

export default function BenefitsList(props: BenefitsListProps) {
  const { headline, items } = props
  const prepareListItem = (label: string, key: number) => {
    return (
      <Box
        key={key}
        component="li"
        sx={(theme: Theme) => ({
          mt: 6,
          mr: 2,
          width: '100%',
          listStyleType: 'none',
          position: 'relative',
          paddingLeft: '1.5em',
          '&::before': {
            content:
              "url(\"data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.1923 0.89103C14.736 0.90555 14.3027 1.11374 13.9842 1.47134C11.2036 4.51845 8.66538 7.50665 5.99523 10.4883L4.4258 9.11226L2.85637 7.73625C2.49235 7.41771 2.02719 7.27051 1.56392 7.32679C1.10042 7.38308 0.676538 7.63818 0.385259 8.03646C0.0939637 8.43452 -0.0408565 8.94296 0.0108208 9.44979C0.0622983 9.95641 0.295782 10.4202 0.659793 10.7385L2.85615 12.6598L5.05252 14.5812C5.39141 14.8763 5.81795 15.0244 6.25016 14.9967C6.68261 14.969 7.09032 14.7675 7.3953 14.4311C10.5552 10.9683 13.3861 7.5672 16.4736 4.18356C16.8117 3.82079 17.0017 3.32378 17 2.80575C16.9986 2.28773 16.8057 1.79203 16.4654 1.43176C16.1249 1.07123 15.6659 0.876064 15.1923 0.89103Z' fill='%23FFBC0D'/%3E%3C/svg%3E\")",
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
          },
          [theme.breakpoints.up('md')]: {},
        })}
      >
        {label}
      </Box>
    )
  }

  return (
    <Section maxWidth="sm">
      <Headline variant="h2" sx={{ mt: 6 }}>
        {headline}
      </Headline>
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
        })}
      >
        <Box
          component="ul"
          sx={(theme: Theme) => ({
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: '48%',
            },
          })}
        >
          {items.map((item, index) => prepareListItem(item.employer, index))}
        </Box>
        <Box
          component="ul"
          sx={(theme: Theme) => ({
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: '48%',
            },
          })}
        >
          {items.map((item, index) => prepareListItem(item.employee, index))}
        </Box>
      </Box>
    </Section>
  )
}
