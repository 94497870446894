import React, { ReactElement } from 'react'
import { Box, Container } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Link from '@components/core/input/Link'
import SocialButton from '@components/core/input/SocialButton'
import { SocailIconProps } from '@components/core/media/SocialIcon'
import Copy from '@components/core/text/Copy'
import theme from '@config/theme'

export type FooterProps = {
  items: (MCDC.Props.ILinkTo | MCDC.Props.IButtonTo)[]
  socialItems: { url: string; variant: SocailIconProps['variant'] }[]
  sx?: MCDC.Props.IDefault['sx']
}

export default function Footer({
  items,
  socialItems,
  sx,
}: FooterProps): ReactElement {
  return (
    <Container
      component="footer"
      sx={[
        {
          textAlign: 'center',
          position: 'relative',
          py: 8,
          backgroundColor: 'background.default',
        },
        sx,
      ]}
    >
      <Box
        component="ul"
        sx={[
          {
            mx: 'auto',
            mb: 10,
            p: 0,
            '> *': {
              display: 'inline-block',
              ml: 6,
              ':first-of-type': {
                ml: 0,
              },
            },
            [theme.breakpoints.up('lg')]: {
              '> li': {
                ml: 4,
              },
            },
          },
          sx,
        ]}
      >
        {socialItems.map((item, index) => (
          <Box
            key={index}
            component="li"
            sx={{
              listStyleType: 'none',
            }}
          >
            <SocialButton to={item.url} variant={item.variant} />
          </Box>
        ))}
      </Box>
      <Box
        component="ul"
        sx={{
          mx: 'auto',
          mb: 10,
          p: 0,
          '> *': {
            display: 'inline-block',
            mx: 2,
          },
        }}
      >
        {items.map((entry, index) => (
          <Box
            key={index}
            component="li"
            sx={{
              listStyleType: 'none',
              fontWeight: 'bold',
            }}
          >
            <Link
              sx={entry.onClick ? { mb: 0.75 } : {}}
              to={entry.url}
              variant={'navigation'}
              underline={'hover'}
              isExternal={entry.isExternal}
              key={index}
              onClick={entry.onClick}
            >
              {entry.label}
            </Link>
          </Box>
        ))}
      </Box>
      <Copy variant="small">
        <FormattedMessage id={'copyrightText'} />
      </Copy>
    </Container>
  )
}
