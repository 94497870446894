import React, { createContext, createRef, ReactElement } from 'react'
import { HistoryLocation } from '@gatsbyjs/reach-router'

export interface IPortalContext {
  portalRef: React.RefObject<HTMLElement>
  location?: HistoryLocation
}

export const PortalContext = createContext<IPortalContext>({
  portalRef: createRef(),
})

export type PortalProviderProps = MCDC.Props.IDefault & {
  portalRef: React.RefObject<HTMLElement>
  location?: HistoryLocation
}

export default function PortalProvider({
  children,
  portalRef,
  location,
}: PortalProviderProps): ReactElement {
  return (
    <PortalContext.Provider value={{ portalRef, location }}>
      {children}
    </PortalContext.Provider>
  )
}
