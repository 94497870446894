import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import Headline from '@components/core/text/Headline'
import Copy from '@components/core/text/Copy'

export type JobFactCardProps = {
  title: string
  description: string
  icon: MCDC.Props.IAssetSvg
  sx?: MCDC.Props.IDefault['sx']
}

export default function JobFactCard({
  title,
  description,
  icon,
  sx,
}: JobFactCardProps): ReactElement {
  return (
    <Box
      sx={[
        sx,
        {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '622px',
          textAlign: 'center',
        },
      ]}
    >
      <Box
        sx={{
          width: '208px',
          height: '208px',
          backgroundColor: 'common.white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 6,
        }}
      >
        <Box
          sx={{
            width: '88px',
            lineHeight: 1,
          }}
          dangerouslySetInnerHTML={{ __html: icon?.content }}
        />
      </Box>
      <Headline variant="h3" sx={{ mb: 6 }}>
        {title}
      </Headline>
      <Copy>
        <strong>{description}</strong>
      </Copy>
    </Box>
  )
}
