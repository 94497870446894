import React, { ForwardedRef, forwardRef, ReactElement } from 'react'
import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
} from '@mui/material'
import clsx from 'clsx'

import Icon, { IconProps } from '@components/core/media/Icon'
import Chip from '@components/core/input/Chip'

export type SelectInputProps = {
  id: string
  label?: string
  placeholder?: string
  variant?: 'underlined' | 'outlined'
  icon?: IconProps['variant']
  value?: MCDC.Props.IOption | MCDC.Props.IOption[] | null
  options: MCDC.Props.IOption[]
  initialValue?: string
  defaultValue?: string
  isRequired?: boolean
  isSuccess?: boolean
  isError?: boolean
  isDisabled?: boolean
  isMultiple?: boolean
  isMultipleCount?: boolean
  withoutBorder?: boolean
  onChange: (e: SelectChangeEvent<string>, child: React.ReactNode) => void
  onDeselect?: (e: any) => void
  sx?: MCDC.Props.IDefault['sx']
}

const SelectInput = forwardRef(function SelectInput(
  {
    label,
    placeholder,
    id,
    icon,
    value,
    variant,
    options = [],
    initialValue = '',
    isError,
    isSuccess,
    isRequired,
    isDisabled,
    isMultiple,
    isMultipleCount,
    withoutBorder,
    onChange,
    onDeselect,
    sx,
  }: SelectInputProps,
  ref: ForwardedRef<HTMLDivElement> | undefined
): ReactElement {
  const IconComponent = (props: any) => (
    <Icon
      {...props}
      variant="expand_more"
      size="medium"
      sx={{
        position: 'absolute',
        right: variant !== 'underlined' ? 10 : 0,
        pointerEvents: 'none',
      }}
    />
  )

  function rendeStartAdornment() {
    if (!icon) return null
    const styleSX = { width: '24px', fontWeight: 700 }
    return (
      <InputAdornment position="start" sx={styleSX}>
        <Icon variant={icon} size="medium" />
      </InputAdornment>
    )
  }

  function renderValue(inputValue: any) {
    if (!isMultipleCount && !isMultiple) {
      const currentValue = (value as any)?.value

      return !!currentValue
        ? options.find((entry) => entry.value === currentValue)?.label ||
            placeholder
        : !label
        ? placeholder
        : undefined
    }

    const currentValue = Array.isArray(value) ? value : value?.value

    const count = !isMultiple
      ? !!currentValue && currentValue !== placeholder
        ? 1
        : 0
      : (currentValue as any)?.length || 0

    return (
      <>
        {placeholder}
        {isMultipleCount && (
          <Chip
            size="small"
            label={count}
            color="primary"
            sx={{
              ml: 1,
              mr: 2,
              visibility: !count ? 'hidden' : undefined,
            }}
          />
        )}
      </>
    )
  }

  const labelId = label && `${id}-label`
  const selectedOptionValues = Array.isArray(value)
    ? (value as any)?.map((entry: any) => entry.value)
    : !!value
    ? [value.value]
    : []

  return (
    <FormControl
      variant={variant === 'underlined' ? 'standard' : 'outlined'}
      sx={[
        sx,
        variant === 'underlined' && {
          width: 'auto',
          '.MuiSelect-select': {
            overflow: 'visible',
          },
        },
      ]}
    >
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select
        id={id}
        labelId={labelId}
        className={clsx({ 'MuiInputBase-noBorder': withoutBorder })}
        ref={ref}
        multiple={isMultiple}
        value={isMultiple ? ((selectedOptionValues || []) as any) : value || ''}
        defaultValue={initialValue}
        onChange={onChange}
        color={isSuccess ? 'success' : undefined}
        error={isError}
        required={isRequired}
        disabled={isDisabled}
        fullWidth={!isMultiple}
        startAdornment={rendeStartAdornment()}
        IconComponent={IconComponent}
        renderValue={renderValue}
        onClose={
          onDeselect
            ? (e) => {
                if (
                  (e.target as any)?.dataset.value ===
                  String((value as any)?.value)
                ) {
                  onDeselect(e)
                }
              }
            : undefined
        }
        displayEmpty
        sx={(theme: Theme) => ({
          color:
            !isMultipleCount && !value ? theme.palette.grey[400] : undefined,
        })}
        MenuProps={{
          PaperProps: {
            elevation: 4,
            sx: {
              backgroundColor: 'common.white',
              borderRadius: '8px',
              mt: 2,
              py: 0,
            },
          },
          sx: { '.MuiList-padding': { py: 0 } },
        }}
      >
        {options.map((entry) => (
          <MenuItem
            key={entry.value}
            value={entry.value}
            className={clsx({
              'Mui-selected': selectedOptionValues?.indexOf(entry.value) > -1,
            })}
            disabled={entry.isDisabled}
          >
            {isMultiple && (
              <Checkbox
                checked={selectedOptionValues?.indexOf(entry.value) > -1}
                sx={{ pl: 0 }}
                disabled={entry.isDisabled}
              />
            )}
            {entry.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

export default SelectInput
