import React, {
  useState,
  useCallback,
  createContext,
  ReactElement,
  useEffect,
} from 'react'
import Snackbar from '@mui/material/Snackbar'
import { Alert, AlertColor, Slide } from '@mui/material'
import theme from '@config/theme'
import Icon, { IconProps } from '@components/core/media/Icon'

type SnackbarOptions = {
  type?: AlertColor
  duration?: number
  icon?: IconProps['variant']
}

type SnackbarConfig = {
  message: string
  options?: SnackbarOptions
}

export interface ISnackbarContext {
  isOpen: boolean
  toggleSnackbar: (message: string, options?: SnackbarOptions) => void
}

export const SnackbarContext = createContext<ISnackbarContext>({
  isOpen: false,
  toggleSnackbar: (message: string) => {
    console.log('No Provider | Snackbar message: ', message)
  },
})

export type SnackbarProviderProps = MCDC.Props.IDefault

export default function SnackbarProvider({
  children,
}: SnackbarProviderProps): ReactElement {
  const [isOpen, setOpen] = useState(false)
  const [queued, setQueued] = useState<SnackbarConfig[]>([])
  const [current, setCurrent] = useState<SnackbarConfig>()

  const toggleSnackbar = useCallback(
    (message: string, options?: SnackbarOptions) => {
      setQueued((prev) => [...prev, { message, options }])
    },
    []
  )

  function handleClose() {
    setOpen(false)
    setTimeout(() => {
      setQueued(queued.slice(1))
      setCurrent(undefined)
    }, 750)
  }

  useEffect(() => {
    if (current || !queued.length) return
    const queuedTmp = [...queued]
    const next = queuedTmp[0]
    if (!next) return
    setCurrent(next)
    // setQueued(queuedTmp)
    setOpen(true)
  }, [queued, current])

  return (
    <SnackbarContext.Provider
      value={{ isOpen: isOpen, toggleSnackbar: toggleSnackbar }}
    >
      {children}
      <Snackbar
        open={isOpen}
        onClose={handleClose}
        autoHideDuration={current?.options?.duration || 6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={Slide}
        TransitionProps={{
          timeout: 750,
          easing: theme.transitions.easing.easeInOut,
        }}
        // onAnimationEnd={() => {
        //   console.log('on end')
        // }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={current?.options?.type}
          icon={
            current?.options?.icon ? (
              <Icon variant={current?.options?.icon} />
            ) : undefined
          }
          onClose={handleClose}
        >
          {current?.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
