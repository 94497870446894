import Icon from '@components/core/media/Icon'
import { Box, Container, IconButton } from '@mui/material'
import React, { ReactElement } from 'react'

export type PopupProps = {
  onClose?: () => void
  children?: MCDC.Props.IDefault['children']
  sx?: MCDC.Props.IDefault['sx']
}

export default function Popup({
  onClose,
  children,
  sx,
}: PopupProps): ReactElement {
  return (
    <Container
      maxWidth="xs"
      sx={[
        sx,
        {
          position: 'relative',
          pt: 14,
          pb: 6,
          px: 4,
          backgroundColor: 'common.white',
          borderRadius: '9px',
        },
      ]}
    >
      {onClose && (
        <Box
          sx={{
            position: 'absolute',
            right: 12,
            top: 16,
            zIndex: 2,
          }}
        >
          <IconButton onClick={onClose} color="inherit">
            <Icon variant="close" />
          </IconButton>
        </Box>
      )}
      {children}
    </Container>
  )
}
