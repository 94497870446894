import { fontFamily } from '@config/theme/definitions/fontFamily'
import { keyframes } from '@emotion/react'
import { Components, Theme } from '@mui/material'

import FontSize, { IconSize } from '../../definitions/fontSize'

const enterKeyframe = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`

export const ButtonOverrides = (theme: Theme): Components['MuiButton'] => ({
  defaultProps: {
    disableElevation: true,
    TouchRippleProps: {
      classes: {
        root: 'MuiButton-touchRipple',
        child: 'MuiButton-touchRippleChild',
        rippleVisible: 'MuiButton-touchRippleVisible',
        ripplePulsate: 'MuiButton-touchRippleChild',
        childPulsate: 'MuiButton-touchRippleChild',
      },
    },
  },
  styleOverrides: {
    root: {
      fontFamily: fontFamily,
      fontSize: FontSize.get('base'),
      lineHeight: FontSize.get('base'),
      fontWeight: 700,
      textTransform: 'none',
      borderRadius: 0,
      '&.Mui-loading': {
        pointerEvents: 'none',
        // cursor: 'not-allowed',
      },
      '& .MuiButton-label': {
        zIndex: 1,
        transition: 'opacity 300ms ease',
      },
    },
    text: {
      padding: 0,
      color: theme.palette.grey[900],
      '&.Mui-disabled': {
        color: theme.palette.grey[300],
      },

      '&:hover': {
        backgroundColor: 'transparent',
        '.MuiButton-endIcon': {
          transform: 'translateX(10%)',
        },
      },
    },
    textPrimary: {
      color: theme.palette.grey[900],
      '.MuiButton-endIcon': {
        color: theme.palette.primary.main,
      },
    },
    textSecondary: {
      color: theme.palette.common.white,
      endIcon: {
        color: theme.palette.common.white,
      },
      '&:hover': {
        color: theme.palette.grey[100],
      },
    },
    contained: {
      minWidth: '56px',
      minHeight: '56px',
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      '&.MuiButton-rounded': {
        borderRadius: 8,
      },
      '&.Mui-disabled': {
        color: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[200],
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[800],
      },

      '& .MuiButton-label': {
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6),
      },
      '& .MuiButton-startIcon + .MuiButton-label': {
        marginLeft: theme.spacing(2),
      },
      '.MuiButton-touchRippleVisible': {
        opacity: '1 !important',
        animation: `${enterKeyframe} 600ms forwards ease !important`,
      },
      '.MuiButton-touchRippleChild': {
        color: theme.palette.grey[900],
      },
    },
    containedPrimary: {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      '.MuiButton-touchRippleChild': {
        color: theme.palette.primary.dark,
      },
    },
    containedSecondary: {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      '.MuiButton-touchRippleChild': {
        color: theme.palette.common.white,
      },
    },
    startIcon: {
      margin: 0,
      marginLeft: theme.spacing(5),
      fontWeight: 100,
      zIndex: 1,
      '&:nth-of-type(1):nth-last-of-type(2)': {
        margin: 0,
      },
      '> *:nth-of-type(1)': {
        fontSize: IconSize.get('medium'),
      },
    },
    endIcon: {
      marginLeft: theme.spacing(3),
      transform: 'translateX(0%)',
      transition: 'transform 600ms ease',
      zIndex: 1,
      fontWeight: 100,
    },
  },
})
