import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/de'

import { WrapPageElementNodeArgs } from 'gatsby'
import React, { ReactElement } from 'react'
import * as ReactIntl from 'react-intl'
import Hls from 'hls.js'

import Layout from '@components/site/container/Layout'
import { defaultLang, messages } from '../../../../translations'
import { HistoryLocation } from '@gatsbyjs/reach-router'
import Providers from '@components/site/providers'
import { isBrowser } from '@utils/functional'

type PageProps = WrapPageElementNodeArgs & {
  props: {
    pageContext: MCDC.Contentful.IPageContext
    location: HistoryLocation
  }
}

export default function Page({ element, props }: PageProps): ReactElement {
  if (isBrowser && !window.Hls) window.Hls = Hls

  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <Providers
        location={props.location}
        pageContext={props.pageContext}
        locale={props.pageContext.locale || defaultLang}
      >
        <Layout {...props}>{element}</Layout>
      </Providers>
    </ReactIntl.IntlProvider>
  )
}
