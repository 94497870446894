import React, { ReactNode, ReactElement, useState, useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { useIntl } from 'react-intl'

import * as api from '@utils/api'
import useStaticContentful from '../hooks/contentful/useStaticContentful'
import {
  searchFilters,
  searchHistory,
  searchHistoryUpdate,
  searchLocation,
  searchLocationGeolocatingError,
  searchLocationGeolocatingState,
  searchPathTo,
  searchProfile,
  searchQuery,
  setSearchLocationDistance,
  setSearchLocationIsGeolocated,
  setSearchLocationOption,
  setSearchLocationQuery,
  setSearchQuery,
  tSearchHistory,
} from '@store/search'
import { globalLocation } from '@store/global'
import useSnackbar from '../hooks/useSnackbar'
import useQueryParams from '../hooks/useQueryParams'
import { getLocationLabel } from '@utils/props'
import { HistoryLocation } from '@gatsbyjs/reach-router'

type SubmitConfig = {
  query?: string
  pager?: MCDC.API.ISearchConfig['pager']
  pageUrl?: string
  profile?: MCDC.API.IProfile
  filters?: MCDC.API.ISearchFilter[]
  location?: MCDC.API.ILocation | null
  distance?: number
}

export interface ISearchContext {
  isBusy: boolean
  linkTo?: MCDC.Props.ILinkTo
  history: tSearchHistory
  meta: MCDC.API.IMeta
  setProfile: (value?: MCDC.API.IProfile, pagePath?: string) => void
  setQuery: (
    value: string,
    profile?: MCDC.API.IProfile,
    pagePath?: string,
    withSubmit?: boolean
  ) => void
  setLocation: (value?: MCDC.API.ILocation) => void
  getDistance: () => number | undefined
  setDistance: (value: number) => void
  setFilters: (value: MCDC.API.ISearchFilter[]) => void
  getSearchConfig: (config?: SubmitConfig) => MCDC.API.ISearchConfig
  submit: (config?: SubmitConfig) => void
  changePage: (value: number) => void
  loadMore: () => void
}

export const SearchContext = React.createContext<ISearchContext>({
  isBusy: false,
  history: {},
  meta: { searchFilters: [], totalJobcount: 0 },
  linkTo: { label: '', url: '' },
  setProfile: (value) => console.log(value),
  setQuery: (value) => console.log(value),
  setLocation: (value) => console.log(value),
  getDistance: () => undefined,
  setDistance: (value) => console.log(value),
  setFilters: (value) => console.log(value),
  getSearchConfig: () => ({}),
  submit: (value) => console.log(value),
  changePage: (value) => console.log(value),
  loadMore: () => console.log(''),
})

export type SearchProviderProps = {
  children: ReactNode
  location?: HistoryLocation
  linkToSearch?: MCDC.Props.ILinkTo
  meta?: MCDC.API.IMeta
}

export default function SearchProvider({
  children,
  location: pageLocation,
  linkToSearch = { label: '', url: '#' },
  meta = { searchFilters: [], totalJobcount: 0 },
}: SearchProviderProps): ReactElement {
  const intl = useIntl()
  const { toggleSnackbar } = useSnackbar()
  const {
    params,
    hasSearchParams,
    setQuery: setParamsQuery,
    setProfile: setParamsProfile,
    setFilters: setParamsFilters,
    setGeolocating: setParamsGeolocating,
    setLocation: setParamsLocation,
    setDistance: setParamsDistance,
  } = useQueryParams()

  const location = useStore(globalLocation)
  const history = useStore(searchHistory(location?.pathname || ''))
  const filtersSet = useStore(searchFilters)
  const profile = useStore(searchProfile)
  const geolocatingState = useStore(searchLocationGeolocatingState)
  const geolocatingError = useStore(searchLocationGeolocatingError)
  const locationConfig = useStore(searchLocation)
  const {
    pagesJobOffers: searchPages,
    getPageJobDetail,
    getPageJobProfile,
  } = useStaticContentful()
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [isFirstPaint, setIsFirstPaint] = useState<boolean>(true)

  function setQuery(
    value: string,
    profile?: MCDC.API.IProfile,
    pagePath?: string,
    withSubmit?: boolean
  ) {
    const currentPath = pagePath || location?.pathname || ''
    setSearchQuery(value)
    setParamsQuery(value)
    if (profile) {
      setProfile(profile, currentPath)
      setParamsProfile(profile)
    } else if (getProfile(pagePath)) {
      setProfile(undefined, currentPath)
      setParamsProfile()
    }

    if (withSubmit) {
      submit()
    }
  }

  function setLocation(value?: MCDC.API.ILocation) {
    if (locationConfig.isGeolocated) {
      setSearchLocationIsGeolocated(false)
      setParamsGeolocating(false)
    }
    setSearchLocationOption(value)
    setSearchLocationIsGeolocated(false)
    setParamsLocation(value ? getLocationLabel(value) : undefined)
  }

  function getDistance() {
    return !!searchLocation.get().distance
      ? (searchLocation.get().distance || 0) * 1000
      : undefined
  }

  function setDistance(value?: number) {
    setSearchLocationDistance(value)
    setParamsDistance(value)
  }

  function setProfile(value?: MCDC.API.IProfile, pagePath?: string) {
    const customHistory = searchHistory(pagePath || location?.pathname || '')
    customHistory.setKey('profile', value)
    if (!pagePath || pagePath === location?.pathname) {
      searchProfile.set(value || {})
    }
  }

  function getProfile(pagePath?: string) {
    const customHistory = searchHistory(pagePath || location?.pathname || '')
    return customHistory.get().profile
  }

  function setFilters(value: MCDC.API.ISearchFilter[]) {
    searchFilters.set(value)
    setParamsFilters(value.map((entry) => entry.id))
  }

  function changePage(value: number) {
    submit({
      pager: {
        offset: value,
      },
    })
  }

  function loadMore() {
    if (!history.result) return
    submit({
      pager: {
        size: (history.result?.meta.pagination.size || 0) + 5,
      },
    })
  }

  /**
   * Submit Search
   * @param config - overrides
   * @returns
   */
  async function submit(config?: SubmitConfig) {
    // if (isBusy) return

    const { pageUrl, profile: configProfile } = config || {}
    const currentPath = pageUrl || location?.pathname || ''
    const customHistory = searchHistory(currentPath)
    const isLocationSet =
      config?.location === null
        ? false
        : config?.location || !!searchLocation.get().location
    const isAggreggatedSearch =
      (currentPath === linkToSearch?.url ||
        !currentPath?.startsWith(linkToSearch?.url || ' ')) &&
      !isLocationSet

    if (configProfile) {
      setProfile(configProfile, currentPath)
    }
    const requestConfig: MCDC.API.ISearchConfig = getSearchConfig(config)
    if (JSON.stringify(requestConfig) === customHistory.get().hash) return

    setIsBusy(true)

    const response = isAggreggatedSearch
      ? await api.searchAggregated(requestConfig)
      : await api.search(requestConfig)

    setIsBusy(false)

    if (!isAggreggatedSearch && configProfile) {
      setProfile(configProfile)
    }

    if (response?.status !== 200) {
      toggleSnackbar(intl.messages['error.5XX.copy'] as string, {
        type: 'error',
      })
      return
    }

    const recommendationsResponse = await api.recommendations(requestConfig)
    searchHistoryUpdate(customHistory, {
      hash: JSON.stringify(requestConfig),
      config: requestConfig,
      result: normalizeResponseData(
        response?.data,
        currentPath,
        isAggreggatedSearch
      ),
      recommendations: normalizeResponseData(
        recommendationsResponse?.data,
        currentPath,
        isAggreggatedSearch
      ),
    })

    customHistory.notify()
  }

  /**
   * Get Search Submit Configuration
   * @param config - overrides
   * @returns
   */
  function getSearchConfig(config?: SubmitConfig): MCDC.API.ISearchConfig {
    const {
      pager,
      pageUrl,
      profile: configProfile,
      filters: configFilters,
    } = config || {}
    const currentPath = pageUrl || location?.pathname || ''
    const customHistory = searchHistory(currentPath).get()
    const locationConfig = searchLocation.get()
    const locationOption =
      config?.location !== undefined
        ? config?.location
        : locationConfig.location
    const isAggreggated = currentPath === linkToSearch?.url && !locationOption

    const profile = configProfile || customHistory.profile

    return {
      query: config?.query !== undefined ? config?.query : searchQuery.get(),
      pager: {
        size: 10,
        ...(pager || {}),
      },
      filters: configFilters || searchFilters.get(),
      profile: profile
        ? {
            templateIds: profile.id ? [profile.id] : [],
            templateTitle: profile.title ? [profile.title] : undefined,
          }
        : undefined,
      area:
        !isAggreggated && locationOption
          ? {
              distance: locationConfig.distance
                ? locationConfig.distance * 1000
                : 0,
              location: locationOption && {
                lat: locationOption?.lat,
                lon: locationOption?.lng,
              },
            }
          : undefined,
      aggregateadministrativjobs: !currentPath.includes(
        linkToSearch?.url || ' '
      ),
    }
  }

  function isSearchPage(
    isStrict?: boolean,
    locationOverride?: HistoryLocation
  ) {
    const testLocation = locationOverride || location
    return (
      !!testLocation &&
      searchPages.some((entry) =>
        isStrict
          ? testLocation?.pathname === entry.fields?.linkTo.url
          : testLocation?.pathname.includes(entry.fields?.linkTo.url)
      )
    )
  }

  /**
   * Normalize Submit Response Data
   * @param response
   * @param path
   * @param isAggregated
   * @returns
   */
  function normalizeResponseData(
    response?: MCDC.API.ISearchResponse | MCDC.API.ISearcAggregatedhResponse,
    path?: string,
    isAggregated?: boolean
  ) {
    if (!response) return

    if (!isAggregated) {
      const data = response.data as MCDC.API.IJobInfo[]
      return {
        ...response,
        data: data.map((entry) => ({
          ...entry,
          page: getPageJobDetail(entry.refId),
        })),
      } as MCDC.API.ISearchResponse
    }

    const data = response.data as MCDC.API.IJobGroupInfo[]

    return {
      ...response,
      data: data.map((entry) => ({
        ...entry,
        path,
        page: getPageJobProfile(entry.templateId),
        requisition:
          entry.jobCount === 1 &&
          entry.requisition &&
          location?.pathname === linkToSearch?.url
            ? {
                ...entry.requisition,
                page: getPageJobDetail(entry.requisition.refId),
              }
            : undefined,
      })),
    } as MCDC.API.ISearcAggregatedhResponse
  }

  /**
   * Handle Geolocation State Messages
   */
  useEffect(() => {
    if (!isMounted) return

    switch (geolocatingState) {
      case 'unsupported':
        toggleSnackbar(intl.messages['geolocation.unsupported'] as string, {
          type: 'error',
        })
        break
      case 'success':
        toggleSnackbar(intl.messages['geolocation.success'] as string, {
          type: 'success',
        })
        setParamsGeolocating(true)
        break
      case 'error':
        toggleSnackbar(
          intl.messages[`geolocation.error${geolocatingError}`] as string,
          {
            type: 'warning',
          }
        )
        break
    }
  }, [geolocatingState, geolocatingError])

  /**
   * Handle Initial Mount - Query Params
   */

  useEffect(() => {
    if (!isMounted) return
    const currentPath = location?.pathname || ''
    const isOffersPage = currentPath.includes(linkToSearch.url)
    const offerPathSegments = isOffersPage
      ? currentPath
          .replace(linkToSearch.url || '', '')
          .split('/')
          .filter((entry) => !!entry) || []
      : []
    const profileId = isOffersPage && offerPathSegments[0]

    /**
     * Handle Query Params on Mount
     */
    if (hasSearchParams()) {
      if (params.query) {
        // searchQuery.set(params.query)
        setQuery(
          params.query,
          params.profile ? { title: params.profile } : undefined
        )
      }

      if (params.geolocating) {
        setSearchLocationIsGeolocated(true)
      }

      if (params.distance) {
        setSearchLocationDistance(params.distance)
      }

      if (params.location) {
        setSearchLocationQuery(params.location, true)
      }

      if (params.filters) {
        const filters = meta.searchFilters.filter((entry) =>
          params.filters?.includes(entry.id)
        )
        setFilters(filters)
      }

      submit()
      return
    }

    /**
     * If Profile Search all Page Initial Submit
     */
    if (
      profileId &&
      currentPath &&
      offerPathSegments.length === 2 &&
      offerPathSegments[1] === 'all'
    ) {
      setProfile(
        {
          id: profileId,
        },
        currentPath
      )

      return
    }

    /**
     * Search Page
     */
    if (linkToSearch && location?.pathname === linkToSearch.url) {
      submit()
      return
    }
  }, [isMounted])

  useEffect(() => {
    if (isFirstPaint) return
    if (location?.pathname !== pageLocation?.pathname) {
      const shouldClearQuery =
        !!searchQuery.get() &&
        isSearchPage(true, pageLocation) &&
        searchPages.some(
          (entry) =>
            entry.metaType !== 'offers' &&
            pageLocation?.pathname === entry.fields?.linkTo.url
        )
      if (shouldClearQuery) {
        setQuery('')
      }
      return
    }

    const searchPageHistory = searchHistory(linkToSearch.url)
    const searchPageHistorValues = searchPageHistory.get()
    const currentPath = location?.pathname || ''
    const isOffersPage = currentPath.includes(linkToSearch.url)
    const offerPathSegments = isOffersPage
      ? currentPath
          .replace(linkToSearch.url || '', '')
          .split('/')
          .filter((entry) => !!entry) || []
      : []
    const profileId = isOffersPage && offerPathSegments[0]

    if (profileId)
      if (
        profileId &&
        currentPath &&
        offerPathSegments.length === 2 &&
        offerPathSegments[1] === 'all'
      ) {
        setProfile(
          {
            id: profileId,
          },
          currentPath
        )

        return
      }

    /**
     * Search Page
     */
    if (isSearchPage()) {
      setParamsQuery(searchQuery.get())
      setParamsFilters(searchFilters.get().map((entry) => entry.id))
      setParamsLocation(locationConfig.option?.label)
      setParamsGeolocating(locationConfig.isGeolocated)
      setParamsProfile(searchHistory(currentPath).get().profile)
      setParamsDistance(locationConfig.distance)
      submit()
    }

    /**
     * Reset Search on Start Page
     */
    if (currentPath === '/' && searchPageHistorValues.result) {
      setQuery('')
      setLocation()
      setProfile(undefined, linkToSearch.url)
      searchFilters.set([])
      searchHistoryUpdate(searchPageHistory, {})
      // setIsUpdating(true)
      return
    }
  }, [pageLocation?.pathname, location?.pathname])

  useEffect(() => {
    if (isFirstPaint) return
    if (hasSearchParams()) {
      if (params.query) {
        // searchQuery.set(params.query)
        setQuery(
          params.query,
          params.profile ? { title: params.profile } : undefined
        )
      }

      if (params.filters) {
        const filters = meta.searchFilters.filter((entry) =>
          params.filters?.includes(entry.id)
        )
        setFilters(filters)
      }
    }
  }, [params])

  useEffect(() => {
    const isBusy = geolocatingState === 'locating'
    if (!isMounted || isBusy) return
    submit()
  }, [profile, locationConfig, filtersSet, geolocatingState])

  useEffect(() => {
    searchPathTo.set(linkToSearch.url)
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (!isMounted) return
    setIsFirstPaint(false)
  }, [isMounted])

  return (
    <SearchContext.Provider
      value={{
        isBusy,
        history,
        meta,
        linkTo: linkToSearch,
        setQuery,
        setLocation,
        setFilters,
        setProfile,
        getDistance,
        setDistance,
        getSearchConfig,
        changePage,
        loadMore,
        submit,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
