import React, { useCallback } from 'react'

export default function useAccordionControls(
  activeId: number | string | boolean
): [string | number | boolean, (id: number, isExpanded: boolean) => void] {
  const [activeAccordionId, setActiveAccordionId] = React.useState<
    boolean | number | string
  >(activeId)

  const handleAccordionChange = useCallback(
    (id: number | string, isExpanded: boolean) => {
      setActiveAccordionId(isExpanded ? id : false)
    },
    []
  )

  return [activeAccordionId, handleAccordionChange]
}
