import { HistoryLocation } from '@gatsbyjs/reach-router'
import {
  kChatBotAppId,
  kChatBotRegion,
  kEventCategoryInteraction,
  kFileUpload,
  kImageUpload,
  kShareLocation,
} from '@utils/constants'
import { isBrowser } from '@utils/functional'
import { trackEvent } from '@utils/tracking'
import React, { ReactNode, ReactElement, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

export interface IChatbotContext {
  setIsChatbotLoaded: (value: boolean) => void
  setIsChatbotVisible: (value: boolean) => void
  setIsChatbotOpen: (value: boolean) => void
  openChatbot: (trackingAction: string) => void
  isChatbotLoaded: boolean
  isChatbotOpen: boolean
  isChatbotVisible: boolean
}

export const ChatbotContext = React.createContext<IChatbotContext>({
  setIsChatbotLoaded: (value: boolean) =>
    console.log('setIsChatbotLoaded', value),
  setIsChatbotOpen: (value: boolean) => console.log('setIsChatbotOpen', value),
  setIsChatbotVisible: (value: boolean) =>
    console.log('setIsChatbotVisible', value),
  openChatbot: (trackingAction: string) =>
    console.log('openChatbot', trackingAction),
  isChatbotLoaded: false,
  isChatbotOpen: false,
  isChatbotVisible: false,
})

export type ChatbotProviderProps = {
  children: ReactNode
  location?: HistoryLocation
  linkToSearch?: MCDC.Props.ILinkTo
}

export default function ChatbotProvider({
  children,
  location,
  linkToSearch,
}: ChatbotProviderProps): ReactElement {
  const intl = useIntl()
  const [isChatbotLoaded, setIsChatbotLoaded] = useState<boolean>(false)
  const [isChatbotOpen, setIsChatbotOpen] = useState<boolean>(false)
  const [isChatbotVisible, setIsChatbotVisible] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  function openChatbot(trackingAction: string) {
    trackEvent(
      kEventCategoryInteraction,
      trackingAction,
      location?.pathname || ''
    )
    if (!window.JobPal) return
    if (!isChatbotLoaded) {
      setIsLoading(true)
      return
    }
    window.JobPal.open()
  }

  useEffect(() => {
    let isSubscribed = true
    async function submit() {
      window.JobPal.on('widget:opened', function () {
        if (isSubscribed) {
          setIsChatbotOpen(true)
        }
        window.JobPal.startConversation()
      })
      window.JobPal.on('widget:closed', function () {
        if (isSubscribed) {
          setIsChatbotOpen(false)
        }
      })
      await window.JobPal.init({
        region: kChatBotRegion,
        appId: kChatBotAppId,
        menuItems: {
          imageUpload: kImageUpload,
          fileUpload: kFileUpload,
          shareLocation: kShareLocation,
        },
        customText: {
          headerText: intl.messages[`chatbot.headerText`] as string,
          introductionText: intl.messages[`chatbot.introductionText`] as string,
          inputPlaceholder: intl.messages[`chatbot.inputPlaceholder`] as string,
          messageDelivered: intl.messages[`chatbot.messageDelivered`] as string,
          messageRelativeTimeDay: intl.messages[
            `chatbot.messageRelativeTimeDay`
          ] as string,
          messageRelativeTimeHour: intl.messages[
            `chatbot.messageRelativeTimeHour`
          ] as string,
          messageRelativeTimeJustNow: intl.messages[
            `chatbot.messageRelativeTimeJustNow`
          ] as string,
          messageRelativeTimeMinute: intl.messages[
            `chatbot.messageRelativeTimeMinute`
          ] as string,
          messageSending: intl.messages[`chatbot.messageSending`] as string,
          sendButtonText: intl.messages[`chatbot.sendButtonText`] as string,
        },
      })

      if (isSubscribed) {
        setIsLoading(false)
        setIsChatbotLoaded(true)
        window.JobPal.open()
      }
    }

    if (isBrowser && isLoading && window.JobPal && !isChatbotLoaded) {
      submit()
    }

    return () => {
      isSubscribed = false
    }
  }, [isLoading])

  useEffect(() => {
    if (!isChatbotVisible && isChatbotOpen) {
      setIsChatbotOpen(false)
      if (window.JobPal.close) {
        window.JobPal.close()
      }
    }
  }, [isChatbotVisible])

  useEffect(() => {
    if (!isBrowser) return
    if (isChatbotVisible) {
      document.body.classList.add('is-jobpalpage')
    } else {
      document.body.classList.remove('is-jobpalpage')
    }
  }, [isChatbotVisible])

  return (
    <ChatbotContext.Provider
      value={{
        isChatbotLoaded,
        isChatbotOpen,
        isChatbotVisible,
        setIsChatbotLoaded,
        setIsChatbotOpen,
        setIsChatbotVisible,
        openChatbot,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  )
}
