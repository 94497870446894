import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Box, Grid, Theme } from '@mui/material'

import { useIntl } from 'react-intl'

import Button from '@components/core/input/Button'
import LocationGroup from '@components/core/form/groups/LocationGroup'

import useSearchLocation from '@components/site/hooks/search/useSearchLocation'
import useSearchQuery from '@components/site/hooks/search/useSearchQuery'
import AutocompleteInput from '@components/core/form/fields/AutocompleteInput'
import useSearchState from '@components/site/hooks/search/useSearchState'
import SearchFiltersDraweDrawer from './components/SearchFiltersDrawer'
import useGlobalState from '@components/site/hooks/useGlobalState'
import SelectInput from '@components/core/form/fields/SelectInput'
import useSearchFilters from '@components/site/hooks/search/useSearchFilters'
import { navigate } from 'gatsby'
import Chip from '@components/core/input/Chip'
import Link from '@components/core/input/Link'
import Icon from '@components/core/media/Icon'

export type SearchProps = {
  variant?: 'offers' | 'offersArea' | 'profile' | 'home'
  areaId?: MCDC.API.SearchFilterArea
  sx?: MCDC.Props.IDefault['sx']
}

export default function Search({
  variant = 'offers',
  areaId,
  sx,
}: SearchProps): ReactElement {
  const intl = useIntl()
  const buttonRef = useRef<HTMLButtonElement>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const globalState = useGlobalState()
  const searchState = useSearchState()
  const submitUrl = variant === 'home' ? searchState.linkTo?.url : undefined
  const {
    locationOptions,
    locationOption,
    distanceOptions,
    distanceOption,
    locationQuery,
    useCurrentLocation,
    setLocation,
    setLocationQuery,
    setDistance,
    isGeoLocated,
    isBusy: isLocationBusy,
  } = useSearchLocation(searchState, submitUrl)

  const {
    query,
    queryOption,
    queryOptions,
    setQuery,
    setQueryOption,
    isBusy: isQueryBusy,
  } = useSearchQuery(searchState, submitUrl)

  const {
    area,
    types,
    tasks,
    levels,
    setArea,
    setTypes,
    setTasks,
    setLevels,
    reset,
    areaFilters,
    typeFilters,
    taskFilters,
    levelFilters,
    isBusy: isFiltersBusy,
  } = useSearchFilters(searchState, submitUrl, areaId)

  useEffect(() => {
    globalState.setActiveDrawer(
      isDrawerOpen ? () => setIsDrawerOpen(false) : undefined
    )
  }, [isDrawerOpen])

  const totalFilters =
    (area && variant !== 'offersArea' ? 1 : 0) +
    (types?.length || 0) +
    (tasks?.length || 0) +
    (levels?.length || 0)

  const isOffers = variant === 'offers' || variant === 'offersArea'

  return (
    <Box sx={sx} width="100%">
      <Grid container spacing={2}>
        {variant !== 'profile' && variant !== 'offersArea' && (
          <Grid item xs={12} md={5}>
            <AutocompleteInput
              id="jobSearchWhat"
              icon="search"
              value={queryOption || null}
              inputValue={query}
              options={queryOptions || []}
              label={intl.messages['form.jobsearch.queryPlaceholder'] as string}
              onChange={(e, value) => setQueryOption(value)}
              onInputChange={(e, value) => setQuery(value)}
              isLoading={isQueryBusy}
              isDisabled={searchState.isBusy}
              isFreeSolo
              isClearable
              withoutBorder
            />
          </Grid>
        )}
        {variant === 'offersArea' && areaId && (
          <Grid item xs={12} md={5}>
            <SelectInput
              id="jobSearchFilterOffersArea"
              placeholder={intl.messages['label.chancesAll'] as string}
              value={levels?.[0] || null}
              options={levelFilters || []}
              withoutBorder
              onChange={(e) => {
                const id = e.target.value as string
                setLevels(levels?.[0]?.value === id ? [] : [id])
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md sx={{ position: 'relative' }}>
          <LocationGroup
            id="jobSearchWhere"
            value={locationOption || null}
            valueDistance={distanceOption}
            options={locationOptions}
            optionsDistance={distanceOptions}
            query={locationQuery}
            isGeoLocated={isGeoLocated}
            labels={{
              location: intl.messages[
                'form.jobsearch.locationPlaceholder'
              ] as string,
              distance: intl.messages[
                'form.jobsearch.distancePlaceholder'
              ] as string,
              useLocation: intl.messages['label.locationUseCurrent'] as string,
              loading: `... ${intl.messages['label.loading'] as string}`,
            }}
            isLoading={isLocationBusy}
            isDisabled={searchState.isBusy}
            withoutBorder
            onUseLocation={() => {
              useCurrentLocation()
              buttonRef.current?.focus()
            }}
            onChangeLocation={(e, value) => setLocation(value)}
            onChangeLocationInput={(e, value) => setLocationQuery(value)}
            onChangeDistance={(e) => {
              setDistance(Number(e.target.value))
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sx={(theme: Theme) => ({
            display: 'flex',
            [theme.breakpoints.down('md')]: { mt: 2 },
          })}
        >
          <Button
            ref={buttonRef}
            onClick={() => {
              if (variant === 'home' && searchState.linkTo?.url) {
                navigate(searchState.linkTo.url)
              }
              searchState.submit({
                pageUrl:
                  variant === 'home' ? searchState.linkTo?.url : undefined,
              })
            }}
            color="primary"
            sx={{ width: '100%' }}
            isLoading={searchState.isBusy}
          >
            {intl.messages['label.search']}
          </Button>
          {isOffers && (
            <Box
              sx={(theme: Theme) => ({
                position: 'relative',
                ml: 2,
                [theme.breakpoints.up('md')]: { display: 'none' },
              })}
            >
              <Button
                icon="filter_alt"
                onClick={() => setIsDrawerOpen(true)}
                isLoading={isFiltersBusy}
              />
              {!!totalFilters && (
                <Chip
                  label={String(totalFilters)}
                  size="small"
                  color="primary"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    transform: 'translate(50%, -50%);',
                  }}
                />
              )}
            </Box>
          )}
        </Grid>
        {isOffers && !!totalFilters && (
          <Grid
            item
            xs={12}
            sx={(theme: Theme) => ({
              display: 'flex',
              mt: 2,
              [theme.breakpoints.up('md')]: { display: 'none' },
            })}
          >
            <Grid container spacing={2}>
              {area && variant !== 'offersArea' && (
                <Grid item>
                  <Chip
                    label={area.label}
                    size="small"
                    isBold={false}
                    isClearable
                    onClick={() => setArea(undefined)}
                  />
                </Grid>
              )}
              {tasks &&
                tasks.map((entry) => (
                  <Grid item key={entry?.value}>
                    <Chip
                      label={entry.label}
                      size="small"
                      isBold={false}
                      isClearable
                      onClick={() =>
                        setTasks(
                          tasks
                            .filter(
                              (type) =>
                                String(type.value) !== String(entry.value)
                            )
                            .map((type) => String(type.value))
                        )
                      }
                    />
                  </Grid>
                ))}
              {levels &&
                levels.map((entry) => (
                  <Grid item key={entry?.value}>
                    <Chip
                      label={entry.label}
                      size="small"
                      isBold={false}
                      isClearable
                      onClick={() =>
                        setLevels(
                          levels
                            .filter(
                              (type) =>
                                String(type.value) !== String(entry.value)
                            )
                            .map((type) => String(type.value))
                        )
                      }
                    />
                  </Grid>
                ))}
              {types &&
                types.map((entry) => (
                  <Grid item key={entry?.value}>
                    <Chip
                      label={entry.label}
                      size="small"
                      isBold={false}
                      isClearable
                      onClick={() =>
                        setTypes(
                          types
                            .filter(
                              (type) =>
                                String(type.value) !== String(entry.value)
                            )
                            .map((type) => String(type.value))
                        )
                      }
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        )}

        {isOffers && (
          <Grid
            item
            xs={12}
            sx={(theme: Theme) => ({
              display: 'flex',
              flexWrap: 'wrap',
              pt: '0 !important',
              '> *': {
                mt: `${theme.spacing(2)} !important`,
                mr: `${theme.spacing(6)} !important`,
              },
              [theme.breakpoints.down('md')]: { display: 'none' },
            })}
          >
            {variant === 'offers' && (
              <SelectInput
                id="jobSearchFilterAreas"
                placeholder={intl.messages['label.jobAreas'] as string}
                value={area || null}
                options={areaFilters}
                variant="underlined"
                isMultipleCount
                onChange={(e) => setArea(e.target.value)}
              />
            )}
            {taskFilters && (
              <SelectInput
                id="jobSearchFilterTasks"
                placeholder={intl.messages['label.jobTasks'] as string}
                value={tasks}
                options={taskFilters}
                variant="underlined"
                onChange={(e) => setTasks(e.target.value as any)}
                isMultiple
                isMultipleCount
              />
            )}
            <SelectInput
              id="jobSearchFilterTypes"
              placeholder={intl.messages['label.jobTypes'] as string}
              value={types}
              options={typeFilters}
              variant="underlined"
              onChange={(e) => setTypes(e.target.value as any)}
              isMultiple
              isMultipleCount
            />

            {variant === 'offers' && levelFilters && (
              <SelectInput
                id="jobSearchFilterLevels"
                placeholder={intl.messages['label.joblevel'] as string}
                value={levels}
                options={levelFilters}
                variant="underlined"
                onChange={(e) => setLevels(e.target.value as any)}
                isMultiple
                isMultipleCount
              />
            )}
            {totalFilters > 0 && (
              <Link
                onClick={() => {
                  reset(
                    variant === 'offersArea'
                      ? [(area?.value as string) || '']
                      : undefined
                  )
                }}
              >
                {intl.messages['label.resetFilters'] as string}
                <Icon variant="close" sx={{ color: 'primary.main' }} />
              </Link>
            )}
          </Grid>
        )}
      </Grid>
      {isOffers && (
        <SearchFiltersDraweDrawer
          area={variant !== 'offersArea' ? area : undefined}
          types={types}
          tasks={tasks}
          levels={variant !== 'offersArea' ? levels : undefined}
          areaFilters={variant !== 'offersArea' ? areaFilters : undefined}
          typeFilters={typeFilters}
          taskFilters={taskFilters}
          levelFilters={variant !== 'offersArea' ? levelFilters : undefined}
          totalResult={searchState.history.result?.meta.totalJobcount || 0}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onSelectArea={(id) => setArea(id)}
          onSelectType={(ids) => setTypes(ids)}
          onSelectTasks={(ids) => setTasks(ids)}
          onSelectLevels={(ids) => setLevels(ids)}
          onReset={() => {
            reset(
              variant === 'offersArea'
                ? [(area?.value as string) || '']
                : undefined
            )
          }}
          sx={{ mt: 16 }}
        />
      )}
    </Box>
  )
}
