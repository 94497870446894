import React from 'react'
import { HistoryLocation } from '@gatsbyjs/reach-router'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import parse from 'html-react-parser'
import { Container, Theme, SxProps, Breakpoint } from '@mui/material'

import Copy from '@components/core/text/Copy'
import Headline from '@components/core/text/Headline'
import { PageModule } from '@components/modules'
import Button from '@components/core/input/Button'
import Link from '@components/core/input/Link'
import { getAssetProp } from './props'
import Image from '@components/core/media/Image'

export type DocumentNodeType = {
  nodeType: string
  data?: Record<string, unknown>
  content?: Array<DocumentNodeType>
  value?: string
}

const sxText: MCDC.Props.IDefault['sx'] = {
  mt: 4,
  '&:first-of-type': {
    mt: 0,
  },
}

const sxHeadlineH3: MCDC.Props.IDefault['sx'] = (theme: Theme) => ({
  mt: theme.padding('small', 'sm'),
  [theme.breakpoints.up('md')]: {
    mt: theme.padding('small', 'md'),
  },
  [theme.breakpoints.up('lg')]: {
    mt: theme.padding('small', 'lg'),
  },
  '&:first-of-type': {
    mt: 0,
  },
})

const sxSubline: MCDC.Props.IDefault['sx'] = {
  mt: 10,
  '&:first-of-type': {
    mt: 0,
  },
}

const sxModules: MCDC.Props.IDefault['sx'] = (theme: Theme) => ({
  my: theme.padding('small', 'sm'),
  [theme.breakpoints.up('md')]: {
    my: theme.padding('small', 'md'),
  },
  [theme.breakpoints.up('lg')]: {
    my: theme.padding('small', 'lg'),
  },
  '&:first-of-type': {
    mt: 0,
  },
  '&:last-of-type': {
    mb: 0,
  },
})

const sxAsset: MCDC.Props.IDefault['sx'] = (theme: Theme) => ({
  my: theme.padding('small', 'sm'),
  [theme.breakpoints.up('md')]: {
    my: theme.padding('small', 'md'),
  },
  [theme.breakpoints.up('lg')]: {
    my: theme.padding('small', 'lg'),
  },
  '&:first-of-type': {
    mt: 0,
  },
  '&:last-of-type': {
    mb: 0,
  },
})

type WrapperProps = {
  children?: any
  maxWidth?: 'sm' | 'md' | 'lg'
  sx?: MCDC.Props.IDefault['sx']
}

const Wrapper = ({ children, maxWidth, sx }: WrapperProps) => {
  if (maxWidth) {
    return (
      <Container maxWidth={maxWidth} sx={sx}>
        {children}
      </Container>
    )
  }

  return children
}

const getNodeText = (children: any) => {
  return children.map((entry: any) => entry?.props?.children || entry).join()
}

export const richTextOptionsBasic: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
  },
  renderText: (text) => {
    return parse(
      text
        .replace(/\n/g, '<br />')
        .replace(/®/g, '<sup>®</sup>')
        .replace(/©/g, '<sup>©</sup>')
        .replace(/℗/g, '<sup>℗</sup>')
    )
  },
  renderNode: {},
}

export const richTextOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
  },
  renderText: (text) => {
    return parse(
      text
        .replace(/\n/g, '<br />')
        .replace(/®/g, '<sup>®</sup>')
        .replace(/©/g, '<sup>©</sup>')
        .replace(/℗/g, '<sup>℗</sup>')
    )
  },
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.HEADING_1]: (node: any, children: any) => {
      const { maxWidth } = node.data
      return (
        <Wrapper sx={maxWidth ? sxHeadlineH3 : undefined} maxWidth={maxWidth}>
          <Headline
            component="p"
            variant="h1"
            sx={!maxWidth ? sxHeadlineH3 : undefined}
          >
            {children}
          </Headline>
        </Wrapper>
      )
    },
    [BLOCKS.HEADING_2]: (node: any, children: any) => {
      const { maxWidth } = node.data
      return (
        <Wrapper sx={maxWidth ? sxHeadlineH3 : undefined} maxWidth={maxWidth}>
          <Headline
            component="p"
            variant="h2"
            sx={!maxWidth ? sxHeadlineH3 : undefined}
          >
            {children}
          </Headline>
        </Wrapper>
      )
    },
    [BLOCKS.HEADING_3]: (node: any, children: any) => {
      const { maxWidth } = node.data
      return (
        <Wrapper sx={maxWidth ? sxHeadlineH3 : undefined} maxWidth={maxWidth}>
          <Headline
            component="p"
            variant="h3"
            sx={!maxWidth ? sxHeadlineH3 : undefined}
          >
            {children}
          </Headline>
        </Wrapper>
      )
    },
    [BLOCKS.HEADING_4]: (node: any, children: any) => {
      const { maxWidth } = node.data
      return (
        <Wrapper sx={maxWidth ? sxHeadlineH3 : undefined} maxWidth={maxWidth}>
          <Headline
            component="p"
            variant="h4"
            sx={!maxWidth ? sxHeadlineH3 : undefined}
          >
            {children}
          </Headline>
        </Wrapper>
      )
    },
    [BLOCKS.HEADING_5]: (node: any, children: any) => {
      const { maxWidth } = node.data
      return (
        <Wrapper sx={maxWidth ? sxSubline : undefined} maxWidth={maxWidth}>
          <Headline
            component="p"
            variant="h5"
            sx={!maxWidth ? sxSubline : undefined}
          >
            {children}
          </Headline>
        </Wrapper>
      )
    },
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      const { maxWidth } = node.data
      return (
        <Wrapper sx={maxWidth ? sxText : undefined} maxWidth={maxWidth}>
          <Copy component="p" sx={!maxWidth ? sxText : undefined}>
            {children}
          </Copy>
        </Wrapper>
      )
    },
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      const { maxWidth } = node.data
      return (
        <Wrapper sx={maxWidth ? sxText : undefined} maxWidth={maxWidth}>
          <Copy component="ul" sx={!maxWidth ? sxText : undefined}>
            {children}
          </Copy>
        </Wrapper>
      )
    },
    [BLOCKS.OL_LIST]: (node: any, children: any) => {
      const { maxWidth } = node.data
      return (
        <Wrapper sx={maxWidth ? sxText : undefined} maxWidth={maxWidth}>
          <Copy component="ol" sx={!maxWidth ? sxText : undefined}>
            {children}
          </Copy>
        </Wrapper>
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { target, location } = node.data
      if (!target || !location) return null
      let maxWidth: Breakpoint = node.data.maxWidth || 'lg'
      let moduleTheme: MCDC.Props.ThemeType | undefined
      switch (target.__typename) {
        case 'ContentfulModuleTestimonials':
        case 'ContentfulModuleVideo':
          maxWidth = 'md'
          break
        case 'ContentfulModuleFaq':
          maxWidth = 'sm'
          break
        case 'ContentfulModuleFacts':
          moduleTheme = 'light'
          break
      }

      return (
        <PageModule
          key={`${node.data.target.id}`}
          location={location}
          moduleProps={target}
          maxWidth={maxWidth}
          theme={moduleTheme}
          sx={sxModules}
        />
      )
    },
    [INLINES.HYPERLINK]: (node: any, children: any) => {
      const { uri, maxWidth } = node.data

      const isButton =
        (children as any).type === 'strong' ||
        (children as any)[0]?.type === 'strong'
      const label = isButton
        ? ((children as any)[0] || (children as any)).props.children
        : children

      return (
        <Wrapper sx={maxWidth ? sxText : undefined} maxWidth={maxWidth}>
          {isButton && (
            <Button
              to={uri}
              color="primary"
              sx={!maxWidth ? sxText : undefined}
              isExternal
            >
              {label}
            </Button>
          )}
          {!isButton && (
            <Link
              to={uri}
              sx={!maxWidth ? sxText : undefined}
              underline="always"
              isExternal
            >
              {label}
            </Link>
          )}
        </Wrapper>
      )
    },
    [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
      const { target, maxWidth } = node.data

      if (!(target as MCDC.Contentful.UnionPageTypes)?.fields?.linkTo?.url) {
        return null
      }

      const isButton =
        (children as any).type === 'strong' ||
        (children as any)[0]?.type === 'strong'
      const label = isButton
        ? ((children as any)[0] || (children as any)).props.children
        : children

      return (
        <Wrapper sx={maxWidth ? sxText : undefined} maxWidth={maxWidth}>
          {isButton && (
            <Button
              to={
                (target as MCDC.Contentful.UnionPageTypes).fields?.linkTo?.url
              }
              color="primary"
              sx={!maxWidth ? sxText : undefined}
            >
              {label}
            </Button>
          )}
          {!isButton && (
            <Link
              to={
                (target as MCDC.Contentful.UnionPageTypes)?.fields?.linkTo?.url
              }
              isExternal={
                (target as MCDC.Contentful.UnionPageTypes)?.fields?.linkTo
                  ?.isExternal
              }
              sx={!maxWidth ? sxText : undefined}
              underline="always"
            >
              {label}
            </Link>
          )}
        </Wrapper>
      )
    },
    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
      const { target, maxWidth } = node.data

      if (!(target as MCDC.Contentful.IAsset)?.localFile?.publicURL) {
        return null
      }

      const isButton =
        (children as any).type === 'strong' ||
        (children as any)[0]?.type === 'strong'
      const label = isButton
        ? ((children as any)[0] || (children as any)).props.children
        : children

      return (
        <Wrapper sx={maxWidth ? sxText : undefined} maxWidth={maxWidth}>
          {isButton && (
            <Button
              to={(target as MCDC.Contentful.IAsset).localFile?.publicURL}
              color="primary"
              sx={!maxWidth ? sxText : undefined}
              isExternal
              isDownload
            >
              {label}
            </Button>
          )}
          {!isButton && (
            <Link
              to={(target as MCDC.Contentful.IAsset).localFile?.publicURL}
              sx={!maxWidth ? sxText : undefined}
              underline="always"
              isExternal
              isDownload
            >
              {label}
            </Link>
          )}
        </Wrapper>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
      const { target, maxWidth } = node.data
      const asset = getAssetProp(target) as MCDC.Props.IAssetImage
      if (!asset?.data) return null

      return (
        <Wrapper sx={maxWidth ? sxAsset : undefined} maxWidth={maxWidth}>
          <Image {...asset} sx={!maxWidth ? sxAsset : undefined} />
        </Wrapper>
      )
    },
  },
}

function getInlineEntryComponent(target: any, sx?: SxProps<Theme>) {
  switch (target.__typename) {
    case 'ContentfulGlobalVideo':
      // const props = getGlobalProp(target) as MCDC.Props.IGlobalVideo
      return null // <YouTube {...props} sx={sx} />
  }
}

type IRichTextNormalized<T> = {
  raw: {
    data: any
    nodeType: any
    content: Array<DocumentNodeType>
  }
  references: Array<T>
}

export const normalizeRichText = (
  richText?: MCDC.Contentful.IRichtext<MCDC.Contentful.UnionBasicRichTextContent>,
  options?: { location?: HistoryLocation }
):
  | IRichTextNormalized<MCDC.Contentful.UnionBasicRichTextContent>
  | undefined => {
  if (!richText) return
  const parsed = JSON.parse(richText.raw)
  return {
    references: richText.references,
    raw: {
      data: parsed.data,
      nodeType: parsed.nodeType,
      content: parsed.content
        .map((component: DocumentNodeType, index: number) => {
          switch (component.nodeType) {
            case BLOCKS.EMBEDDED_ASSET:
            case BLOCKS.HEADING_1:
            case BLOCKS.HEADING_2:
            case BLOCKS.HEADING_3:
            case BLOCKS.HEADING_4:
            case BLOCKS.HEADING_5:
            case BLOCKS.HEADING_6:
            case BLOCKS.PARAGRAPH:
            case BLOCKS.UL_LIST:
            case BLOCKS.OL_LIST:
            case BLOCKS.QUOTE:
              // remove empty last paragraph
              if (index == parsed.content.length - 1) {
                if (
                  component.content &&
                  component.content.length == 1 &&
                  component.content[0].nodeType == 'text' &&
                  component.content[0].value == ''
                ) {
                  return null
                }
              }

              return {
                ...component,
                data: {
                  ...component.data,
                  maxWidth: 'sm',
                },
              }
            case BLOCKS.EMBEDDED_ENTRY:
              return {
                ...component,
                data: {
                  ...component.data,
                  location: options?.location,
                  maxWidth: 'lg',
                },
              }
            case INLINES.HYPERLINK:
            case INLINES.ASSET_HYPERLINK:
            case INLINES.ENTRY_HYPERLINK: {
              return {
                ...component,
                data: {
                  ...component.data,
                  maxWidth: 'sm',
                },
              }
            }
            default:
              return component
          }
        })
        .filter((entry: DocumentNodeType | null) => !!entry),
    },
  }
}
