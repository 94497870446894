/* eslint-disable @typescript-eslint/no-unused-vars */
import { HistoryLocation } from '@gatsbyjs/reach-router'
import { useStore } from '@nanostores/react'

import { kYoutubeCookieId } from '@utils/constants'
import { setCookieById, getCookieById } from '@utils/cookies'
import { activateYoutubeConsentInOntrust } from '@utils/consenthandling'
import React, { ReactNode, ReactElement, useState, useEffect } from 'react'
import {
  globalLocation,
  setGlobalLocale,
  setGlobalLocation,
} from '@store/global'
import { tLocales } from '../../../translations'

export interface IStateContext {
  getYoutubeConsent: () => boolean
  setLocation: (value: HistoryLocation) => void
  setActiveDrawer: (onClose?: () => void) => void
  activeDrawer?: { onClose: () => void }
  location?: HistoryLocation
  isYoutubeConsented: boolean
  setYoutubeConsented: (consent: boolean) => void
}

export const StateContext = React.createContext<IStateContext>({
  setActiveDrawer: () => console.log(''),
  getYoutubeConsent: () => false,

  isYoutubeConsented: false,
  setYoutubeConsented: () => console.log(''),
  setLocation: (value: HistoryLocation) => console.log('setLocation', value),
})

export type StateProviderProps = {
  children: ReactNode
  location?: HistoryLocation
  locale?: string
}

export default function StateProvider({
  children,
  location: pageLocation,
  locale = 'en',
}: StateProviderProps): ReactElement {
  const location = useStore(globalLocation)

  const [activeDrawer, setActiiveDrawerState] = useState<
    { onClose: () => void } | undefined
  >()

  const [isYoutubeConsented, setYoutubeConsentedState] = useState<boolean>(
    getCookieById(kYoutubeCookieId) === 'true' // to Consent tool
  )

  const getYoutubeConsent = () => {
    return getCookieById(kYoutubeCookieId) === 'true'
  }

  const setYoutubeConsented = (consent: boolean) => {
    setCookieById(kYoutubeCookieId, consent)
    setYoutubeConsentedState(consent)
  }

  function setLocation(value: HistoryLocation) {
    setGlobalLocation(value)
  }

  function setActiveDrawer(onClose?: () => void) {
    setActiiveDrawerState(onClose ? { onClose } : undefined)
  }

  useEffect(() => {
    const handler = (payload: any) => {
      if (!payload.detail) {
        return
      }
      payload.detail.find((consentId: string) => consentId === '6')
        ? setYoutubeConsentedState(true)
        : setYoutubeConsentedState(false)
    }
    window.addEventListener('OneTrustGroupsUpdated', handler)
    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', handler)
    }
  }, [])

  useEffect(() => {
    if (!isYoutubeConsented) return
    activateYoutubeConsentInOntrust()
  }, [isYoutubeConsented])

  useEffect(() => {
    setGlobalLocale(locale as tLocales)
  }, [locale])

  useEffect(() => {
    setGlobalLocation(pageLocation)
  }, [])

  return (
    <StateContext.Provider
      value={{
        location,
        activeDrawer,
        setLocation,
        setActiveDrawer,
        getYoutubeConsent,
        isYoutubeConsented,
        setYoutubeConsented,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}
