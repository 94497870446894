import Section from '@components/core/container/Section'
import Button from '@components/core/input/Button'
import Headline from '@components/core/text/Headline'
import Box from '@mui/material/Box/Box'
import { Theme } from '@mui/material'

import React from 'react'

interface LinkCollectionProps {
  headline: string
  style: 'primary' | 'secondary'
  items: Array<{
    label: string
    isExternal: boolean
    url: string
  }>
}

export default function LinkCollection({
  headline,
  style,
  items,
}: LinkCollectionProps) {
  return (
    <Section maxWidth="md">
      <Headline variant="h2" sx={{ mt: 6 }}>
        {headline}
      </Headline>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {items.map((item, index) => (
          <Button
            key={index}
            to={item.url}
            isExternal={item.isExternal}
            color={style}
            variant="rounded"
            icon="arrow_forward_ios"
            hasEndIcon
            sx={(theme: Theme) => ({
              mt: 6,
              mr: 2,
              width: '100%',
              [theme.breakpoints.up('md')]: {
                width: '48%',
              },
            })}
          >
            {item.label}
          </Button>
        ))}
      </Box>
    </Section>
  )
}
