import React, { ReactNode, ReactElement, useState, useEffect } from 'react'

import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from 'use-query-params'
import { debounce } from '@mui/material'

type QueryParams = {
  title?: string
  refId?: string
  query?: string
  filters?: string[]
  location?: string | null
  distance?: number
  geolocating?: boolean
  profile?: string
}

export interface IQueryParamsContext {
  getTitle: () => string | undefined
  getRefId: () => string | undefined
  setProfile: (value?: MCDC.API.IProfile) => void
  setQuery: (value: string) => void
  setLocation: (value?: string) => void
  setDistance: (value?: number) => void
  setFilters: (value: string[]) => void
  setGeolocating: (value?: boolean) => void
  hasSearchParams: () => boolean
  params: QueryParams
}

export const QueryParamsContext = React.createContext<IQueryParamsContext>({
  getTitle: () => undefined,
  getRefId: () => undefined,
  setProfile: (value) => console.log(value),
  setQuery: (value) => console.log(value),
  setLocation: (value) => console.log(value),
  setDistance: (value) => console.log(value),
  setFilters: (value) => console.log(value),
  setGeolocating: (value?: boolean) => console.log(value),
  hasSearchParams: () => false,
  params: {},
})

export type QueryParamsProviderProps = {
  children: ReactNode
  linkToSearch?: MCDC.Props.ILinkTo
}

export default function QueryParamsProvider({
  children,
}: QueryParamsProviderProps): ReactElement {
  const [title] = useQueryParam('title', StringParam, {
    updateType: 'replaceIn',
  })
  const [refId] = useQueryParam('refId', StringParam, {
    updateType: 'replaceIn',
  })
  const [isRelative, setRelativeState] = useQueryParam('rel', StringParam, {
    updateType: 'replaceIn',
  })
  const [query, setQueryState] = useQueryParam('query', StringParam, {
    updateType: 'replaceIn',
  })
  const [geolocating, setGeoLocatingState] = useQueryParam('geo', StringParam, {
    updateType: 'replaceIn',
  })
  const [location, setLocationState] = useQueryParam('location', StringParam, {
    updateType: 'replaceIn',
  })
  const [distance, setDistanceState] = useQueryParam('distance', NumberParam, {
    updateType: 'replaceIn',
  })
  const [filters, setFiltersState] = useQueryParam('filters', ArrayParam, {
    updateType: 'replaceIn',
  })
  const [profile, setProfileState] = useQueryParam('profile', StringParam, {
    updateType: 'replaceIn',
  })
  const [params, setParams] = useState<QueryParams>({
    title: title || undefined,
    refId: refId || undefined,
    query: profile || query || undefined,
    geolocating: geolocating === 'true' || false,
    filters: (filters as string[]) || undefined,
    profile: profile || undefined,
    distance: distance || 0,
    location: location || undefined,
  })

  function getTitle() {
    return title || undefined
  }
  function getRefId() {
    return refId || undefined
  }

  function setGeolocating(value?: boolean) {
    setGeoLocatingState(value ? 'true' : undefined)
  }

  function setQuery(value: string) {
    if (value && value === profile) return
    setQueryState(value || undefined)
  }

  function setLocation(value?: string) {
    setLocationState(value)
  }

  function setDistance(value?: number) {
    setDistanceState(value || undefined)
  }

  function setFilters(value: string[]) {
    setFiltersState(value)
  }

  function setProfile(value?: MCDC.API.IProfile) {
    setProfileState(value?.title || null)
    if (value && query) {
      setQueryState(undefined)
    }
  }

  function setIsRelative(value?: boolean) {
    setRelativeState(value ? 'true' : undefined)
  }

  function hasSearchParams(): boolean {
    return !!query || !!location || !!filters || !!geolocating || !!profile
  }

  useEffect(() => {
    if (!isRelative) return
    setIsRelative(false)
    setParams({
      title: title || undefined,
      refId: refId || undefined,
      query: profile || query || undefined,
      geolocating: geolocating === 'true' || false,
      filters: (filters as string[]) || undefined,
      profile: profile || undefined,
      distance: distance || 0,
      location: location || undefined,
    })
  }, [isRelative])

  return (
    <QueryParamsContext.Provider
      value={{
        params,
        getTitle,
        getRefId,
        setGeolocating: debounce(setGeolocating, 500),
        setQuery: debounce(setQuery, 500),
        setLocation: debounce(setLocation, 500),
        setDistance: debounce(setDistance, 500),
        setFilters: debounce(setFilters, 500),
        setProfile: debounce(setProfile, 500),
        hasSearchParams,
      }}
    >
      {children}
    </QueryParamsContext.Provider>
  )
}
