import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import SocailIcon, { SocailIconProps } from '@components/core/media/SocialIcon'

export type SocialButtonProps = {
  id?: string
  to?: string
  onClick?: (event: React.SyntheticEvent) => void
  variant?: SocailIconProps['variant']
  isActive?: boolean
  isDisabled?: boolean
  isDownload?: boolean
  isExternal?: boolean
  sx?: MCDC.Props.IDefault['sx']
}

/**
 * MUI Button Wrapper
 */
export default function SocialButton({
  id,
  to,
  variant,
  onClick,
  sx = {},
}: SocialButtonProps): ReactElement {
  return (
    <Box
      id={id}
      href={to}
      target="_blank"
      rel="noreferrer"
      component="a"
      onClick={onClick}
      sx={[{ cursor: 'pointer' }, sx]}
    >
      <SocailIcon variant={variant} />
    </Box>
  )
}
