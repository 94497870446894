import Button from '@components/core/input/Button'
import Headline from '@components/core/text/Headline'
import Box from '@mui/material/Box/Box'
import { Theme } from '@mui/material'

import React from 'react'
import Copy from '@components/core/text/Copy'

export interface EducationTeaserItemProps {
  headline: string
  copy: string
  image: MCDC.Props.IAssetImage
  links: Array<{
    label: string
    url: string
    openInNewTab: boolean
  }>
}

//TODO: Use Image component for better performance and accessibility
export default function EducationTeaserItem({
  headline,
  copy,
  image,
  links,
}: EducationTeaserItemProps) {
  return (
    <Box
      sx={(theme: Theme) => ({
        width: '100%',
        backgroundColor: theme.palette.common.white,
        borderRadius: '8px',
        overflow: 'hidden',
        mb: 6,
        [theme.breakpoints.up('md')]: {
          width: '46%',
          mb: 0,
        },
      })}
    >
      <Box
        component={'img'}
        src={image}
        sx={{
          maxWidth: '580px',
          maxHeight: '200px',
          display: 'block',
        }}
      />
      <Box sx={{ px: 6, pb: 6, pt: 4 }}>
        <Headline variant="h4" sx={{ mb: 2 }}>
          {headline}
        </Headline>
        <Copy variant="body1" sx={{ mb: 2, minHeight: '36px' }}>
          {copy}
        </Copy>
        <Box
          sx={(theme: Theme) => ({
            position: 'sticky',
            bottom: 16,
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            [theme.breakpoints.up('md')]: {
              justifyContent: 'space-between',
            },
          })}
        >
          {links.map((link, index) => (
            <Button
              isExternal={link.openInNewTab}
              key={index}
              to={link.url}
              color="primary"
              variant="rounded"
              sx={(theme: Theme) => ({
                mt: 2,
                width: '100%',
                [theme.breakpoints.up('md')]: {
                  width: '48%',
                },
              })}
            >
              {link.label}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
