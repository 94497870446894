import React, { ReactElement } from 'react'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material'

import Section from '@components/core/container/Section'
import Headline from '@components/core/text/Headline'
import Image from '@components/core/media/Image'
import Copy from '@components/core/text/Copy'
import SocialButton from "@components/core/input/SocialButton";

export type AmbassadorProps = {
  headline: string
  copy?: MCDC.Props.RichText
  image: MCDC.Props.IAssetImage
  urlToInstagram: string
  urlToTikTok: string
  noWrapper: boolean
  sx?: MCDC.Props.IDefault['sx']
}

export default function Ambassador({
  headline,
  copy,
  image,
  urlToInstagram,
  urlToTikTok,
  noWrapper,
}: AmbassadorProps): ReactElement {
  const ComponentSection = (children) => {

    return (
      <Section>{children}</Section>
    )
  };

  const ComponentBase = () => {

    return (
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          justifyContent: 'center',
          flexBasis: '100%',
          [theme.breakpoints.up('md')]: {
            flexBasis: '50%',
          },
          [theme.breakpoints.up('lg')]: {
            flexBasis: '32%',
            '&:first-of-type': {
              mr: 2,
              ml: 0,
            },
            '&:nth-of-type(2)': {
              mx: 2,
            },
            '&:nth-of-type(3)': {
              ml: 2,
              mr: 0,
            },
            '&:nth-of-type(4)': {
              mr: 2,
              ml: 0,
            },
            '&:nth-of-type(5)': {
              mx: 2,
            },
            '&:nth-of-type(6)': {
              ml: 2,
              mr: 0,
            },
            '&:only-child': {
              mx: 0,
            },
          },
        })}
      >
        <Box
          sx={(theme: Theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'common.white',
            borderRadius: '8px',
            overflow: 'hidden',
            maxWidth: '342px',
            [theme.breakpoints.up('lg')]: {
              maxWidth: '368px',
            },
          })}
        >
          <Box
            sx={{ width: '100%' }}
          >
            <Image
              {...image}
              sx={{ height: '100%' }}
              objectFit="cover"
              objectPosition="center"
            />
          </Box>
          <Box
            sx={(theme: Theme) => ({
              display: 'flex',
              flexDirection: 'column',
              p: theme.gutter('sm'),
              height: '100%',
            })}
          >
            <Headline variant="h5">{headline}</Headline>
            {copy && (
              <Copy component="div" sx={{ mt: 4 }}>
                {copy}
              </Copy>
            )}
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                mt: 4,
                '> *': {
                  mr: 4,
                  ':last-of-type': {
                    mr: 0,
                  }
                },
              }}
            >
              {urlToInstagram &&
              <SocialButton to={urlToInstagram} variant="Instagram" />
              }
              {urlToTikTok &&
              <SocialButton to={urlToTikTok} variant="TikTok" />
              }
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  return noWrapper ? ComponentBase() : ComponentSection(ComponentBase())
}
