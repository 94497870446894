import React, { ReactElement } from 'react'

import Section from '@components/core/container/Section'
import Headline from '@components/core/text/Headline'
import { alpha, Box, Breakpoint, IconButton, Theme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import Search from '../Search'
import Button from '@components/core/input/Button'
import Chip from '@components/core/input/Chip'
import Copy from '@components/core/text/Copy'
import { getJobAddress, getJobTypeLabel } from '@utils/props'
import Icon from '@components/core/media/Icon'

export type StageProps = {
  variant?: 'home' | 'profile' | 'detail'
  headline: string
  background: MCDC.Props.IGlobalImage | MCDC.Props.IGlobalVideo
  item?: MCDC.API.IJobGroupInfo | MCDC.API.IJobDetail
  linkTo?: MCDC.Props.IButtonTo
  maxWidth?: Breakpoint
  sx?: MCDC.Props.IDefault['sx']
  isFavorite?: boolean
  onClickFavorite?: () => void
}

export default function Stage({
  variant = 'home',
  headline,
  background,
  item,
  linkTo,
  maxWidth,
  sx,
  isFavorite,
  onClickFavorite,
}: StageProps): ReactElement {
  const intl = useIntl()
  const image =
    (background as MCDC.Props.IGlobalVideo)?.image ||
    (background as MCDC.Props.IGlobalImage)
  const theme = image?.theme

  return (
    <Section
      theme={theme}
      background={background}
      backgroundLoading="eager"
      backgroundPosition={variant !== 'home' ? 'top center' : undefined}
      minHeight={variant !== 'home' ? { sm: 624, md: 408 } : undefined}
      maxWidth={maxWidth}
      paddingSize="small"
      sx={[
        sx,
        {
          height:
            variant === 'home'
              ? 'calc(var(--vh, 1vh) * 100 - 64px)'
              : undefined,
          maxHeight: variant === 'home' ? '1100px' : undefined,
        },
      ]}
    >
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: variant === 'home' ? 'center' : undefined,
          justifyContent: 'flex-end',
          mt: 'auto',
          [theme.breakpoints.down('md')]:
            variant !== 'home'
              ? {
                  mx: -6,
                  px: 6,
                  mb: -8,
                  py: 8,
                  mt: 'auto',
                  backgroundColor: alpha(theme.palette.common.white, 0.9),
                }
              : undefined,
        })}
      >
        <Headline
          variant={variant === 'home' ? 'h1' : 'h3'}
          sx={(theme: Theme) => ({
            mb: 8,
            textAlign: variant === 'home' ? 'center' : undefined,
            [theme.breakpoints.up('md')]: {
              width: variant !== 'home' ? '50%' : undefined,
            },
          })}
          isHtml
        >
          {headline}
        </Headline>
        {variant === 'home' && <Search variant="home" />}
        {variant === 'detail' && (
          <Box sx={{ minHeight: '68px', mb: 4 }}>
            {item && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 3,
                  }}
                >
                  {((item as MCDC.API.IJobDetail).isParttime ||
                    (item as MCDC.API.IJobDetail).isFulltime ||
                    (item as MCDC.API.IJobDetail).isMinijob) && (
                    <Copy sx={{ mr: 4 }}>
                      <strong>{getJobTypeLabel(item, intl)}</strong>
                    </Copy>
                  )}
                  {!!(item as MCDC.API.IJobDetail).employmentType && (
                    <Chip
                      label={(item as MCDC.API.IJobDetail).employmentType}
                      variant="outlined"
                      color={theme === 'dark' ? 'secondary' : undefined}
                    />
                  )}
                </Box>
                <Copy>
                  <strong>
                    {(item as MCDC.API.IJobGroupInfo).locationCount
                      ? (item as MCDC.API.IJobDetail).address
                      : getJobAddress(item as MCDC.API.IJobDetail)}
                  </strong>
                </Copy>
                {(item as MCDC.API.IJobDetail).locationName && (
                  <Copy>
                    <strong>
                      <FormattedMessage
                        id="label.franchise"
                        values={{
                          value: (item as MCDC.API.IJobDetail).locationName,
                        }}
                      />
                    </strong>
                  </Copy>
                )}
              </>
            )}
          </Box>
        )}
        {variant === 'profile' && (
          <Copy component="div" sx={{ mb: 4, minHeight: '28px' }}>
            {item && (
              <strong>
                {intl.formatMessage(
                  { id: 'label.multipleLocations' },
                  {
                    value: intl.formatNumber(
                      (item as MCDC.API.IJobGroupInfo).locationCount
                    ),
                  }
                )}
              </strong>
            )}
          </Copy>
        )}

        {linkTo && (
          <Box
            sx={(theme: Theme) => ({
              display: 'flex',
              // visibility: linkTo.isDisabled ? 'hidden' : undefined,
              [theme.breakpoints.up('md')]: {
                minWidth: '300px',
                alignSelf: 'flex-start',
              },
            })}
          >
            <Button
              to={linkTo.url}
              isExternal={linkTo.isExternal}
              isDisabled={linkTo.isDisabled}
              isLoading={linkTo.isLoading}
              onClick={linkTo.onClick}
              color="primary"
              sx={(theme: Theme) => ({
                flexGrow: 1,
                [theme.breakpoints.up('md')]: {
                  alignSelf: 'flex-start',
                },
              })}
            >
              {linkTo.label}
            </Button>
            {variant === 'detail' && !linkTo?.isDisabled && onClickFavorite && (
              <>
                <IconButton
                  onClick={onClickFavorite}
                  sx={(theme: Theme) => ({
                    color: 'primary.main',
                    ml: 2,
                    [theme.breakpoints.up('md')]: {
                      display: 'none',
                    },
                  })}
                >
                  <Icon
                    variant={!isFavorite ? 'favorite' : 'favorite_filled'}
                    size="large"
                  />
                </IconButton>
                <Button
                  onClick={onClickFavorite}
                  color="secondary"
                  icon={!isFavorite ? 'favorite' : 'favorite_filled'}
                  sx={(theme: Theme) => ({
                    ml: 4,
                    [theme.breakpoints.down('md')]: {
                      display: 'none',
                    },
                  })}
                >
                  <FormattedMessage id="label.notice" />
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>
    </Section>
  )
}
