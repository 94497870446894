import React, { ReactElement } from 'react'

import Box from '@mui/material/Box'
import { IconButton, Theme } from "@mui/material";
import Copy from "@components/core/text/Copy";
import Icon from "@components/core/media/Icon";
import { setCookieById } from "@utils/cookies";
import { kCookieBannerCookieId, kCookieMethodCookie } from "@utils/constants";


export type CookieBannerProps = {
  copy: string
  onClose?: () => void
  sx?: MCDC.Props.IDefault['sx']
}

export default function CookieBanner({
  copy,
  onClose,
  sx
}: CookieBannerProps): ReactElement {

  const ClosingAction = () => {
   setCookieById(kCookieBannerCookieId, 'true', kCookieMethodCookie, 1/48)
    if (onClose) {
      onClose()
    }
  }

  return (
    <Box
      sx={(theme: Theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.background.secondary,
        width: '100%',
        p: 6,
    })}
    >
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          color: theme.palette.common.white,
      })}>
        <Icon variant="favorite" sx={{ color: 'inherit', verticalAlign: 'middle' }} />
        <Copy
          component="span"
          sx={{ px: 4 }}
        >
          {copy}
        </Copy>
      </Box>
        <Box
          sx={(theme: Theme) => ({
            color: theme.palette.common.white,
          })}
        >
          <IconButton onClick={ClosingAction} color="inherit">
            <Icon variant="close" />
          </IconButton>
        </Box>
    </Box>
  )
}
