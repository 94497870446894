import React, { ElementType, ReactElement } from 'react'

import TextBase from '../TextBase'

export type HeadlineProps = {
  id?: string
  isHtml?: boolean
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  component?: ElementType<any>
  children?: MCDC.Props.IDefault['children']
  sx?: MCDC.Props.IDefault['sx']
}

export default function Headline({
  children,
  id,
  variant = 'h1',
  component = 'p',
  isHtml,
  sx,
}: HeadlineProps): ReactElement {
  return (
    <TextBase
      id={id}
      component={component}
      variant={variant}
      sx={sx}
      isHtml={isHtml}
    >
      {children}
    </TextBase>
  )
}
