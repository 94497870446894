import React, { ElementType, ReactElement } from 'react'

import Typography from '@mui/material/Typography'

export type TextBaseProps = {
  id?: string
  isHtml?: boolean
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body1' | 'small' | 'inherit'
  component?: ElementType<any>
  children?: MCDC.Props.IDefault['children']
  sx?: MCDC.Props.IDefault['sx']
}

export default function TextBase({
  children,
  isHtml,
  ...props
}: TextBaseProps): ReactElement {
  if (isHtml) {
    return (
      <Typography
        {...props}
        gutterBottom={false}
        dangerouslySetInnerHTML={{
          __html: children as string,
        }}
      />
    )
  }

  return (
    <Typography {...props} gutterBottom={false}>
      {children}
    </Typography>
  )
}
