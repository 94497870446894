import { Components, Theme } from '@mui/material'

export const SnackbarOverrides = (theme: Theme): Components['MuiSnackbar'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {},
    anchorOriginBottomRight: {
      left: theme.spacing(4),
      right: theme.spacing(4),
      bottom: theme.spacing(4),
    },
  },
})
