import Page from './src/components/site/container/Page'
import { pageTransition } from './src/utils/motion'

let timeoutId: NodeJS.Timeout
export const wrapPageElement = Page
export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}: {
  prevRouterProps: any
  routerProps: any
  getSavedScrollPosition: any
}) => {
  const TRANSITION_DELAY = pageTransition.exit?.transition?.duration * 1000
  if (prevRouterProps?.location.pathname === routerProps.location.pathname)
    return false

  if (!prevRouterProps) {
    window.scrollTo([0, 0] as ScrollToOptions)
  } else {
    const lastPosition = getSavedScrollPosition(prevRouterProps.location) || [
      0, 0,
    ]
    const nextPosition = getSavedScrollPosition(routerProps.location) || [0, 0]
    clearTimeout(timeoutId)
    window.scrollTo(...lastPosition)
    timeoutId = setTimeout(
      () => window.scrollTo(...nextPosition),
      TRANSITION_DELAY
    )
  }

  return false
}
