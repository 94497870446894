import React, { ReactElement } from 'react'
import { Breakpoint } from '@mui/material'

import Section from '@components/core/container/Section'
import Slider from '@components/core/container/Slider'
import Testimonial from '@components/modules/Testimonials/components/Testimonial'

export type TestimonialsProps = {
  items: MCDC.Props.IGlobalTestimonial[]
  maxWidth?: Breakpoint
  sx?: MCDC.Props.IDefault['sx']
}

export default function Testimonials({
  items,
  maxWidth,
  sx,
}: TestimonialsProps): ReactElement {
  return (
    <Section sx={[sx]} maxWidth={maxWidth}>
      {items.length > 1 && (
        <Slider variant="custom" arrows="none" isLoop isAutoHeight>
          {items.map((item, index) => (
            <Testimonial
              author={item.author}
              copy={item.copy}
              image={item.image}
              key={`${item.author}-${index}`}
              sx={{ backgroundColor: 'background.default' }}
            />
          ))}
        </Slider>
      )}
      {items.length === 1 && (
        <Testimonial
          author={items[0].author}
          copy={items[0].copy}
          image={items[0].image}
        />
      )}
    </Section>
  )
}
