import muiTheme from '@config/theme'
import React, { useEffect, useState } from 'react'
import { alpha, Box, IconButton, Theme } from '@mui/material'
import Icon from '@components/core/media/Icon'

function getThemeStyles(muiTheme: Theme, theme?: MCDC.Props.ThemeType) {
  switch (theme) {
    case 'light':
    case 'medium':
      return {
        color: muiTheme.palette.text.inverted,
        backgroundColor: alpha(
          muiTheme.palette.background.primary as string,
          0.5
        ),
      }
    case 'dark':
    case 'primary':
    case 'secondary':
      return {
        color: muiTheme.palette.text.primary,
        backgroundColor: muiTheme.palette.common.white,
      }
    default:
      return {}
  }
}

export type SliderUiProps = {
  theme?: MCDC.Props.ThemeType
  sx?: MCDC.Props.IDefault['sx']
  onClickLeft?: () => void
  onClickRight?: () => void
  totalSlides?: number | undefined
  filterChanged?: boolean
  resetFilterChanged?: () => void
  isMobile?: boolean
}

export const SliderArrowsAmbassadorFaqs = ({
  sx,
  totalSlides,
  theme,
  onClickLeft,
  onClickRight,
  filterChanged,
  resetFilterChanged,
  isMobile,
}: SliderUiProps) => {
  const themeStyles = getThemeStyles(muiTheme, theme)
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    if (filterChanged) {
      setCurrentSlide(0)
      resetFilterChanged && resetFilterChanged()
    }
  }, [filterChanged])

  const handleLeftClick = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1)
      if (onClickLeft) {
        onClickRight && onClickLeft()
      }
    }
  }

  const handleRightClick = () => {
    if (totalSlides !== undefined && currentSlide < totalSlides - 1) {
      setCurrentSlide(currentSlide + 1)
      if (onClickRight) {
        onClickRight()
      }
    }
  }

  let isDisabled
  if (isMobile && totalSlides) {
    isDisabled = currentSlide >= totalSlides - 1
  } else {
    if (totalSlides && totalSlides > 4) {
      isDisabled = currentSlide >= totalSlides - 4
    } else if (totalSlides === 4) {
      isDisabled = currentSlide >= totalSlides - 3
    } else if (totalSlides === 3) {
      isDisabled = currentSlide >= totalSlides - 2
    }
  }

  return (
    <Box
      sx={[
        {
          display: 'flex',
          position: 'absolute',
          direction: 'rtl',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 100,
          cursor: 'pointer',
          '.MuiIconButton-root': {
            color: themeStyles.color || 'text.inverted',
            border: 'none',
            backgroundColor: themeStyles.backgroundColor
              ? themeStyles.backgroundColor
              : undefined,
          },
          '.MuiIconButton-root:hover': {
            color: themeStyles.color || 'text.inverted',
            backgroundColor: themeStyles.backgroundColor
              ? alpha(themeStyles.backgroundColor as string, 0.5)
              : undefined,
          },
        },
        sx,
      ]}
    >
      <IconButton
        onClick={handleRightClick}
        disabled={totalSlides === undefined || isDisabled}
        sx={{
          pointerEvents: 'auto',
          '&.Mui-disabled': {
            backgroundColor: themeStyles.backgroundColor
              ? alpha(themeStyles.backgroundColor as string, 0.5)
              : undefined,
          },
        }}
      >
        <Icon variant="chevron_right" />
      </IconButton>
      <IconButton
        onClick={handleLeftClick}
        disabled={currentSlide === 0}
        sx={{
          pointerEvents: 'auto',
          '&.Mui-disabled': {
            backgroundColor: themeStyles.backgroundColor
              ? alpha(themeStyles.backgroundColor as string, 0.5)
              : undefined,
          },
        }}
      >
        <Icon variant="chevron_left" />
      </IconButton>
    </Box>
  )
}
