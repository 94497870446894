import React, { ReactElement, useEffect, useState } from 'react'
import { AppBar, Toolbar } from '@mui/material'
import { HistoryLocation } from '@gatsbyjs/reach-router'

import Navigation from '@components/modules/Navigation'
import { motion, useScroll } from 'framer-motion'
import { appBarMotionProps } from '@utils/motion'
import NavigationDrawer from '@components/modules/Navigation/components/NavigationDrawer'
import useGlobalState from '@components/site/hooks/useGlobalState'

export type HeaderProps = MCDC.Props.IDefault & {
  navItems: MCDC.Props.ILinkTo[]
  location: HistoryLocation
}

export default function Header({
  navItems,
  location,
}: HeaderProps): ReactElement {
  const { activeDrawer } = useGlobalState()
  const [appAniVariant, setAppAniVariant] = useState<'hide' | 'show'>('show')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const scroll = useScroll()

  useEffect(() => {
    let prevScrollY = scroll.scrollY.get()
    scroll.scrollY.onChange((v) => {
      let variant: 'hide' | 'show' = 'show'
      const scrollY = scroll.scrollY.get()
      if (scrollY > 80 && scrollY > prevScrollY) {
        variant = 'hide'
      }
      prevScrollY = scroll.scrollY.get()
      if (appAniVariant !== variant) {
        setAppAniVariant(variant)
      }
    })

    return () => scroll.scrollY.clearListeners()
  }, [scroll, appAniVariant])

  const itemHome = navItems[0]
  const itemAccount = navItems[navItems.length - 1]
  const items = navItems.slice(1, navItems.length - 1)
  const activeNavItemIndex = items.findIndex(
    (entry) =>
      (entry.url !== '/' && location?.pathname.includes(entry.url)) ||
      location?.pathname === entry.url
  )

  return (
    <AppBar
      component={motion.header}
      animate={appAniVariant}
      {...appBarMotionProps}
      sx={{ position: 'fixed' }}
    >
      <Toolbar sx={{ height: 'inherit', px: '0 !important' }}>
        <Navigation
          itemHome={itemHome}
          itemAccount={itemAccount}
          items={items}
          activeIndex={activeNavItemIndex}
          onToggle={() =>
            activeDrawer
              ? activeDrawer.onClose()
              : setIsDrawerOpen(!isDrawerOpen)
          }
          isOpen={isDrawerOpen || !!activeDrawer}
        />
        <NavigationDrawer
          items={items}
          itemAccount={itemAccount}
          activeIndex={activeNavItemIndex}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          sx={{ mt: 16 }}
        />
      </Toolbar>
    </AppBar>
  )
}
