import { TypographyOptions } from '@mui/material/styles/createTypography'
import { Theme } from '@mui/material'
import FontSize from '../definitions/fontSize'
import { fontFamily } from '../definitions/fontFamily'

/**
 * Set which custom type can be used as a variant prop
 * See: More Variants in ../type/createTypography
 * */

export const TypographyOverrides = (theme: Theme): TypographyOptions => {
  return {
    // ...theme.typography,
    fontFamily: fontFamily,
    body1: {
      fontFamily: fontFamily,
      fontSize: FontSize.get('base'),
      lineHeight: 1.75,
    },
    small: {
      fontSize: FontSize.get('small'),
      fontWeight: 400,
      letterSpacing: 0.3,
      lineHeight: '18px',
    },
    h1: {
      fontSize: FontSize.get('h1-sm'),
      lineHeight: 1,
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('h1-lg'),
        lineHeight: 1,
      },
    },
    h2: {
      fontSize: FontSize.get('h2-sm'),
      lineHeight: 1,
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('h2-lg'),
        lineHeight: 1,
      },
    },
    h3: {
      fontSize: FontSize.get('h3-sm'),
      lineHeight: 1.125,
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('h3-lg'),
        lineHeight: 1,
      },
    },
    h4: {
      fontSize: FontSize.get('h4-sm'),
      lineHeight: 1.5,
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('h4-lg'),
        lineHeight: 1.428,
      },
    },
    h5: {
      fontSize: FontSize.get('h5-sm'),
      lineHeight: 1.4,
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('h5-lg'),
        lineHeight: 1.17,
      },
    },
    button: {
      fontSize: FontSize.get('base'),
      lineHeight: 1.1,
      fontWeight: 700,
    },
    navigation: {
      fontSize: FontSize.get('base'),
      lineHeight: 1.1,
      fontWeight: 700,
    },
  }
}
