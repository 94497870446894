import React, { ReactElement } from 'react'

import { Box, Drawer } from '@mui/material'

import NavigationItem from '../NavigationItem'
import Icon from '@components/core/media/Icon'

export type NavigationDrawerProps = {
  items: MCDC.Props.ILinkTo[]
  itemAccount: MCDC.Props.ILinkTo
  activeIndex: number
  onClose: () => void
  isOpen: boolean
  sx?: MCDC.Props.IDefault['sx']
}

export default function NavigationDrawer({
  items,
  itemAccount,
  activeIndex,
  onClose,
  isOpen,
  sx = {},
}: NavigationDrawerProps): ReactElement {
  return (
    <Drawer
      elevation={0}
      anchor="right"
      variant="temporary"
      open={isOpen}
      onClose={onClose}
      transitionDuration={500}
      PaperProps={{
        sx: [
          {
            width: '100vw',
            display: 'flex',
            height: 'calc(100% - 64px)',
          },
          sx,
        ],
      }}
      sx={sx}
    >
      <Box
        component="ul"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          p: 0,
          '> * + *': { mt: 6 },
          my: 0,
        }}
      >
        {items.map((entry, index) => (
          <NavigationItem
            to={entry.url}
            isExternal={entry.isExternal}
            count={entry.count}
            onClick={onClose}
            isSelected={activeIndex === index}
            key={index}
          >
            {entry.label}
          </NavigationItem>
        ))}
        <NavigationItem
          to={itemAccount.url}
          isExternal={itemAccount.isExternal}
          count={itemAccount.count}
          onClick={onClose}
          sx={{
            borderTop: '1px solid #d2d2d2',
            mt: 2,
            pt: 6,
          }}
        >
          <Icon variant="favorite" sx={{ color: 'inherit', mr: 3 }} />
          {itemAccount.label}
        </NavigationItem>
      </Box>
    </Drawer>
  )
}
