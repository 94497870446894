import React, { ReactElement } from 'react'
import Section from '@components/core/container/Section'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material'

import Headline from '@components/core/text/Headline'
import Copy from '@components/core/text/Copy'
import StarRatingSvg from '@static/images/icons/rating.svg'
import StarRatingFilledSvg from '@static/images/icons/rating-filled.svg'
import KununuImg from '@static/images/kununu2x.png'

export type ReviewDetailsProps = {
  headline: string
  copy: MCDC.Props.RichText
  options?: MCDC.Props.IOption[]
  sx?: MCDC.Props.IDefault['sx']
}

export default function ReviewDetails({
  headline,
  copy,
  options,
}: ReviewDetailsProps): ReactElement {
  return (
    <Section theme="light" paddingSize="small" maxWidth="sm">
      <Box component="img" src={KununuImg} sx={{ width: '200px', mb: 6 }} />
      <Headline variant="h3" component="div" isHtml>
        {headline}
      </Headline>
      <Box
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            display: 'flex',
            maxWidth: '744px',
            mt: 8,
          },
        })}
      >
        <Copy
          variant="body1"
          component="div"
          sx={(theme: Theme) => ({
            mb: 4,
            [theme.breakpoints.up('lg')]: {
              width: '50%',
              mr: 15,
            },
          })}
        >
          {copy}
        </Copy>
        <Box
          sx={(theme: Theme) => ({
            [theme.breakpoints.up('lg')]: {
              width: '50%',
            },
          })}
        >
          {options?.map((option, index) => {
            const rating =
              parseFloat(String(option.value).replace(',', '.')) / 5
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 3,
                  fontWeight: 700,
                }}
              >
                <Copy sx={{ width: '50%' }}>{option.label}</Copy>
                <Copy sx={{ width: '10%', textAlign: 'center' }}>
                  {option.value}
                </Copy>
                <Box
                  sx={{
                    position: 'relative',
                    m: 'auto',
                    mr: 0,
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: `${rating * 100 + rating * 3}%`,
                      height: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      component={StarRatingFilledSvg}
                      sx={{
                        display: 'block',
                      }}
                    />
                  </Box>
                  <Box
                    component={StarRatingSvg}
                    sx={{
                      display: 'block',
                    }}
                  />
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Section>
  )
}
