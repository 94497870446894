import React, { ReactElement } from 'react'
import { Box, Breakpoint, Theme } from '@mui/material'

import Section from '@components/core/container/Section'
import Ambassador from './components/AmbassadorCard/'
import theme from "@config/theme";

export type AmbassadorContainerProps = {
  ambassadors: MCDC.Props.IModuleAmbassador[]
  maxWidth?: Breakpoint
  sx?: MCDC.Props.IDefault['sx']
}

export default function AmbassadorContainer({
     ambassadors = [],
     maxWidth,
     sx,
   }: AmbassadorContainerProps): ReactElement {
  return (
    <Section theme={theme} maxWidth={maxWidth} paddingSize="small">
      <Box sx={(theme: Theme) => ({
        display: 'flex',
        flexFlow: 'row wrap',
        '> *': {
          mb: 20,
        },
        [theme.breakpoints.down('md')]: {
          '> *': {
            mb: 10,
          }
        },
      })}
      >
        {ambassadors.map((item, index) => (
          <Ambassador {...item} sx={{ mx: 'auto' }} key={index} noWrapper />
        ))}
      </Box>
    </Section>
  )
}
