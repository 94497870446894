import React, { ReactElement } from 'react'

import { Box, Theme } from '@mui/material'
import TextBase from '@components/core/text/TextBase'
import Image from '@components/core/media/Image'
import Headline from '@components/core/text/Headline'

export type TestimonialProps = {
  author: string
  copy: MCDC.Props.RichText
  image: MCDC.Props.IAssetImage
  sx?: MCDC.Props.IDefault['sx']
}

export default function Testimonial({
  author,
  copy,
  image,
  sx,
}: TestimonialProps): ReactElement {
  return (
    <Box
      sx={[
        sx,
        (theme: Theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(24, 1fr )',
          },
        }),
      ]}
    >
      <Image
        {...image}
        sx={(theme: Theme) => ({
          [theme.breakpoints.up('md')]: {
            gridColumnStart: '1',
            gridColumnEnd: '13',
            gridRow: '1',
          },
        })}
      />
      <Box
        sx={(theme: Theme) => ({
          position: 'relative',
          marginTop: '-128px',
          marginLeft: 11,
          paddingTop: 12,
          paddingBottom: 12,
          paddingRight: 6,
          paddingLeft: 6,
          backgroundColor: 'secondary.dark',
          color: 'secondary.contrastText',
          // zIndex Hack: Image has Transform
          // TODO: Find a proper Solution
          // https://www.freecodecamp.org/news/4-reasons-your-z-index-isnt-working-and-how-to-fix-it-coder-coder-6bc05f103e6c/
          transform: 'translateZ(0)',
          zIndex: 1,
          [theme.breakpoints.up('md')]: {
            gridRow: '1',
            gridColumnStart: '10',
            gridColumnEnd: '25',
            my: 'auto',
          },
        })}
      >
        <Headline
          variant="h5"
          component="div"
          sx={(theme: Theme) => ({
            fontWeight: 700,
            display: 'inline-block',
            mb: 4,
            [theme.breakpoints.up('md')]: {},
            '> *': {
              display: 'inline',
            },
          })}
        >
          <span>„</span>
          {copy}
          <span>“</span>
        </Headline>
        <TextBase
          component="p"
          variant="body1"
          sx={(theme: Theme) => ({
            [theme.breakpoints.up('md')]: {},
          })}
        >
          {author}
        </TextBase>
      </Box>
    </Box>
  )
}
