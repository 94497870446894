import React, { ReactElement, useState } from 'react'

import HeroDropDown from '../HeroDropDown'
import Accordions from '../Accordions'
import { Theme } from '@mui/material'
import { cardTransition, StyledMotionDiv } from '@utils/motion'
import { AnimatePresence } from 'framer-motion'

export type FaqsProps = {
  headline: string
  copy: MCDC.Props.RichText
  options: MCDC.Props.IOption[]
  items: MCDC.Props.IGlobalFaq[]
  image: MCDC.Props.IAssetImage
  sx?: MCDC.Props.IDefault['sx']
}

export default function Faqs({
  headline,
  copy,
  image,
  options,
  items,
  sx,
}: FaqsProps): ReactElement {
  const [selectedOption, setSelectedOption] = useState<MCDC.Props.IOption>()
  const selectedOptions = selectedOption ? [selectedOption] : options
  return (
    <div>
      <HeroDropDown
        headline={headline}
        copy={copy}
        image={image}
        options={options}
        value={selectedOption || null}
        onChange={(e) => {
          selectedOption?.value === e.target.value
            ? setSelectedOption(undefined)
            : setSelectedOption(
                options.find((entry) => entry.value === e.target.value)
              )
        }}
      />
      <AnimatePresence exitBeforeEnter>
        {selectedOptions.map((entry) => (
          <StyledMotionDiv
            {...cardTransition}
            key={entry.value}
            sx={(theme: Theme) => ({
              mt: 16,
              [theme.breakpoints.up('lg')]: { mt: 20 },
            })}
          >
            <Accordions
              headline={entry.label}
              headlineSize="h4"
              items={items
                .filter((item) =>
                  item.topics.find((topic) => topic.identifier === entry.value)
                )
                .map((entry) => ({
                  title: entry.question,
                  content: entry.answer,
                }))}
            />
          </StyledMotionDiv>
        ))}
      </AnimatePresence>
    </div>
  )
}
