import React, { ReactElement } from 'react'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material'

import Section from '@components/core/container/Section'
import Headline from '@components/core/text/Headline'
import Image from '@components/core/media/Image'
import Copy from '@components/core/text/Copy'
import Button from '@components/core/input/Button'
import StickyActions from '@components/site/container/StickyActions'
import PageFooter from '@components/modules/PageFooter'

export type TeaserJobsProps = {
  headline: string
  subline?: string
  copy?: MCDC.Props.RichText
  image: MCDC.Props.IAssetImage
  linkTo: MCDC.Props.ILinkTo
  variant?: 'full' | 'minimal'
  stickyCallToAction?: boolean | null
  sx?: MCDC.Props.IDefault['sx']
}

export default function TeaserJobs({
  headline,
  subline,
  copy,
  image,
  linkTo,
  variant,
  stickyCallToAction,
  sx,
}: TeaserJobsProps): ReactElement {
  return (
    <Section maxWidth="sm" sx={sx}>
      {stickyCallToAction && (
        <StickyActions>
          <PageFooter linkTo={linkTo} />
        </StickyActions>
      )}
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'common.white',
          borderRadius: '8px',
          overflow: 'hidden',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
          [theme.breakpoints.up('lg')]: {
            mx: theme.spacing(-theme.gutter('lg')),
          },
        })}
      >
        <Box
          sx={(theme: Theme) => ({
            display: 'flex',
            flexDirection: 'column',
            py: theme.padding('small', 'sm'),
            px: theme.gutter('sm'),
            [theme.breakpoints.up('md')]: {
              justifyContent: 'center',
              width: '50%',
            },
            [theme.breakpoints.up('lg')]: {
              py: theme.padding('small', 'md'),
              px: theme.gutter('lg'),
            },
          })}
        >
          <Headline variant="h3">{headline}</Headline>
          {subline && <Copy>{subline}</Copy>}
          {copy && (
            <Copy component="div" sx={{ mt: 4 }}>
              {copy}
            </Copy>
          )}
          <Button
            to={linkTo.url}
            isExternal={linkTo.isExternal}
            color="primary"
            variant="rounded"
            sx={(theme: Theme) => ({
              alignSelf: 'flex-start',
              mt: 4,
            })}
          >
            {linkTo.label}
          </Button>
        </Box>
        <Box
          sx={(theme: Theme) => ({
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: variant === 'full' ? '40%' : '50%',
              ml: 'auto',
            },
          })}
        >
          <Image
            {...image}
            sx={{ height: '100%' }}
            objectFit="cover"
            objectPosition="center"
          />
        </Box>
      </Box>
    </Section>
  )
}
