import React, { ReactElement } from 'react'
import Box from '@mui/material/Box'
import { Container } from '@mui/material'

import theme from '@config/theme'
import Headline from '@components/core/text/Headline'

import Image from '@components/core/media/Image'
import QuoteSvg from '@static/images/icons/format_quote.svg'
import BackgroundImage from '@static/images/bg-pattern.png'

export type ReviewsProps = {
  items: MCDC.Props.IGlobalReview[]
  sx?: MCDC.Props.IDefault['sx']
}

export default function Reviews({
  items = [],
  sx = {},
}: ReviewsProps): ReactElement {
  const { copy, image } = items[0]
  function renderAsset() {
    if (!image) return null
    if (image?.file.contentType === 'image/svg+xml') {
      const svg = image as any as MCDC.Props.IAssetSvg
      return (
        <Box
          sx={{
            width: '148px',
            lineHeight: 1,
          }}
          dangerouslySetInnerHTML={{
            __html: svg?.content,
          }}
        />
      )
    }

    return (
      <Image
        {...(image as MCDC.Props.IAssetImage)}
        sx={{ maxWidth: '148px' }}
      />
    )
  }

  return (
    <Box
      sx={[
        sx,
        {
          position: 'relative',
          overflow: 'hidden',
          // minHeight: '640px',
          backgroundColor: 'common.white',
          backgroundImage: `url("${BackgroundImage}")`,
          backgroundSize: '160px',
          backgroundRepeat: 'repeat',
          backgroundPosition: 'center',
          p: 6,

          [theme.breakpoints.up('lg')]: {
            minHeight: '354px',
            backgroundSize: '230px',
            px: 8,
            py: 10,
          },
        },
      ]}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: 'common.white',
          p: 10,
          borderRadius: '8px',
        }}
      >
        <QuoteSvg />
        <Headline
          variant="h4"
          component="div"
          sx={{
            mt: 3,
            mb: 8,
            position: 'relative',
            maxWidth: '800px',
            zIndex: 1,
          }}
        >
          {copy}
        </Headline>
        {renderAsset()}
      </Container>
    </Box>
  )
}
