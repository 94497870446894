import { Components, Theme } from '@mui/material'

import { speedeeRegular, speedeeBold } from '../../definitions/fontFamily'

export const CssBaselineOverrides = (
  theme: Theme
): Components['MuiCssBaseline'] => ({
  styleOverrides: {
    '@font-face': speedeeRegular,
    fallbacks: [
      {
        '@font-face': speedeeBold,
      },
    ],
    a: {
      color: 'currentColor',
      fontWeight: 400,
    },
    strong: {
      fontWeight: 700,
    },
    html: {
      scrollBehaviour: 'smooth',
    },
    'body.Mui-noScroll': {
      height: '100%',
      overflow: 'hidden',
    },
    '#web-messenger-container': {
      opacity: 0,
      pointerEvents: 'none',
    },
    'body.is-jobpalpage': {
      '#web-messenger-container': {
        opacity: 1,
        pointerEvents: 'auto',
      },
    },
  },
})
