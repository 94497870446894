import { Components, Theme } from '@mui/material'

export const AccordionOverrides = (
  theme: Theme
): Components['MuiAccordion'] => ({
  defaultProps: {
    square: false,
    disableGutters: true,
  },
  styleOverrides: {
    root: {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.primary.contrastText,
      // '&.Mui-expanded': {
      //   marginTop: 0,
      //   marginBottom: 0,
      //   '&:last-child': {
      //     marginBottom: `${theme.spacing(6)}`,
      //   },
      // },
      '&:not(:last-child)': {
        marginBottom: `${theme.spacing(2)}`,
      },
      '&:before': {
        display: 'none',
      },
    },
    rounded: {
      borderRadius: '8px',
    },
  },
})

export const AccordionSummaryOverrides = (
  theme: Theme
): Components['MuiAccordionSummary'] => ({
  styleOverrides: {
    root: {
      padding: 0,
      minHeight: 0,
      borderBottom: '1px solid transparent',
      transition: 'border 250ms ease',
      '&.Mui-expanded': {
        borderColor: theme.palette.grey[200],
      },
    },
    content: {
      padding: theme.spacing(4, 0, 4, 4),
      margin: 0,
    },

    expandIconWrapper: {
      padding: theme.spacing(4),
      svg: {
        fill: 'currentColor',
      },
    },
  },
})

export const AccordionDetailsOverrides = (
  theme: Theme
): Components['MuiAccordionDetails'] => ({
  styleOverrides: {
    root: {
      padding: theme.spacing(4),
    },
  },
})
