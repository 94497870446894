import React, { ReactElement } from 'react'
import Section from '@components/core/container/Section'
import Typography from '@mui/material/Typography'
import { Box, Breakpoint, Theme } from '@mui/material'

import Headline, { HeadlineProps } from '@components/core/text/Headline'
import Accordion from '@components/core/container/Accordion'
import useAccordionControls from '../../site/hooks/useAccordionControls'
import Button from '@components/core/input/Button'
import Copy from '@components/core/text/Copy'

export type AccodionProps = {
  title: string
  content: MCDC.Props.IDefault['children']
}

export type AccordionsProps = {
  theme?: MCDC.Props.ThemeType
  variant?: 'default' | 'footerInfos'
  headline: string
  subline?: string
  copy?: MCDC.Props.RichText
  linkTo?: MCDC.Props.ILinkTo
  items: AccodionProps[]
  sx?: MCDC.Props.IDefault['sx']
  maxWidth?: Breakpoint
  headlineSize?: HeadlineProps['variant']
}

export default function Accordions({
  theme,
  variant = 'default',
  headline,
  subline,
  copy,
  items,
  linkTo,
  maxWidth,
  headlineSize = 'h3',
  sx,
}: AccordionsProps): ReactElement {
  const [activeAccordionId, handleAccordionChange] = useAccordionControls(false)

  function renderText() {
    return (
      <>
        {subline && (
          <Headline variant="h5" sx={{ mt: variant === 'footerInfos' ? 6 : 2 }}>
            {subline}
          </Headline>
        )}
        {copy && (
          <Copy sx={{ mt: 2 }} component="div">
            {copy}
          </Copy>
        )}
      </>
    )
  }

  return (
    <Section
      theme={theme}
      maxWidth={maxWidth || 'sm'}
      sx={sx}
      paddingSize="default"
    >
      <Headline variant={headlineSize}>{headline}</Headline>
      {variant === 'default' && renderText()}
      <Box sx={{ mt: 8 }}>
        {items.map((entry, index) => (
          <Accordion
            title={entry.title}
            key={index}
            onChange={(event, isExpanded) =>
              handleAccordionChange(index, isExpanded)
            }
            expanded={activeAccordionId === index}
          >
            <Typography component="div">{entry.content}</Typography>
          </Accordion>
        ))}
      </Box>
      {variant === 'footerInfos' && renderText()}
      {linkTo && (
        <Button
          to={linkTo.url}
          isExternal={linkTo.isExternal}
          color="primary"
          variant="rounded"
          sx={(theme: Theme) => ({
            mt: 6,
            [theme.breakpoints.up('md')]: { alignSelf: 'center' },
          })}
        >
          {linkTo.label}
        </Button>
      )}
    </Section>
  )
}
