import React, { ReactElement } from 'react'
import { Box, Breakpoint, Theme } from '@mui/material'

import Section from '@components/core/container/Section'
import Slider from '@components/core/container/Slider'
import DeepLinkCard from './components/DeepLinkCard'
import Headline from '@components/core/text/Headline'

export type DeepLinksProps = {
  headline: string
  items: MCDC.Props.IGlobalDeepLink[]
  maxWidth?: Breakpoint
  sx?: MCDC.Props.IDefault['sx']
}

export default function DeepLinks({
  headline,
  items = [],
  maxWidth,
  sx,
}: DeepLinksProps): ReactElement {
  return (
    <Section
      theme="primary"
      sx={[sx]}
      maxWidth={maxWidth}
      paddingSize="small"
      withDrip
    >
      <Headline variant="h3" sx={{ mb: 9, textAlign: 'center' }}>
        {headline}
      </Headline>
      <Box sx={{ position: 'relative' }}>
        <Slider
          slidesPerView={1.05}
          theme="primary"
          arrows="overlay"
          withOverflow={{ sm: true, md: false, lg: false }}
          breakpoints={{
            md: { slidesPerView: 2 },
            lg: { slidesPerView: 3 },
          }}
          sx={(theme: Theme) => ({
            [theme.breakpoints.up('md')]:
              items.length > 2 ? { px: 16 } : undefined,
            [theme.breakpoints.up('lg')]:
              items.length > 3 ? { px: 16 } : { px: 0 },
          })}
        >
          {items.map((item, index) => (
            <DeepLinkCard {...item} key={index} />
          ))}
        </Slider>
      </Box>
    </Section>
  )
}
