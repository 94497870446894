import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Box } from '@mui/system'
import { AnimatePresence } from 'framer-motion'
import { stickyActionsTransition, StyledMotionDiv } from '@utils/motion'
import usePortalTarget from '@components/site/hooks/usePortal'
import { createPortal } from 'react-dom'

export type StickyActionsProps = {
  children: MCDC.Props.IDefault['children']
}

export default function StickyActions({
  children,
}: StickyActionsProps): ReactElement | null {
  const containerRef = useRef<HTMLDivElement>()
  const { portalRef, location } = usePortalTarget()
  const [isInView, setIsInView] = useState(false)
  const [isFullyScrolled, setIsFullyScrolled] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const [stickyLocationPathname] = useState(location?.pathname)

  useEffect(() => {
    let isSubscribed = true
    if (!containerRef.current) return
    const observer = new IntersectionObserver(
      (entries: any) => {
        const [entry] = entries
        if (isSubscribed) {
          setIsInView(entry.isIntersecting)
        }
      },
      {
        rootMargin: '0px',
        threshold: 0,
      }
    )
    if (containerRef.current) observer.observe(containerRef.current)
    return () => {
      isSubscribed = false
      if (containerRef.current) observer.unobserve(containerRef.current)
    }
  }, [containerRef])

  useEffect(() => {
    function onScroll() {
      const isFullyScrolledTmp =
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.documentElement.scrollHeight

      if (isFullyScrolledTmp !== isFullyScrolled) {
        setIsFullyScrolled(isFullyScrolledTmp)
      }
    }

    if (!!children) {
      window.addEventListener('scroll', onScroll, true)
    }

    return () => {
      window.removeEventListener('scroll', onScroll, true)
    }
  }, [isFullyScrolled])

  useEffect(() => {
    if ((isInView || isFullyScrolled) && isSticky) {
      setIsSticky(false)
    } else if (!isInView && !isSticky) {
      setIsSticky(true)
    }
  }, [isInView, isFullyScrolled])

  return (
    <Box
      className="Mui-themed"
      sx={{
        height: '1px',
        mt: '-1px',
      }}
      ref={containerRef}
    >
      {portalRef?.current &&
        createPortal(
          <AnimatePresence exitBeforeEnter>
            {location?.pathname === stickyLocationPathname && (
              <StyledMotionDiv
                animate={isSticky ? 'show' : 'hide'}
                {...stickyActionsTransition}
                sx={{ pointerEvents: 'auto' }}
              >
                {children}
              </StyledMotionDiv>
            )}
          </AnimatePresence>,
          portalRef.current,
          'stickyContent'
        )}
    </Box>
  )
}
