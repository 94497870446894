/* eslint-disable @typescript-eslint/no-explicit-any */

import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'

const useContentfulPageJobOffers = (): [
  MCDC.Contentful.IPageGeneric,
  MCDC.Contentful.IPageGeneric[]
] => {
  const { locale } = useIntl()
  const { data } = useStaticQuery(
    graphql`
      query {
        data: allContentfulPageGeneric(
          filter: { metaType: { glob: "offers*" } }
        ) {
          nodes {
            node_locale
            metaType
            fields {
              linkTo {
                url
              }
            }
          }
        }
      }
    `
  )

  return [
    data.nodes?.find(
      (entry: MCDC.Contentful.IPageGeneric) =>
        entry.node_locale === locale && entry.metaType === 'offers'
    ) || [],
    data.nodes?.filter(
      (entry: MCDC.Contentful.IPageGeneric) => entry.node_locale === locale
    ) || [],
  ]
}
export default useContentfulPageJobOffers
