/* eslint-disable @typescript-eslint/no-unused-vars */
import { kPageMetaTypeFilterArea } from '@utils/constants'
import React, { ReactNode, ReactElement, useState } from 'react'

const getAreaRegEx = (areaId?: MCDC.API.SearchFilterArea) => {
  switch (areaId) {
    case kPageMetaTypeFilterArea.offersRestaurant:
      return /^\d+(_)\d+$/g
    case kPageMetaTypeFilterArea.offersManagement:
      return /^\d+(_)\d+$/g
    default:
      return
  }
}
export interface IStaticContentfulContext {
  pageJobOffers?: MCDC.Contentful.IPageGeneric
  pagesJobOffers: MCDC.Contentful.IPageGeneric[]
  meta?: MCDC.API.IMeta
  getPageJobDetail: (id: string) => MCDC.Contentful.IPageJobDetail | undefined
  getPageJobProfile: (id: string) => MCDC.Contentful.IPageProfile | undefined
  getJobProfilePages: (
    id: MCDC.API.SearchFilterArea
  ) => MCDC.Contentful.IPageProfile[]
  getGlobalFaqs: (topics: MCDC.Props.IGlobalTopic[]) => MCDC.Props.IGlobalFaq[]
}

export const StaticContentfulContext =
  React.createContext<IStaticContentfulContext>({
    pageJobOffers: undefined,
    pagesJobOffers: [],
    getPageJobDetail: () => undefined,
    getPageJobProfile: () => undefined,
    getJobProfilePages: () => [],
    getGlobalFaqs: () => [],
    meta: undefined,
  })

export type StaticContentfulProviderProps = {
  children: ReactNode
  pagesJobDetail: MCDC.Contentful.IPageJobDetail[]
  pagesJobProfile: MCDC.Contentful.IPageProfile[]
  pagesJobOffers?: MCDC.Contentful.IPageGeneric[]
  globalFaqs?: MCDC.Props.IGlobalFaq[]
  meta?: MCDC.API.IMeta
}

export default function StaticContentfulProvider({
  children,
  pagesJobDetail,
  pagesJobProfile,
  pagesJobOffers = [],
  globalFaqs = [],
  meta,
}: StaticContentfulProviderProps): ReactElement {
  const [pageJobOffers] = useState(
    pagesJobOffers.find((entry) => entry.metaType === 'offers')
  )

  function getPageJobDetail(id: string) {
    return pagesJobDetail.find((entry) => entry.identifier === id)
  }

  function getPageJobProfile(id?: string) {
    return pagesJobProfile.find((entry) => entry.identifier === String(id))
  }

  function getJobProfilePages(areaId?: MCDC.API.SearchFilterArea) {
    const areaRegEx = getAreaRegEx(areaId)
    return areaId
      ? pagesJobProfile.filter(
          (entry: MCDC.Contentful.IPageProfile) =>
            !areaRegEx || entry.identifier.match(areaRegEx)
        )
      : pagesJobProfile
  }

  function getGlobalFaqs(topics: MCDC.Props.IGlobalTopic[] = []) {
    return globalFaqs.filter((entry: MCDC.Props.IGlobalFaq) =>
      entry.topics.some((entryTopic) =>
        topics.find((topic) => topic.identifier === entryTopic.identifier)
      )
    )
  }

  return (
    <StaticContentfulContext.Provider
      value={{
        meta,
        pageJobOffers,
        pagesJobOffers,
        getPageJobDetail,
        getPageJobProfile,
        getJobProfilePages,
        getGlobalFaqs,
      }}
    >
      {children}
    </StaticContentfulContext.Provider>
  )
}
