/* eslint-disable @typescript-eslint/no-explicit-any */

import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'

const useContentfulPageJobDetail = (
  id?: string
): MCDC.Contentful.IPageJobDetail[] => {
  const { locale } = useIntl()
  const { data } = useStaticQuery(
    graphql`
      query {
        data: allContentfulPageJobDetail {
          nodes {
            node_locale
            identifier
            fields {
              linkTo {
                url
              }
            }
          }
        }
      }
    `
  )

  return data.nodes?.filter(
    (entry: MCDC.Contentful.IPageJobDetail) =>
      ((!id || entry.identifier === id) && entry.node_locale === locale) || []
  )
}
export default useContentfulPageJobDetail
