import { styled } from '@mui/material'
import { motion, TargetAndTransition } from 'framer-motion'
import { Link } from 'gatsby'

export const addDelayToWhileInView = (
  whileInViewProps: MCDC.Motion.WhileInView,
  delay: number
) => ({
  ...((whileInViewProps as TargetAndTransition) || {}),
  transition: {
    ...((whileInViewProps as TargetAndTransition)?.transition || {}),
    delay: delay,
  },
})

// -------------------------------------------------------------
// PAGE LAYOUT Motion Props
// -------------------------------------------------------------

export const pageTransition: MCDC.Motion.IProps = {
  initial: { y: -24, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 0,
    opacity: 0,
    transition: { type: 'tween', ease: 'easeIn', duration: 0.5 },
  },
  enter: {
    y: 0,
    opacity: 1,
  },
  transition: {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.6,
    delay: 0.1,
  },
}

// -------------------------------------------------------------
// NAVIGATION Motion Props
// -------------------------------------------------------------
export const appBarMotionProps: MCDC.Motion.IProps = {
  initial: { y: '-100%' },
  variants: {
    hide: {
      y: '-100%',
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.6,
      },
    },
    show: { y: 0 },
  },
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.6,
    delay: 0.15,
  },
}

// -------------------------------------------------------------
// Sticky Motion Props
// -------------------------------------------------------------

export const stickyActionsTransition: MCDC.Motion.IProps = {
  initial: { y: '100%' },
  variants: {
    show: {
      y: '0%',
    },
    hide: {
      y: '100%',
    },
  },
  exit: {
    y: '100%',
  },
  transition: {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.6,
  },
}

// -------------------------------------------------------------
// LIGHTBOX Motion Props
// -------------------------------------------------------------

export const lightboxDimmerMotionProps: MCDC.Motion.IProps = {
  initial: 'hide',
  variants: {
    show: {
      opacity: 0.5,
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: 0.25,
      },
    },
    hide: {
      opacity: 0,
      transition: {
        type: 'tween',
        ease: 'easeIn',
        duration: 0.25,
      },
    },
  },
}

export const lightboxDialogMotionProps: MCDC.Motion.IProps = {
  initial: { opacity: 0, y: 32 },
  animate: { opacity: 1, y: 0 },
  exit: {
    opacity: 0,
    y: -32,
    transition: { type: 'tween', ease: 'easeIn', duration: 0.4 },
  },
  enter: { opacity: 0, y: 32 },
  transition: {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.5,
  },
}

// -------------------------------------------------------------
// Module Motion Props
// -------------------------------------------------------------

export const cardTransition: MCDC.Motion.IProps = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: { type: 'tween', ease: 'easeIn', duration: 0.8 },
  },
  transition: {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.8,
  },
}

/*
  Prevent SX and other Props from being added as an DOM Attribute
*/

const shouldForwardProp = (prop: PropertyKey): boolean =>
  prop !== 'sx' && prop !== 'component'
// prop !== 'enter' &&
// prop !== 'initial' &&
// prop !== 'transition' &&
// prop !== 'animate' &&
// prop !== 'viewport' &&
// prop !== 'whileInView'

export const StyledMotionMain = styled(motion.main, {
  shouldForwardProp: shouldForwardProp,
})(() => ({}))

export const StyledMotionDiv = styled(motion.div, {
  shouldForwardProp: shouldForwardProp,
})(() => ({}))

export const StyledMotionSpan = styled(motion.span, {
  shouldForwardProp: shouldForwardProp,
})(() => ({}))

export const StyledGatsbyLink = styled(Link, {
  shouldForwardProp: shouldForwardProp,
})(() => ({}))

export const StyledLink = styled('a', {
  shouldForwardProp: shouldForwardProp,
})(() => ({}))
