import React, { ReactElement } from 'react'
import {
  Accordion as AccordionMUI,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'

import Icon from '@components/core/media/Icon'
import TextBase from '@components/core/text/TextBase'

export type AccordionProps = {
  /**
   * The Title shown
   * Displays Prop Tables with these components
   * @default null
   */
  title: string
  /**
   * The Icon set used by the Component
   * @default null
   */
  chevron?: 'expand_more' | 'expand_less'
  expanded?: boolean
  defaultExpanded?: boolean
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => void
  sx?: MCDC.Props.IDefault['sx']
  children: MCDC.Props.IDefault['children']
}

export default function Accordion({
  title,
  children,
  expanded,
  defaultExpanded,
  chevron,
  onChange,

  sx,
}: AccordionProps): ReactElement {
  const Chevron =
    chevron === 'expand_more' ? (
      <Icon variant="expand_less" />
    ) : (
      <Icon variant="expand_more" />
    )

  return (
    <AccordionMUI
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={onChange}
      sx={sx}
    >
      <AccordionSummary
        expandIcon={Chevron}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: 'primary.main',
          },
        }}
      >
        <TextBase component="span" sx={{ fontWeight: 700 }}>
          {title}
        </TextBase>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionMUI>
  )
}
