/* eslint-disable @typescript-eslint/no-explicit-any */
import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'

import * as api from '@utils/api'

const useApiMeta = (shouldFetch: boolean): MCDC.API.IMeta => {
  const { apiMeta } = useStaticQuery(
    graphql`
      query {
        apiMeta {
          searchFilters {
            title
            level
            id
            description
          }
          pagination {
            offset
            size
            pages
            total
          }
        }
      }
    `
  )

  const [data, setData] = useState<MCDC.API.IMeta>(apiMeta)

  useEffect(() => {
    if (!shouldFetch && data.totalJobcount) {
      return
    }
    let isSubscribed = true
    api.getMeta().then((response) => {
      if (response?.status === 200 && isSubscribed) {
        setData({
          ...data,
          totalJobcount: response.data.totalJobcount,
        })
      }
    })
    return () => {
      isSubscribed = false
    }
  }, [shouldFetch])

  return data
}
export default useApiMeta
