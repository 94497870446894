import React, { ReactElement, useState } from 'react'
import Section from '@components/core/container/Section'
import Box from '@mui/material/Box'
import Slider from '@components/core/container/Slider'
import Headline from '@components/core/text/Headline'
import YouTubePlayer, { VideoProps } from '../Video/index'
import { YouTubeEvent } from 'react-youtube'
import { useMediaQuery } from '@mui/material'

export type VideoSliderProps = {
  headline: string
  slidesPerView?: number
  videos: Array<VideoProps>
  sx?: MCDC.Props.IDefault['sx']
}

export default function VideoSlider({
  headline,
  videos,
  slidesPerView,
}: VideoSliderProps): ReactElement {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [activeVideo, setActiveVideo] = useState<YouTubeEvent | null>(null)

  const setPlayerReference = (newActiveVideo: YouTubeEvent) => {
    if (newActiveVideo) {
      if (activeVideo) {
        if (activeVideo === newActiveVideo) {
          setActiveVideo(null)
        } else {
          activeVideo.pauseVideo()
          setActiveVideo(newActiveVideo)
        }
      } else {
        setActiveVideo(newActiveVideo)
      }
    }
  }

  return (
    <Section theme="primary" paddingSize="small" withDrip>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Headline variant="h3">{headline}</Headline>
      </Box>
      {videos && (
        <Slider
          slidesPerView={isMobile ? 1.2 : slidesPerView || 3}
          theme="primary"
          arrows="overlay"
        >
          {videos.map((item, index) => (
            <YouTubePlayer
              key={index}
              sx={{ color: '#ffffff' }}
              youtubeId={item.youtubeId}
              headline={item.headline}
              image={item.image}
              compact
              customPaddingX={5}
              onPlaying={({ target }: YouTubeEvent) => {
                setPlayerReference(target)
              }}
            />
          ))}
        </Slider>
      )}
    </Section>
  )
}
