/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { kPageMetaType } from '@utils/constants'
import { graphql, useStaticQuery } from 'gatsby'
import useStaticContentful from './useStaticContentful'

const useContentfulNavigation = () => {
  const { locale } = useIntl()
  const { meta } = useStaticContentful()
  const { main, account } = useStaticQuery(
    graphql`
      query {
        main: allContentfulNavigation(
          filter: { identifier: { eq: "navigationMain" } }
        ) {
          nodes {
            ...NavigationFragment
          }
        }
        account: allLocalPageAccount {
          nodes {
            ...PageAccountFragment
          }
        }
      }
    `
  )

  const results = useMemo(() => {
    const { homePage, mainPages, metaPages } = main.nodes.find(
      (entry: MCDC.Contentful.INavigation) => entry.node_locale === locale
    )

    const accountPage = account.nodes.find(
      (entry: MCDC.Contentful.IPageAccount) => entry.node_locale === locale
    )

    return {
      homePage: {
        ...homePage.fields?.linkTo,
      },
      accountPage: accountPage.fields?.linkTo,
      mainPages: mainPages.map((entry: any) => {
        return {
          ...entry.fields?.linkTo,
          count:
            entry.metaType === kPageMetaType.offers
              ? meta?.totalJobcount || 0
              : undefined,
        }
      }),
      metaPages:
        metaPages?.map((entry: any) => ({
          ...entry.fields?.linkTo,
        })) || [],
    }
  }, [main, locale, meta, account])

  return results
}
export default useContentfulNavigation
