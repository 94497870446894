import React, { ReactElement } from 'react'
import { IconButton, styled } from '@mui/material'

import MenuIcon from '@static/images/icons/menu.svg'
import CloseIcon from '@static/images/icons/close.svg'

const StyledIconButton = styled(IconButton)({
  display: 'inline-flex',
  alignItems: 'center',
})

export type MenuToggleProps = {
  onClick?: (event: React.SyntheticEvent) => void
  isToggled?: boolean
  sx?: MCDC.Props.IDefault['sx']
}

/**
 * MUI MenuToggle Wrapper
 */
export default function MenuToggle({
  onClick,
  isToggled,
  sx = {},
}: MenuToggleProps): ReactElement {
  return (
    <StyledIconButton
      edge="start"
      color="inherit"
      aria-label="open drawer"
      onClick={onClick}
      sx={sx}
    >
      {!isToggled && <MenuIcon />}
      {isToggled && <CloseIcon />}
    </StyledIconButton>
  )
}
