import React, { ReactElement, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as api from '@utils/api'
import Headline from '@components/core/text/Headline'
import { Box, Grid } from '@mui/material'
import Section from '@components/core/container/Section'
import JobCard from '@components/core/card/JobCard'
import Button from '@components/core/input/Button'

import { getJobCardProps } from '@utils/props'
import { cardTransition, StyledMotionDiv } from '@utils/motion'
import { AnimatePresence } from 'framer-motion'

export type RecommendationsProps = {
  items?: MCDC.API.IJobInfo[] | MCDC.API.IJobGroupInfo[]
  variant?: 'offers' | 'related'
  baseUrl?: string
  sx?: MCDC.Props.IDefault['sx']
}

export default function Recommendations({
  sx,
  items = [],
  variant = 'offers',
  baseUrl = '',
}: RecommendationsProps): ReactElement | null {
  if (variant !== 'offers' && !items.length) return null
  const intl = useIntl()
  const headline =
    variant !== 'offers'
      ? (intl.messages['label.jobsRelated'] as string)
      : (intl.messages['label.jobsOpen'] as string)
  const [itemsState, setItemsState] = useState(items)

  useEffect(() => {
    let isSubscribed = true
    async function submit() {
      const response = await api.searchAggregated({ pager: { size: 5 } })
      if (!isSubscribed) return
      setItemsState(response?.data?.data || [])
    }

    if (variant === 'offers' && !items.length) {
      submit()
    }

    return () => {
      isSubscribed = false
    }
  }, [])

  function renderJobCard(props: MCDC.API.IJobInfo | MCDC.API.IJobGroupInfo) {
    const key = !!(props as MCDC.API.IJobInfo).refId
      ? `job-${(props as MCDC.API.IJobInfo).refId}`
      : `${props.templateId}-${props.templateTitle}`
    return (
      <Grid item xs={12} key={key} sx={{ overflow: 'hidden' }}>
        <StyledMotionDiv {...cardTransition}>
          {<JobCard {...getJobCardProps(props, baseUrl, intl)} />}
        </StyledMotionDiv>
      </Grid>
    )
  }

  return (
    <Section
      theme={variant === 'offers' ? 'primary' : undefined}
      sx={[sx]}
      paddingSize="default"
      maxWidth={variant === 'offers' ? 'sm' : undefined}
    >
      <Headline sx={{ mb: 7 }} component="h2" variant="h3">
        {headline}
      </Headline>
      <AnimatePresence exitBeforeEnter>
        <Grid container spacing={6}>
          {itemsState.map(renderJobCard)}
        </Grid>
      </AnimatePresence>
      {variant === 'offers' && baseUrl && (
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: variant === 'offers' ? 'flex-start' : 'center',
          }}
        >
          <Button
            color="secondary"
            sx={{ mt: 8 }}
            variant={variant === 'offers' ? 'text' : 'rounded'}
            to={baseUrl}
          >
            <FormattedMessage id={'joblist.moreLink.label'} />
          </Button>
        </Box>
      )}
    </Section>
  )
}
